import React, {
    forwardRef, useEffect, useImperativeHandle,
    useState,
} from 'react'

export default forwardRef<{}, Record<string, any>>((props, ref) => {

    const [selectedIndex, setSelectedIndex] = useState(0)

    const selectItem = index => {
        const item = props.items[index]

        if (item) {
            props.command(item)
        }
    }

    const upHandler = () => {
        setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
        document.querySelector(".item.is-selected").scrollIntoView({block: "end", inline: "nearest"})
    }

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1) % props.items.length);
        document.querySelector(".item.is-selected").scrollIntoView({block: "end", inline: "nearest"})
    }

    const enterHandler = () => {
        selectItem(selectedIndex)
    }

    useEffect(() => setSelectedIndex(0), [props.items])

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }) => {
            if (event.key === 'ArrowUp') {
                upHandler()
                return true
            }

            if (event.key === 'ArrowDown') {
                downHandler()
                return true
            }

            if (event.key === 'Enter') {
                enterHandler()
                return true
            }

            return false
        },
    }))

    return (
        <div className="items">
            {props.items.length
                ? props.items.map((item, index) => (
                    <button
                        className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
                        key={index}
                        onClick={() => selectItem(index)}
                    >
                        {item.label}
                    </button>
                ))
                : <div className="item">No result</div>
            }
        </div>
    )
})