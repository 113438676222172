import React, {useState} from "react";
import {Submission} from "../../../../models/submission";
import moment from "moment";
import Dropzone, { IPreviewProps } from "react-dropzone-uploader"
import Button, {button_container} from "../../../../components/buttons/button";
import {Folder, Image, Paper, Upload} from "react-iconly";
import {formatBytes} from "../../../../utils/methods";
import {FiX} from "react-icons/fi";
import {auth, storage} from "../../../../utils/firebase.utils";
import { uploadBytesResumable, ref, getMetadata } from "firebase/storage";
import axios from "axios";
import {BASE_API} from "../../../../utils/constants";

const SubmissionUpload = ({submission, refresh}: {submission:Submission, refresh: () => void}) => {

    const [uploading, setUploading] = useState(false);

    return (
        <>
            <Dropzone
                multiple={false}
                maxFiles={1}
                inputContent={
                    <p key={"dropzone"} className={"w-full font-semibold text-sm text-gray-500 text-center cursor-pointer py-6"}>
                        Drag n' Drop to upload your paper
                        <div className={"flex w-full justify-center mt-2"}>
                            <div>
                                <div className={`${button_container} items-center`}>
                                    <Folder className={"h-4 w-4 text-primary group-hover:opacity-90"} aria-hidden={true}/>
                                    <span className={"ml-4"}>
                                        Browse Files
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={"flex flex-col justify-center items-center mt-6"}>
                            <div className={"text-xs font-normal"}>
                                Accepts Formats:
                            </div>
                            <div className={"flex space-x-3 mt-2"}>
                                <div className={"text-xs font-bold text-white flex items-center justify-center w-5 h-5 rounded-md"} style={{ background: "#005DA6" }}>
                                    <span style={{  }}>
                                        W
                                    </span>
                                </div>
                                <div className={"text-primary-dark bg-primary-lighter text-white flex items-center justify-center w-5 h-5 rounded-md"}>
                                    <Image set={"bold"}/>
                                </div>
                            </div>
                        </div>
                    </p>
                }
                inputWithFilesContent={
                    <p key={"dropzone"} className={""}/>
                }
                PreviewComponent={(file: IPreviewProps) => {
                    return <div key={file.meta.id} className={"w-full font-semibold text-sm text-gray-500 text-center"}>
                            {file.meta.name.split(".")[file.meta.name.split(".").length - 1] === "docx" ?
                                <div className={"flex items-start "}>
                                    <Paper className={"w-8 h-8 flex-shrink-0"} set={"bold"} style={{ color: "#005DA6" }}/>
                                    <div>
                                        <p className={"text-gray-900 w-full text-left ml-2 font-normal text-sm"}>
                                            {file.meta.name}
                                        </p>
                                        <p className={"w-full text-left ml-2 font-normal text-xs"}>
                                            {formatBytes(file.meta.size, 2)}
                                        </p>
                                    </div>
                                    <div className={"p-2 bg-red-100 hover:bg-red-200 cursor-pointer rounded-full ml-auto"} onClick={() => file.fileWithMeta.remove()}>
                                        <FiX className={"text-red-700"}/>
                                    </div>
                                </div>
                                :
                                <div className={"flex items-start "}>
                                    <div className={"w-10 h-10 flex-shrink-0 rounded-lg"} style={{ backgroundImage: `url(${file.meta.previewUrl})`, backgroundSize: 'cover' }}/>
                                    <div>
                                        <p className={"text-gray-900 w-full text-left ml-2 font-normal text-sm"}>
                                            {file.meta.name}
                                        </p>
                                        <p className={"w-full text-left ml-2 font-normal text-xs"}>
                                            {formatBytes(file.meta.size, 2)}
                                        </p>
                                    </div>
                                    <div className={"p-2 bg-red-100 hover:bg-red-200 cursor-pointer rounded-full ml-auto"} onClick={() => file.fileWithMeta.remove()}>
                                        <FiX className={"text-red-700"}/>
                                    </div>
                                </div>
                            }
                    </div>
                }}
                submitButtonContent={() => {
                    return <span key={"dropzone"} className={"w-full font-semibold text-sm text-gray-500 text-center cursor-pointer pb-0"}>
                        <div className={"flex w-full justify-center mt-4"}>
                            <div>
                                <Button icon={Upload} loading={uploading}>
                                    <span className={"ml-6"}>
                                        Upload
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </span>
                }}
                onSubmit={(successFiles) => {
                    setUploading(true);
                    let uploadTask = uploadBytesResumable(ref(storage, `users/${submission.uid}/${submission.document_id}/${submission.id}-${moment(new Date()).format("DD-MM-YY-HH-m-s")}.docx`), successFiles[0].file);
                    uploadTask.on(
                        'state_changed',
                        () => {},
                        (error) => {
                            console.log(error);
                        },
                        () => {
                            getMetadata(uploadTask.snapshot.ref).then(async (downloadURL) => {
                                let token = await auth.currentUser?.getIdToken();
                                axios.post(`${BASE_API}/submissions/submit-data/${submission.document_id}`, { submission_data_link: `https://ik.imagekit.io/atticcreation/korero/${downloadURL.fullPath}`, submission_data_type: successFiles[0].file.type.startsWith("image/") ? "image" : "docx" }, { headers: { authorization: token ?? "" } }).then(async (res) => {
                                    if (res.status === 201) {
                                        refresh();
                                    }
                                });
                            });
                        }
                    );
                }}
                submitButtonDisabled={uploading}
                disabled={false}
                accept="image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                classNames={{
                    input: "hidden",
                    dropzone: (_, extra) => {
                        return `flex flex-col items-center py-4 px-4 border-2 border-dashed rounded-lg transition duration-200 ${extra.active ? (extra.reject ? "border-red-700 bg-red-100" : "border-primary-default bg-primary-lighter") : "border-gray-300"}`
                    },
                    inputLabel: "w-full",
                    submitButtonContainer: "w-full"
                }}
            />

        </>
    )

}

export default SubmissionUpload