import React from "react";
import Loading from "../cards/loading";

const OnLoadViewer = ({loading, children, component="div", childComponent, className=""}: {loading: boolean, children?: React.ReactNode, component?:string, childComponent?:string, className?: string}) => {

    return (
        <>
            {
                loading ?
                    React.createElement(component, { className: `w-full flex justify-center py-10 ${className}`, children: React.createElement(childComponent ?? React.Fragment, { children: <Loading/> }) })
                    :
                    children
            }
        </>
    )


}

export default OnLoadViewer;