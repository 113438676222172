import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "redux";
import {Role, SET_USER, UPDATE_USER, UserActionTypes} from "./redux/types/user-data-types";
import {RootState} from "./redux/index.reducers";
import {auth, conferences, firestore, users} from "./utils/firebase.utils";
import {UserData} from "./models/user-data";
import AppRoutes from "./components/app-routes/app-routes";
import { onAuthStateChanged } from 'firebase/auth';
import {
    doc, enableIndexedDbPersistence, getDoc, getDocs, limit,
    query, where
} from 'firebase/firestore';
import {ConferenceSettingsActionTypes, SET_CONFERENCE_SETTINGS} from "./redux/types/conference-types";
import {ConferenceSettings} from "./models/conference-settings";
import Favicon from "react-favicon";

const App = () => {

    const dispatch = useDispatch<Dispatch<UserActionTypes | ConferenceSettingsActionTypes>>();
    const userData = useSelector((state: RootState) => state.userData);
    const conference = useSelector((state: RootState) => state.conference);

    useEffect(() => {

       try {
           enableIndexedDbPersistence(firestore).catch(() => {})
       } catch (e) {}

        // let domain = window.location.host;

        let ref = query(conferences, where('domain', '==', "submissions.icsg.world"), limit(1));
        let local_data = localStorage.getItem("conference_settings");

        if (local_data) {
            let conf_settings = new ConferenceSettings(JSON.parse(local_data));
            dispatch({type: SET_CONFERENCE_SETTINGS, payload: { settings: conf_settings }});
        }

        getDocs(ref).then((snapshot) => {
            if (!snapshot.empty) {
                let data = snapshot.docs[0].data();
                data.id = snapshot.docs[0].id;
                let conf_settings = new ConferenceSettings(data);

                localStorage.setItem("conference_settings", JSON.stringify(data));
                dispatch({type: SET_CONFERENCE_SETTINGS, payload: { settings: conf_settings }});
            }
        });

        onAuthStateChanged(auth, async (user) => {
            if (user) {
                auth.currentUser?.getIdTokenResult()
                    .then(async (result) => {
                        let role: Role = "regular";
                        if (result.claims["admin"]) {
                            role = "admin";
                        }

                        dispatch({type: SET_USER, payload: {user: user, userData: new UserData({uid: user.uid, email: user.email}), role: role}});
                    })
                    .then(async () => {
                        let document = await getDoc(doc(users, user.uid));
                        let userData;
                        if (document.exists()) {
                            let data = document.data();
                            data.uid = user.uid;
                            userData = new UserData(data);
                        } else {
                            userData = new UserData({
                                uid: user.uid, email: user.email
                            });
                        }
                        dispatch({type: UPDATE_USER, payload: {userData: userData}});
                    })
            } else {
                dispatch({type: SET_USER, payload: {user: null, userData: null, role: "regular"}});
            }
        });


    }, [dispatch])

    return (
        <>
            <Favicon url={conference?.icon ?? ""}/>
            {(!userData.loading) &&
                <AppRoutes/>
            }
        </>
    );
}

export default App;
