import { Author } from "../models/submission"
import {Descendant, Node} from "slate";

export const authorValue = (authors:Author[]) => {
    let finalString = "";
    authors.forEach((n, index) => {
        if (index < authors.length-2)
            finalString += `${n.name}, `
        else if (index === authors.length-2)
            finalString += `${n.name} `
        else
            finalString += `${authors.length > 1 ? "& " : ""} ${n.name}`
    })

    return finalString;
}

export const nodeValue = (nodes:Descendant[]) => {
    return nodes.map(n => {
        return Node.string(n as Node);
    }).join('\n')
}

export const formatBytes = (a=0,b=2,k=1024) => {
    let d=Math.floor(Math.log(a)/Math.log(k));return 0===a?"0 Bytes":parseFloat((a/Math.pow(k,d)).toFixed(Math.max(0,b)))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]
}

export const formatCurrency = (val: number, currency: string = "USD") => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0, style: 'currency', currency: currency.toUpperCase()}).format(val)
}