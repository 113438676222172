import React, { useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import Heading from "../components/heading";
import {Submission} from "../../../models/submission";
import {onSnapshot, orderBy, query, where} from "firebase/firestore";
import {submissions} from "../../../utils/firebase.utils";
import PrimaryButton from "../../../components/buttons/button";
import {Link} from "react-router-dom";
import OnLoadViewer from "../../../components/on-load-viewer/on-load-viewer";
import AttendeeSubmissionRow from "../../../components/submission-components/submission-row/attendee-submission-row";

const MySubmissions = () => {

    const conference = useSelector((state: RootState) => state.conference);
    const user = useSelector((state: RootState) => state.userData);

    const [loading, setLoading] = useState(true);
    const [mySubmissions, setMySubmissions] = useState<Submission[]>([]);


    useEffect(() => {

        if (conference.id) {
            let ref = query(
                submissions,
                where('uid', '==', user.userData?.uid),
                where('conference_id', '==', conference.id),
                where('conference_version_id', '==', conference.active_conference),
                orderBy('createdAt', 'desc')
            );

            let observer = onSnapshot(ref, (snapshot) => {
                if (snapshot.empty) {
                    setMySubmissions([])
                } else {
                    let s:Submission[] = [];
                    snapshot.forEach((doc) => {
                        let data = doc.data();
                        data.document_id = doc.id;
                        s.push(new Submission(data))
                    });
                    setMySubmissions(s);
                }
                setLoading(false);
            })

            return () => observer();
        }

    }, [conference.active_conference, conference.id, user.userData?.uid])

    return (
        <>
            <Heading title={"My Submissions"} subtitle={"All of your submissions are listed below. You may update your submissions before the deadline."}
                     button={
                         <Link to={"/submissions/new"}>
                             <PrimaryButton className={"mt-5"} style={{ width: "auto" }}>
                                 New Submission
                             </PrimaryButton>
                         </Link>
                     }
            />
            <OnLoadViewer loading={conference.loading}>
                <table className="table-fixed w-full text-left">
                    <thead className={`bg-gray-50 h-12 px-3 py-3 text-gray-400 sticky z-20`} style={{ top: `64px`, boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>
                        <tr>
                            <th className="font-normal sm:p-3 py-2 px-1" style={{ width: "15%" }}>#</th>
                            <th className="font-normal sm:p-3 py-2 px-1" style={{ width: "65%" }}>Title</th>
                            <th className="font-normal sm:p-3 py-2 px-1 sm:text-gray-400 text-white" style={{ width: "20%" }}>Status</th>
                        </tr>
                    </thead>
                    <tbody className={"text-gray-600 relative"}>
                       <OnLoadViewer loading={loading} component={"tr"} childComponent={"td"} className={"absolute"}>
                           {
                               mySubmissions.map((submission) => {
                                   return <AttendeeSubmissionRow submission={submission}/>
                               })
                           }
                       </OnLoadViewer>
                    </tbody>
                </table>
            </OnLoadViewer>
        </>
    )
}

export default MySubmissions;