import { combineReducers } from 'redux';
import userDataReducer from "./reducers/user-data-reducer";
import conferenceReducer from "./reducers/conference-reducer";
import adminSubmissionsReducer from "./reducers/admin-submissions-reducer";
import adminRegistrationsReducer from "./reducers/admin-registrations-reducer";

const allReducers = combineReducers({
    userData: userDataReducer,
    conference: conferenceReducer,
    adminSubmissions: adminSubmissionsReducer,
    adminRegistrations: adminRegistrationsReducer
});

export type RootState = ReturnType<typeof allReducers>
export default allReducers;