import React, {useCallback, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import {Submission} from "../../../models/submission";
import {doc, getDoc} from "firebase/firestore";
import {submissions} from "../../../utils/firebase.utils";
import {useParams} from "react-router-dom";
import OnLoadViewer from "../../../components/on-load-viewer/on-load-viewer";
import Card from "../../../components/cards/card";
import SubmissionTimeline from "../../../components/submission-components/submission-details/submission-timeline";
import SubmissionUpload from "./components/submission-upload";
import SubmissionHeading from "../../../components/submission-components/submission-details/submission-heading";
import SubmissionsFieldsView
    from "../../../components/submission-components/submission-details/submissions-fields-view";
import SubmissionAbstractView
    from "../../../components/submission-components/submission-details/submission-abstract-view";
import SubmissionDocDataView
    from "../../../components/submission-components/submission-details/submissions-doc-data-view";

const SubmissionDetails = () => {

    const submissionLeftCol = useRef<HTMLDivElement>(null);

    const conference = useSelector((state: RootState) => state.conference);
    const {id}: {id: string} = useParams();

    const [loading, setLoading] = useState(true);
    const [submissionDetails, setSubmissionDetails] = useState<Submission>();

    const getSubmission = useCallback(() => {

        setLoading(true);
        getDoc(
            doc(
                submissions,
                id
            )
        ).then(async (doc) => {
            if (doc.exists()) {
                let data = doc.data();
                data.document_id = doc.id;
                setSubmissionDetails(new Submission(data));
            }
            setLoading(false);
        });
    }, [id])

    useEffect(() => {

        if (id && !submissionDetails) {
            getSubmission();
        }

    }, [getSubmission, id, submissionDetails])

    return (
        <>
            <SubmissionHeading submission={submissionDetails}/>
            <OnLoadViewer loading={conference.loading || loading}>
                <div className={"grid grid-cols-5 space-x-8 px-6"}>
                    <div ref={submissionLeftCol} className={"col-span-3 space-y-4 py-8"}>
                        <div className={"flex items-center"}>
                            <p className={"font-medium text-base"}>
                                Details
                            </p>
                        </div>
                        <Card >
                            <SubmissionsFieldsView submission={submissionDetails}/>

                            <div className={"my-5"} style={{ width: "100%", borderBottom: "1px dashed rgb(230,230,230)" }}/>

                            <SubmissionAbstractView submission={submissionDetails} containerWidth={submissionLeftCol?.current?.clientWidth ?? 1}/>

                            {submissionDetails?.submission_data_link && (
                                <div className={"my-5"} style={{width: "100%", borderBottom: "1px dashed rgb(230,230,230)"}}/>
                            )}
                            {submissionDetails?.submission_data_link && (
                                <SubmissionDocDataView submission={submissionDetails}/>
                            )}
                        </Card>
                        {["submit-paper", "re-submit-paper"].includes(submissionDetails?.status) &&
                            <Card>
                                <div className={"text-sm max-w-xl mb-4"}>
                                    <span className={"text-primary-default font-medium"}>Your abstract has been accepted.</span>
                                    {" "}
                                    <span className={"opacity-80"}>
                                        Please submit a full paper following the sample template on our website. Please know that there may be an additional review or feedback before your paper is included in the proceedings.
                                    </span>
                                </div>
                                <SubmissionUpload submission={submissionDetails} refresh={getSubmission}/>
                            </Card>
                        }
                    </div>
                    <div className={"col-span-2 sticky top-16 h-0 pt-8"}>
                        <SubmissionTimeline id={id} friendly_id={submissionDetails?.id}/>
                    </div>
                </div>
            </OnLoadViewer>
        </>
    )
}

export default SubmissionDetails;