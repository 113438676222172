import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import Heading from "../components/heading";
import AbstractForm from "../../../components/submission-components/abstract-form/abstract-form";
import OnLoadViewer from "../../../components/on-load-viewer/on-load-viewer";

const NewSubmission = () => {

    const conference = useSelector((state: RootState) => state.conference);

    return (
        <>
            <Heading returnTo={"/submissions"} returnText={"My Submissions"} title={"New Submission"}/>
            <OnLoadViewer loading={conference.loading}>
                <div>
                    <AbstractForm/>
                </div>
            </OnLoadViewer>
        </>
    )
}

export default NewSubmission;