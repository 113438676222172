import React from "react";
import {Submission} from "../../../models/submission";
import {useHistory} from "react-router-dom";

const AttendeeSubmissionRow = ({submission}: {submission: Submission}) => {

    const history = useHistory();

    return (
        <tr key={submission.document_id} className={"border-b border-gray-200 hover:bg-gray-100 cursor-pointer"} onClick={() => { history.push(`/submissions/${submission.document_id}`) }}>
            <td className="sm:p-3 py-2 px-1">
                <div className="flex items-center">
                    {submission.id}
                </div>
            </td>
            <td className="sm:p-3 py-2 px-1">
                <div className="flex items-center">
                    {submission.title}
                </div>
            </td>
            <td className="sm:p-3 py-2 px-1">
                {
                    (() => {
                        switch (submission.status) {
                            case "draft":
                                return <div className="flex items-center text-yellow-600">
                                    Draft
                                </div>
                            case "abstract-review":
                            case "paper-review":
                                return <div className="flex items-center text-yellow-600">
                                    In Review
                                </div>
                            case "re-submit-paper":
                            case "re-submit-abstract":
                                return <div className="flex items-center text-red-600">
                                    Resubmit
                                </div>
                            case "submit-paper":
                                return <div className="flex items-center text-blue-800">
                                    Abstract Accepted
                                </div>
                            case "final":
                                return <div className="flex items-center text-green-800">
                                    Paper Accepted
                                </div>
                        }
                    })()
                }
            </td>
        </tr>
    )

}

export default AttendeeSubmissionRow;