export class DefaultAnalytics {
    _id: string
    count: number

    constructor(data?: { [field: string]: any }) {
        this._id = data?._id ?? "";
        this.count = data?.count ?? 0;
    }
}

export default class SubmissionAnalytics {

    countries: DefaultAnalytics[] = [];
    daily: DefaultAnalytics[] = [];
    weekly: DefaultAnalytics[] = [];
    monthly: DefaultAnalytics[] = [];
    statuses: DefaultAnalytics[] = [];

    constructor(data?: { [field: string]: any }) {
        if (data?.countries) {
            this.countries = [];
            data.countries.forEach((a: any) => {
                this.countries.push(new DefaultAnalytics(a));
            });
        } else {
            this.countries = [];
        }
        if (data?.daily) {
            this.daily = [];
            data.daily.forEach((a: any) => {
                this.daily.push(new DefaultAnalytics(a));
            });
        } else {
            this.daily = [];
        }
        if (data?.weekly) {
            this.weekly = [];
            data.weekly.forEach((a: any) => {
                this.weekly.push(new DefaultAnalytics(a));
            });
        } else {
            this.weekly = [];
        }
        if (data?.monthly) {
            this.monthly = [];
            data.monthly.forEach((a: any) => {
                this.monthly.push(new DefaultAnalytics(a));
            });
        } else {
            this.monthly = [];
        }
        if (data?.statuses) {
            this.statuses = [];
            data.statuses.forEach((a: any) => {
                this.statuses.push(new DefaultAnalytics(a));
            });
        } else {
            this.statuses = [];
        }
    }

    total_submissions({filter = []}: {filter?: string[]}): number {
        let sum = 0;
        this.statuses.forEach(s => {
            if (filter.length === 0)
                sum+=s.count
            else if (filter.includes(s._id))
                sum+=s.count
        });
        return sum;
    }

    max_count (data: DefaultAnalytics[]): number {
        let max = 0;
        data.forEach((d) => {
            if (d.count > max)
                max = d.count;
        })
        return max;
    }

    bar_data (type: "SIX_MONTHS" | "COUNTRIES", param: string): {size:string, value:number} {
        switch (type) {
            case "SIX_MONTHS":
                let value = this.monthly.find((m) => m._id === param)?.count ?? 0;
                return {
                    size: value === 0 ? "4px" : `${(value/this.max_count(this.monthly)*100)}%`,
                    value: value
                }
            case "COUNTRIES":
                let value_2 = this.countries.find((m) => m._id === param)?.count ?? 0;
                return {
                    size: `${(value_2/this.max_count(this.countries)*100)}%`,
                    value: value_2
                }

        }
    }
}