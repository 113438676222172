import {
    ConferenceSettingsActionTypes,
    ConferenceSettingState,
    SET_CONFERENCE_SETTINGS
} from "../types/conference-types";

const initialState: ConferenceSettingState = new ConferenceSettingState();

export function conferenceReducer (state = initialState, action: ConferenceSettingsActionTypes): ConferenceSettingState {

    switch (action.type) {
        case SET_CONFERENCE_SETTINGS:
            return new ConferenceSettingState({
                loading: false,
                ...action.payload.settings
            });
        default:
            return state
    }
}

export default conferenceReducer;