export class RegistrantDetails {
    name: string
    email: string
    meta_data: Record<string, any>

    constructor(data?: { [field: string]: any }) {
        this.name = data?.name ?? '';
        this.email = data?.email ?? '';
        this.meta_data = data?.meta_data ?? {}
    }

    toJson(): RegistrantDetails {
        return Object.assign({}, this);
    }
}

export class PostConferenceActivity {
    id: string
    variation_id: string;
    variation_name: string;
    has_variation: boolean;

    title: string
    price: number

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? '';
        this.variation_id = data?.variation_id ?? '';
        this.variation_name = data?.variation_name ?? '';
        this.has_variation = data?.has_variation ?? false;
        this.title = data?.title ?? '';
        this.price = data?.price ?? 0;
    }

    get variation_title():string {
        if (this.has_variation)
            return `${this.title} (${this.variation_name})`
        else
            return this.title
    }

    toJson(): PostConferenceActivity {
        return Object.assign({}, this);
    }
}

export class RegistrationTicket {
    id: string
    title: string
    description: string
    currency: string
    price: number
    ticket_type: "paid" | "free"
    registrant_details: RegistrantDetails
    post_conference_activities: PostConferenceActivity[]

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? '';
        this.title = data?.title ?? '';
        this.description = data?.description ?? '';
        this.currency = data?.currency ?? '';
        this.price = data?.price ?? 0;
        this.ticket_type = data?.ticket_type ?? 'free';
        this.registrant_details = new RegistrantDetails(data?.registrant_details);
        if (data?.post_conference_activities) {
            this.post_conference_activities = data?.post_conference_activities.map((r: { [field: string]: any }) => new PostConferenceActivity(r));
        } else {
            this.post_conference_activities = [];
        }
    }

    toJson(): RegistrationTicket {
        let d = Object.assign({}, this);
        d.post_conference_activities = this.post_conference_activities.map(t => t.toJson())
        return d;
    }
}

export class Registration {

    id: string = '';
    document_id: string = '';
    conference_id: string = '';
    conference_version_id: string = '';
    uid: string = '';

    tickets: RegistrationTicket[]
    country: string
    createdAt: Date | null = null;

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? '';
        this.document_id = data?.document_id ?? '';
        this.conference_id = data?.conference_id ?? '';
        this.conference_version_id = data?.conference_version_id ?? '';
        this.uid = data?.uid ?? '';

        if (data?.tickets) {
            this.tickets = data?.tickets.map((r: { [field: string]: any }) => new RegistrationTicket(r));
        } else {
            this.tickets = [];
        }

        this.country = data?.country ?? '';
        this.createdAt = data?.createdAt ? (typeof data?.createdAt === "string" ? new Date(data.createdAt) : (data.createdAt instanceof Date ? data.createdAt : new Date(data.createdAt.toDate().toString()))) : null;
    }

    get total():number {
        let total_amount = 0;

        this.tickets.forEach((t) => {
            total_amount += t.price;
            t.post_conference_activities.forEach((pca) => {
                total_amount += pca.price;
            })
        })

        return total_amount;
    }

    get ticket_lines(): { id: string, title: string, quantity:number, price: number }[] {
        let lines: { id: string, title: string, quantity:number, price: number }[] = [];

        this.tickets.forEach((t) => {
            let c_index = lines.findIndex(l => l.id === t.id)
            if (c_index === -1) {
                lines.push({
                    id: t.id,
                    title: t.title,
                    quantity: 1,
                    price: t.price
                })
            } else {
                lines[c_index] = {
                    id: t.id,
                    title: t.title,
                    quantity: lines[c_index].quantity + 1,
                    price: lines[c_index].price + t.price
                }
            }
        })

        this.tickets.forEach((t) => {
            if (t.post_conference_activities.length > 0) {
                t.post_conference_activities.forEach((pc) => {
                    let p_index = lines.findIndex(l => (l.id === (pc.id+pc.variation_id)))
                    if (p_index === -1) {
                        lines.push({
                            id: pc.id+pc.variation_id,
                            title: pc.variation_title,
                            quantity: 1,
                            price: pc.price
                        })
                    } else {
                        lines[p_index] = {
                            id: pc.id+pc.variation_id,
                            title: pc.variation_title,
                            quantity: lines[p_index].quantity + 1,
                            price: lines[p_index].price + pc.price
                        }
                    }
                })
            }
        })

        return lines;
    }

    toJson(): Registration {
        let d = Object.assign({}, this);
        d.tickets = this.tickets.map(t => t.toJson())
        return d;
    }

}