import React, {useEffect, useState} from "react";
import Logo from "../../assets/logo.webp";
import {useLocation} from "react-router-dom";
import * as queryString from "querystring";
import LinkSignIn from "./link-sign-in";
import ActionError from "./action-error";

const Action = () => {

    const [mode, setMode] = useState<string>();
    const location = useLocation();

    useEffect(() => {
        let q = queryString.parse(location.search.replace("?", ""));
        setMode(q["mode"]?.toString() ?? "");
    }, [location.search])

    return (
        <div className="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 items-start sm:items-center">
            <div className="max-w-md w-full space-y-8">
                <div className={"flex justify-center align-start"}>
                    <img
                        className="mt-1 h-4"
                        src={Logo}
                        alt="Attic Cr."
                    />
                </div>
                <div className={"flex justify-center align-start"}>
                    {
                        (() => {
                            switch (mode) {
                                case "signIn":
                                    return <LinkSignIn/>
                                default:
                                    return <ActionError/>

                            }
                        })()
                    }
                </div>
            </div>
        </div>
    )
}

export default Action;