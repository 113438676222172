import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/index.reducers";
import moment from "moment";
import PrimaryButton from "../../../../components/buttons/button";
import { Link } from "react-router-dom";
import Card from "../../../../components/cards/card";

const SubmissionOverview = () => {

    const conference = useSelector((state: RootState) => state.conference);

    return (
        <Card style={{ minHeight: "235px" }}>
            <div>
                {
                    !moment(conference.abstract_deadline.date).isAfter(new Date()) ?
                       <>
                           <div className={"text-3xl font-semibold max-w-sm"}>
                                {conference.abstract_deadline.formatted_date("MMMM DD, YYYY")}
                               {" "}
                                <span className={"text-primary-default"}>
                                   was the submissions deadline
                                </span>
                           </div>

                           <div className={"text-sm opacity-80 mt-2 max-w-sm"}>
                               Sorry, the conference is now closed to abstract submissions. You can contact the organisers for more information.
                           </div>
                           {/*// TODO add contact*/}
                           <PrimaryButton className={"mt-5"} style={{ width: "auto" }}>
                               Contact Organisers
                           </PrimaryButton>
                       </>
                        :
                        <>
                            <div className={"text-3xl font-semibold max-w-sm"}>
                                <span className={"text-primary-default"}>
                                    {(() => {
                                        let daysLeft = moment(conference.abstract_deadline.date).diff(moment(new Date()), 'days');
                                        return `${daysLeft} day${daysLeft !== 1 ? "s" : ""}`
                                    })()}
                                </span>
                                {" "}
                                until <br/> submissions close
                            </div>

                            <div className={"text-sm opacity-80 mt-2 max-w-sm"}>
                                The deadline to submit an abstract is {conference.abstract_deadline.formatted_date()}. Please complete your abstract before this date.
                            </div>
                            <div className={"flex"}>
                                <Link to={"/submissions"}>
                                    <PrimaryButton className={"mt-5"} style={{ width: "auto" }}>
                                        Submit Now
                                    </PrimaryButton>
                                </Link>
                            </div>
                        </>
                }
            </div>
        </Card>
    )

}

export default  SubmissionOverview;