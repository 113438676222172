import React, {useEffect, useCallback, useMemo} from "react";
import {Link, Route, Switch, useLocation} from "react-router-dom";
import AdminSubmissionsList from "../submissions-list/admin-submissions-list";
import AdminSubmissionsOverview from "../submissions-overview/admin-submissions-overview";
import {docx_export} from "../../../../components/submission-components/export/docx-export";
import PrimaryButton from "../../../../components/buttons/button";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/index.reducers";
import {AlignmentType, HeadingLevel, PageBreak, Paragraph, TextRun} from "docx";
import {authorValue, nodeValue} from "../../../../utils/methods";

const AdminSubmissionsView = () => {

    const TABS = useMemo(() => {
        return [
            {
                title: "Overview",
                to: "/admin/submissions",
                search: "",
                type: "root"
            },
            {
                title: "_pause_",
                to: "",
                search: ""
            },
            {
                title: "All",
                to: "/admin/submissions/browse",
                search: "",
                type: "root"
            },
            {
                title: "Needs Review",
                to: "/admin/submissions/browse",
                search: "?status=review",
                type: "search"
            },
            {
                title: "Completed",
                to: "/admin/submissions/browse",
                search: "?status=complete",
                type: "search"
            }
        ]
    }, []);

    const location = useLocation();

    const returnToActive = useCallback(() => {

        const active_index = TABS.findIndex((tab) =>
            {
                return ((location.pathname === tab.to || location.pathname === tab.to + "/") &&
                    (location.search === tab.search ||
                        (tab.search === "" && !TABS.map(tx => tx.search).includes(location.search))
                    )
                )
            }
        );

        let link = document.getElementById(`submissions_tab_${active_index.toString()}`);
        let floating_background = document.getElementById(`floating_background`);
        let floating_border = document.getElementById(`floating_border`);
        floating_background.style.width = ((link?.clientWidth-16) + "px");
        floating_background.style.transform = `translateX(${link?.offsetLeft}px)`;
        floating_border.style.width = ((link?.clientWidth-16) + "px");
        floating_border.style.transform = `translateX(${link?.offsetLeft}px)`;
        setTimeout(() => {
            floating_background.className = floating_background.className + "transition-all duration-300"
            floating_border.className = floating_border.className + "transition-all duration-300"
        }, 300)
    }, [TABS, location])

    useEffect(() => {
        returnToActive();
    }, [returnToActive])

    const admin_submissions = useSelector((state: RootState) => state.adminSubmissions);

    return (
        <>
            {/*Main*/}
            <div className={"flex items-end h-12 sticky border-b bg-gray-50 z-20 relative"} style={{ top: "64px" }}>
                <div className={"z-10 w-12 h-6 bg-primary-lighter mb-2 rounded-md absolute left-0"} id={"floating_background"} style={{ marginLeft: 0, marginRight: 0, willChange: "width" }}/>
                <div className={"z-10 w-12 h-6 border-b-2 border-primary-default pb-2 absolute left-0"} id={"floating_border"} style={{ marginLeft: 0, marginRight: 0, willChange: "width" }}/>
                {
                    TABS.map((t, index) => {

                        const active = ((location.pathname === t.to || location.pathname === t.to + "/") &&
                            (location.search === t.search ||
                                (t.search === "" && !TABS.map(tx => tx.search).includes(location.search))
                            )
                        )

                        if (t.title === "_pause_")
                            return (
                                <div key={`submissions_tab_${index.toString()}`} className={"w-1.5 h-1.5 my-auto mx-4 bg-gray-400 rounded-full transform translate-y-1"}/>
                            )
                        else
                            return (
                                <Link key={`submissions_tab_${index.toString()}`}
                                      id={`submissions_tab_${index.toString()}`}
                                      to={t.to + (t.search === "*" ? "" : t.search)}
                                      className={`z-10 border-b-2 border-transparent group ${active ? "text-primary-default" : "text-gray-600"} font-medium pb-2 pr-4`}
                                      onMouseEnter={() => {
                                          let link = document.getElementById(`submissions_tab_${index.toString()}`);
                                          let floating_background = document.getElementById(`floating_background`);
                                          let floating_border = document.getElementById(`floating_border`);
                                          floating_background.style.width = ((link.clientWidth-16) + "px");
                                          floating_background.style.transform = `translateX(${link.offsetLeft}px)`;
                                          floating_border.style.width = ((link.clientWidth-16) + "px");
                                          floating_border.style.transform = `translateX(${link.offsetLeft}px)`;
                                      }}
                                      onMouseLeave={() => {
                                          returnToActive();
                                      }}
                                >
                                    <span className={"py-1 px-2 transition duration-300 rounded-md"}>
                                        {t.title}
                                    </span>
                                </Link>
                            )
                    })
                }
            </div>
            <div className={"h-24"}/>
            <PrimaryButton onClick={() => {

                let content: Paragraph[] = [];
                admin_submissions.submissions.forEach((sub, ind) => {
                    content.push(new Paragraph({
                        // pageBreakBefore: ind !== 0,
                        style: "MainHeading",
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,
                        text: sub.title
                    }));
                    if (sub.authors.length === 1) {
                        content.push(new Paragraph({
                            style: "Subtitle",
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    bold: true,
                                    text: sub.authors[0].name
                                })
                            ]}),
                            new Paragraph({
                                style: "Subtitle",
                                alignment: AlignmentType.CENTER,
                                spacing: {
                                    before: 120
                                },
                                text: sub.authors[0].organization
                            })
                        )
                    } else {
                        let same_org = true;
                        let first_org = sub.authors[0].organization;
                        sub.authors.forEach((a) => {
                            if (a.organization !== first_org) {
                                same_org = false;
                            }
                        })

                        if (same_org) {
                            content.push(new Paragraph({
                                    style: "Subtitle",
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            bold: true,
                                            text: sub.authors.map(a => a.name).join(", ")
                                        })
                                    ]}),
                                new Paragraph({
                                    style: "Subtitle",
                                    alignment: AlignmentType.CENTER,
                                    spacing: {
                                        before: 120
                                    },
                                    text: sub.authors[0].organization
                                })
                            )
                        } else {
                            let author_names = [];
                            let author_orgs = [];
                            sub.authors.forEach((a, ind) => {
                                author_names.push(
                                    new TextRun({
                                        bold: true,
                                        text: a.name
                                    }),
                                    new TextRun({
                                        bold: true,
                                        superScript: true,
                                        text: (ind+1).toString()
                                    })
                                )
                                author_orgs.push(
                                    new TextRun({
                                        bold: true,
                                        text: (ind+1).toString() + ". "
                                    }),
                                    new TextRun({
                                        text: a.organization
                                    })
                                )
                                if (ind !== (sub.authors.length - 1 )) {
                                    author_names.push(
                                        new TextRun({
                                            bold: true,
                                            text: ', '
                                        }))
                                    author_orgs.push(
                                        new TextRun({
                                            text: ',  '
                                        }))
                                }
                            })
                            content.push(
                                new Paragraph({
                                    style: "Subtitle",
                                    alignment: AlignmentType.CENTER,
                                    children: author_names
                                }),
                                new Paragraph({
                                    style: "Subtitle",
                                    alignment: AlignmentType.CENTER,
                                    spacing: {
                                        before: 120
                                    },
                                    children: author_orgs
                                })
                            )

                        }
                    }
                    content.push(
                        new Paragraph({
                            style: "Subtitle2",
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    bold: true,
                                    italics: false,
                                    text: "Abstract"
                                })
                            ]
                        })
                    )

                    let abs = nodeValue(sub.abstract).split("\n");
                    abs.forEach((abstract_line, ind) => {
                        content.push(
                            new Paragraph({
                                style: "Content",
                                alignment: AlignmentType.JUSTIFIED,
                                children: [
                                    new TextRun({
                                        text: abstract_line
                                    }),
                                    ind === (abs.length - 1) ? new PageBreak() : new TextRun({ text: "" })
                                ]
                            }),
                        )
                    })
                })

                docx_export({
                    heading: "ICSG 2022",
                    content: content
                });
            }}>Download</PrimaryButton>
            <Switch>
                <Route exact path={['/admin/submissions']} component={AdminSubmissionsOverview} />
                <Route exact path={['/admin/submissions/browse']} component={AdminSubmissionsList} />
            </Switch>
        </>
    )

}

export default AdminSubmissionsView;