import {Transition} from "@headlessui/react";
import InlineLink from "../../../../components/links/inline-link";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/index.reducers";
import {FiGlobe} from "react-icons/fi";

const ConferenceOverview = () => {

    const conference = useSelector((state: RootState) => state.conference);

    const conferenceDetails = [
        {
            label: "Website",
            value: conference.website,
            icon: FiGlobe,
            link: `https://${conference.website}`
        },
        {
            label: "Conference Dates",
            value: conference.start_date.range(conference.end_date)
        },
        {
            label: "Conference Location",
            value: conference.location
        },
    ]

    return (
        <div className={"space-y-4"}>
            <div className={"bg-gray-100 box-border border border-1 border-gray-200 rounded-2xl overflow-hidden"}>
                <Transition
                    appear={true}
                    show={true}
                    enter="transition ease-in-out delay-80 duration-300 transform"
                    enterFrom="opacity-0 scale-120"
                    enterTo="opacity-100 scale-100"
                    className={"relative w-full aspect-w-16 aspect-h-7"}
                >
                    <div className={"absolute left-0 top-0 w-full h-full"} style={{ background: `url("${conference.background_theme}") left bottom / cover` }}/>
                </Transition>
            </div>
            <div className={"flex space-x-3"}>
                <div className={"w-14 h-14 sm:w-16 sm:h-16 bg-gray-100 box-border border border-1 border-gray-200 rounded-2xl overflow-hidden flex-shrink-0"}>
                    <Transition
                        appear={true}
                        show={true}
                        enter="transition ease-in-out delay-160 duration-300transform"
                        enterFrom="opacity-0 scale-120"
                        enterTo="opacity-100 scale-100"
                        className={"relative w-14 h-14 sm:w-16 sm:h-16"}
                    >
                        <div className={"absolute left-0 top-0 w-full h-full"} style={{ background: `url("${conference.icon}") center center / cover` }}/>
                    </Transition>
                </div>
                <div className="text-xl text-gray-900 font-medium max-w-md">
                    {conference.conference_full_name}
                </div>
            </div>
            <div className={"divide-y-2 divide-gray-200"}>
                {
                    conferenceDetails.map((details, index) => {

                        const odd = index%2 !== 0;

                        const value = <div className={"flex items-center"}>
                            {details.icon &&
                                React.createElement(details.icon, { size: 14, className: "mr-1.5"})

                            }
                            {details.value}
                        </div>

                        return (
                            <div className={`${odd ? "bg-gray-100" : ""} flex justify-between py-4 px-6`} key={index}>
                                <div className="text-base text-gray-900 font-medium max-w-md opacity-80">
                                    {details.label}
                                </div>
                                <div className="text-base text-gray-900 max-w-md">
                                    {
                                        details.link ?
                                            <InlineLink to={"https://" +conference.website}>
                                                {value}
                                            </InlineLink>
                                            :
                                            value

                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )

}

export default  ConferenceOverview;