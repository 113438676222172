import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/index.reducers";
import {useEffect} from "react";
import {Submission} from "../../../../models/submission";
import {doc, getDoc, getDocs, limit, orderBy, query, where} from "firebase/firestore";
import {submissions, submissions_analytics} from "../../../../utils/firebase.utils";
import {Dispatch} from "redux";
import {
    AdminSubmissionsActionTypes,
    SET_ADMIN_SUBMISSIONS,
    SET_ADMIN_SUBMISSIONS_ANALYTICS
} from "../../../../redux/types/admin-submissions-types";
import SubmissionAnalytics from "../../../../models/submission-analytics";

const ReduxSubmissions = () => {

    const conference = useSelector((state: RootState) => state.conference);
    const user = useSelector((state: RootState) => state.userData);

    const dispatch = useDispatch<Dispatch<AdminSubmissionsActionTypes>>();


    useEffect(() => {

        if (conference.id) {
            let ref = query(
                submissions,
                where('conference_id', '==', conference.id),
                where('conference_version_id', '==', conference.active_conference),
                limit(40),
                orderBy('createdAt', 'desc')
            );

            let a_ref = doc(submissions_analytics, `${conference.id}-${conference.active_conference}`);

            getDoc(a_ref)
                .then((snapshot) => {
                    if (!snapshot.exists()) {
                        dispatch({ type: SET_ADMIN_SUBMISSIONS_ANALYTICS, payload: {analytics: null} });
                    } else {
                        dispatch({ type: SET_ADMIN_SUBMISSIONS_ANALYTICS, payload: {analytics: new SubmissionAnalytics(snapshot.data())} });
                    }
                })
                .catch()

            getDocs(ref)
                .then((snapshot) => {
                    if (snapshot.empty) {
                        dispatch({ type: SET_ADMIN_SUBMISSIONS, payload: {submissions: []} });
                    } else {
                        let s:Submission[] = [];
                        snapshot.forEach((doc) => {
                            let data = doc.data();
                            data.document_id = doc.id;
                            s.push(new Submission(data))
                        });
                        dispatch({ type: SET_ADMIN_SUBMISSIONS, payload: {submissions: s} });
                    }
                })
                .catch()
        }

    }, [conference.active_conference, conference.id, user.userData?.uid, dispatch])

    return (
        <></>
    )

}

export default ReduxSubmissions;