import {
    AdminSubmissionsActionTypes,
    AdminSubmissionsState,
    SET_ADMIN_SUBMISSIONS,
    SET_ADMIN_SUBMISSIONS_ANALYTICS,
    UPDATE_ADMIN_SUBMISSIONS
} from "../types/admin-submissions-types";

const initialState: AdminSubmissionsState = new AdminSubmissionsState({
    loading: true,
    submissions: [],
    analytics: null
});

export function adminSubmissionsReducer (state = initialState, action: AdminSubmissionsActionTypes): AdminSubmissionsState {
    switch (action.type) {
        case SET_ADMIN_SUBMISSIONS:
            return new AdminSubmissionsState({
                ...state,
                submissions: action.payload.submissions,
                loading: false
            });
        case SET_ADMIN_SUBMISSIONS_ANALYTICS:
            return new AdminSubmissionsState({
                ...state,
                analytics: action.payload.analytics,
                loading: false
            });
        case UPDATE_ADMIN_SUBMISSIONS:

            let current_submissions = [...state.submissions];
            let new_submissions = [...action.payload.submissions];

            new_submissions.forEach((sub) => {
                let ind = current_submissions.findIndex((t) => t.document_id === sub.document_id);
                if (ind !== -1) {
                    current_submissions[ind] = sub;
                } else {
                    current_submissions.push(sub)
                }
            })

            current_submissions.sort((a,b) => (b.createdAt.getTime() - a.createdAt.getTime()))

            return new AdminSubmissionsState({
                ...state,
                submissions: current_submissions,
                loading: false
            });
        default:
            return state
    }
}

export default adminSubmissionsReducer;