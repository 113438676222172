import React, {useEffect, useCallback, useMemo} from "react";
import {Link, Route, Switch, useLocation} from "react-router-dom";
import AdminRegistrationList from "../admin-registrations-list/admin-registration-list";
// import AdminSubmissionsList from "../submissions-list/admin-submissions-list";
// import AdminSubmissionsOverview from "../submissions-overview/admin-submissions-overview";

const AdminRegistrationsView = () => {

    const TABS = useMemo(() => {
        return [
            // {
            //     title: "Overview",
            //     to: "/admin/registrations",
            //     search: "",
            //     type: "root"
            // },
            // {
            //     title: "_pause_",
            //     to: "",
            //     search: ""
            // },
            {
                title: "Attendees",
                to: "/admin/registrations",
                search: "",
                type: "root"
            },
            {
                title: "Payments",
                to: "/admin/registrations/payments",
                search: "",
                type: "root"
            }
        ]
    }, []);

    const location = useLocation();

    const returnToActive = useCallback(() => {

        const active_index = TABS.findIndex((tab) =>
            {
                return ((location.pathname === tab.to || location.pathname === tab.to + "/") &&
                    (location.search === tab.search ||
                        (tab.search === "" && !TABS.map(tx => tx.search).includes(location.search))
                    )
                )
            }
        );

        let link = document.getElementById(`registration_tab_${active_index.toString()}`);
        let floating_background = document.getElementById(`floating_background`);
        let floating_border = document.getElementById(`floating_border`);
        floating_background.style.width = ((link?.clientWidth-16) + "px");
        floating_background.style.transform = `translateX(${link?.offsetLeft}px)`;
        floating_border.style.width = ((link?.clientWidth-16) + "px");
        floating_border.style.transform = `translateX(${link?.offsetLeft}px)`;
        setTimeout(() => {
            floating_background.className = floating_background.className + "transition-all duration-300"
            floating_border.className = floating_border.className + "transition-all duration-300"
        }, 300)
    }, [TABS, location])

    useEffect(() => {
        returnToActive();
    }, [returnToActive])

    return (
        <>
            {/*Main*/}
            <div className={"flex items-end h-12 sticky border-b bg-gray-50 z-20 relative"} style={{ top: "64px" }}>
                <div className={"z-10 w-12 h-6 bg-primary-lighter mb-2 rounded-md absolute left-0"} id={"floating_background"} style={{ marginLeft: 0, marginRight: 0, willChange: "width" }}/>
                <div className={"z-10 w-12 h-6 border-b-2 border-primary-default pb-2 absolute left-0"} id={"floating_border"} style={{ marginLeft: 0, marginRight: 0, willChange: "width" }}/>
                {
                    TABS.map((t, index) => {

                        const active = ((location.pathname === t.to || location.pathname === t.to + "/") &&
                            (location.search === t.search ||
                                (t.search === "" && !TABS.map(tx => tx.search).includes(location.search))
                            )
                        )

                        if (t.title === "_pause_")
                            return (
                                <div key={`registration_tab_${index.toString()}`} className={"w-1.5 h-1.5 my-auto mx-4 bg-gray-400 rounded-full transform translate-y-1"}/>
                            )
                        else
                            return (
                                <Link key={`registration_tab_${index.toString()}`}
                                      id={`registration_tab_${index.toString()}`}
                                      to={t.to + (t.search === "*" ? "" : t.search)}
                                      className={`z-10 border-b-2 border-transparent group ${active ? "text-primary-default" : "text-gray-600"} font-medium pb-2 pr-4`}
                                      onMouseEnter={() => {
                                          let link = document.getElementById(`registration_tab_${index.toString()}`);
                                          let floating_background = document.getElementById(`floating_background`);
                                          let floating_border = document.getElementById(`floating_border`);
                                          floating_background.style.width = ((link.clientWidth-16) + "px");
                                          floating_background.style.transform = `translateX(${link.offsetLeft}px)`;
                                          floating_border.style.width = ((link.clientWidth-16) + "px");
                                          floating_border.style.transform = `translateX(${link.offsetLeft}px)`;
                                      }}
                                      onMouseLeave={() => {
                                          returnToActive();
                                      }}
                                >
                                    <span className={"py-1 px-2 transition duration-300 rounded-md"}>
                                        {t.title}
                                    </span>
                                </Link>
                            )
                    })
                }
            </div>
            <Switch>
                {/*<Route exact path={['/admin/submissions']} component={AdminSubmissionsOverview} />*/}
                <Route exact path={['/admin/registrations']} component={AdminRegistrationList} />
            </Switch>
        </>
    )

}

export default AdminRegistrationsView;