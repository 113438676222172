import {ArrowLeft, ArrowRight, Calendar} from "react-iconly";
import moment from "moment";
import Heading from "../../../main-pages/attendee-dashboard/components/heading";
import React from "react";
import {Submission} from "../../../models/submission";
import {secondary_button_class} from "../../buttons/secondary-button";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import { Link } from "react-router-dom";

const SubmissionHeading = ({submission, admin=false}: {submission?: Submission, admin?: boolean}) => {

    let title: string = (submission?.title ?? "");
    let returnText = admin ? "All Submissions": "My Submissions";
    let returnTo = admin ? "/admin/submissions/browse" : "/submissions";
    const admin_submissions = useSelector((state: RootState) => state.adminSubmissions);

    return (
        <Heading className={"px-6 text-sm"} title={title}
                 returnText={returnText}
                 returnTo={returnTo}
                 returnType={"back"}
                 button={
                     <div className={"flex w-full"}>
                         <div className={"mt-3 flex items-center"}>
                             <div className={`flex text-gray-800 h-7 space-x-2 items-center mr-3 ${!!submission ? "" : "animate-pulse bg-gray-200 rounded-lg"}`} style={{ minWidth: "60px" }}>
                                 <p className={"text-gray-800"}>
                                     {`${!!submission ? "#" : ""}${submission?.id ?? ""}`}
                                 </p>
                             </div>
                             <div className={`flex text-gray-800 h-7 space-x-2 items-center mr-6 ${!!submission ? "" : "animate-pulse bg-gray-200 rounded-lg"}`} style={{ minWidth: "120px" }}>
                                 <Calendar size={14} className={"ml-3"}/>
                                 <p className={"text-gray-800"}>
                                     {!!submission ? moment(submission?.createdAt).format("MMM DD, YYYY") : ""}
                                 </p>
                             </div>
                             <div>
                                 {
                                     (() => {
                                         const className = "flex items-center px-2 border border-gray-200 rounded-lg";

                                         switch (submission?.status) {
                                             case "draft":
                                                 return <div className={`${className} text-yellow-600`}>
                                                     Draft
                                                 </div>
                                             case "abstract-review":
                                             case "paper-review":
                                                 return <div className={`${className} text-yellow-600`}>
                                                     {admin ? "Pending Review" : "In Review"}
                                                 </div>
                                             case "re-submit-paper":
                                             case "re-submit-abstract":
                                                 return <div className={`${className} text-red-600`}>
                                                     Resubmit
                                                 </div>
                                             case "submit-paper":
                                                 return <div className={`${className} text-blue-800`}>
                                                     Abstract Accepted
                                                 </div>
                                             case "final":
                                                 return <div className={`${className} text-green-800`}>
                                                     Paper Accepted
                                                 </div>
                                         }
                                     })()
                                 }
                             </div>
                         </div>
                         {admin &&
                             <>
                                 <div className={"ml-auto w-10 h-10"}>
                                     <Link to={`/admin/submissions/${admin_submissions.previous(submission?.document_id)}`} className={`h-full ${admin_submissions.has_previous(submission?.document_id) ? "" : "pointer-events-none opacity-50"} ${secondary_button_class} `}>
                                          <span className={`absolute left-0 inset-y-0 flex items-center pl-3`}>
                                            <ArrowLeft className={`h-4 w-4 ${admin_submissions.has_previous(submission?.document_id) ? "text-primary-default" : "text-gray-500"}`}/>
                                        </span>
                                     </Link>
                                 </div>
                                 <div className={"ml-4 w-10 h-10"}>
                                     <Link to={`/admin/submissions/${admin_submissions.next(submission?.document_id)}`} className={`h-full ${admin_submissions.has_next(submission?.document_id) ? "" : "pointer-events-none opacity-50"} ${secondary_button_class} `}>
                                          <span className={`absolute left-0 inset-y-0 flex items-center pl-3`}>
                                            <ArrowRight className={`h-4 w-4 ${admin_submissions.has_next(submission?.document_id) ? "text-primary-default" : "text-gray-500"}`}/>
                                        </span>
                                     </Link>
                                 </div>
                             </>
                         }
                     </div>
                 }
        />
    )

}

export default SubmissionHeading;