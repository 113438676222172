
export class Activity {

    id: string = '';
    avatar: string = '';
    name: string = '';
    link: string = '';
    submission_data_type: string = '';
    content: string = '';
    submission_id: string = '';
    type: 'new-submission' | 'abstract-accepted' | 'abstract-rejected' | 're-submit-abstract' | 're-submit-paper' | 'uploaded-paper' | 'unknown' = 'unknown';
    createdAt: Date | null = null;

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? '';
        this.avatar = data?.avatar ?? '';
        this.name = data?.name ?? '';
        this.link = data?.link ?? '';
        this.submission_data_type = data?.submission_data_type ?? '';
        this.content = data?.content ?? '';
        this.submission_id = data?.submission_id ?? '';
        this.type = data?.type ?? 'unknown';
        this.createdAt = data?.createdAt ? (typeof data?.createdAt === "string" ? new Date(data.createdAt) : (data.createdAt instanceof Date ? data.createdAt : new Date(data.createdAt.toDate().toString()))) : null;
    }

    toJson(): Activity {
        return Object.assign({}, this);
    }

}