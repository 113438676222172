import {
    AlignmentType,
    convertInchesToTwip,
    Document,
    Header,
    HeadingLevel,
    LineRuleType,
    Packer,
    Paragraph,
    TableOfContents,
    TextRun
} from "docx";
import {saveAs} from "file-saver";

export const docx_export = ({heading, content}:{heading: string, content: Paragraph[]}) => {

    const doc = new Document({
        features: {
            updateFields: true,
        },
        styles: {
            paragraphStyles: [
                {
                    id: "Subtitle",
                    name: "Subtitle",
                    basedOn: "Normal",
                    next: "Normal",
                    run: {
                        color: "000000",
                        font: "Roboto",
                        italics: true,
                        size: 24
                    },
                    paragraph: {
                        indent: {
                            left: 1300,
                            right: 1300
                        },
                        spacing: {
                            before: 300,
                            after: 150,
                        },
                    },
                },
                {
                    id: "Subtitle2",
                    name: "Subtitle",
                    basedOn: "Normal",
                    next: "Normal",
                    run: {
                        color: "000000",
                        font: "Roboto",
                        size: 24
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 150,
                        },
                    },
                },
                {
                    id: "Content",
                    name: "Content",
                    basedOn: "Normal",
                    next: "Normal",
                    run: {
                        color: "000000",
                        font: "Roboto",
                        size: 24
                    },
                    paragraph: {
                        spacing: {
                            line: 360,
                            lineRule: LineRuleType.EXACTLY,
                            before: 120,
                            after: 120
                        },
                    },
                },
                {
                    id: "ContentBasic",
                    name: "ContentBasic",
                    basedOn: "Normal",
                    next: "Normal",
                    run: {
                        color: "000000",
                        font: "Roboto",
                        size: 20
                    },
                },
                {
                    id: "MainHeading",
                    name: "Main Heading",
                    basedOn: "Title",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        bold: true,
                        font: "Roboto",
                        size: 36,
                    },
                    paragraph: {
                        indent: {
                            left: 1300,
                            right: 1300
                        },
                        spacing: {
                            before: 240,
                            after: 150,
                        },
                    },
                },
            ],
        },
        sections: [
            {
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({
                                thematicBreak: true,
                                alignment: AlignmentType.RIGHT,
                                style: "ContentBasic",
                                children: [
                                    new TextRun({
                                        bold: true,
                                        text: heading
                                    })
                                ]
                            }),
                        ],
                    })
                },
                properties: {
                    page: {
                        pageNumbers: {
                            start: 1
                        },
                    }
                },
                children: content
            }
        ]
    })

    Packer.toBlob(doc).then(blob => {
        saveAs(blob, "ss.docx");
    })
}
