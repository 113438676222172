import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import Heading from "../components/heading";
import OnLoadViewer from "../../../components/on-load-viewer/on-load-viewer";
import axios from "axios";
import {BASE_API} from "../../../utils/constants";
import {Registration} from "../../../models/registration";
import RegistrationTicketSelect
    from "../../../components/registration-components/registration-form/registration-ticket-select";
import RegistrationPayment from "../../../components/registration-components/registration-form/registration-payment";
import {Elements} from "@stripe/react-stripe-js";
import RegistrationRegistrantDetails
    from "../../../components/registration-components/registration-form/registration-registrant-details";
import stripeJs, {loadStripe} from "@stripe/stripe-js";
import {formatCurrency} from "../../../utils/methods";
import {Bag} from "react-iconly";

// const stripePromise = loadStripe('pk_test_TTuBexQin7zJcwPYzPJr87iE')
const stripePromise = loadStripe('pk_live_21mqX2dz97srKt37Ght8UcjZ')
// const stripePromise = loadStripe('pk_test_Q6yZKI2NYFPKFuxwDaEzJ9qo00zFr99Y1j')
// const stripePromise = loadStripe('pk_live_k8c6DIjjtq7obSJHqHSPdac8006gnqsV1t')


const options: stripeJs.StripeElementsOptions = {
    fonts: [
        { cssSrc: "https://fonts.googleapis.com/css2?family=Inter&display=swap" }
    ]
};

const NewRegistration = () => {

    const conference = useSelector((state: RootState) => state.conference);

    return (
        <>
            <Heading returnTo={"/registrations"} returnText={"Registrations"} title={"New Registration"}/>
            <OnLoadViewer loading={conference.loading}>
                <div>
                    <RegistrationForm/>
                </div>
            </OnLoadViewer>
        </>
    )
}

const RegistrationForm = () => {

    const conference = useSelector((state: RootState) => state.conference);
    const user = useSelector((state: RootState) => state.userData);

    const [step, setStep] = useState<number>(0);
    const [registration, setRegistration] = useState<Registration>(new Registration({
        ...JSON.parse(localStorage.getItem("new_registration") ?? "{}"),
        conference_id: conference.id,
        conference_version_id: conference.active_conference,
        uid: user.userData?.uid
    }))

    useEffect(()=> {
        axios.post(`${BASE_API}/payments/heat`).then();
    }, [])

    const isValid = (tab: string) => {
        switch(tab) {
            case "Select Tickets":
                return registration.tickets.length > 0;
            case "Registrant Details":
                if (registration.tickets.length === 0)
                    return false;
                let has_missing_fields = false;
                conference.registration_data.forEach((section) => {
                    section.fields.forEach((field) => {
                        if (field.type === "personal_details") {
                            registration.tickets.forEach((t) => {
                                if (!t.registrant_details.name || !t.registrant_details.email)
                                    has_missing_fields = true;
                            })
                        } else {
                            if (field.required)
                                registration.tickets.forEach((t) => {
                                    if (!t.registrant_details.meta_data[field.name])
                                        has_missing_fields = true;
                                })
                        }
                    })
                })
                return !has_missing_fields;
            case "All":
                let has_missing_fields_x = false;
                conference.registration_data.forEach((section) => {
                    section.fields.forEach((field) => {
                        if (field.type === "personal_details") {
                            registration.tickets.forEach((t) => {
                                if (!t.registrant_details.name || !t.registrant_details.email)
                                    has_missing_fields_x = true;
                            })
                        } else {
                            if (field.required)
                                registration.tickets.forEach((t) => {
                                    console.log(t);
                                    if (!t.registrant_details.meta_data[field.name])
                                        has_missing_fields_x = true;
                                })
                        }
                    })
                })
                return !has_missing_fields_x && registration.tickets.length > 0;
            default:
                return false;
        }
    }

    const goToStep = (nextStep: number) => {
        if (nextStep > step) {
            setStep(nextStep);
        } else {
            setStep((prev) => prev-0.5);
            setTimeout(() => {
                setStep(nextStep)
            }, 80)
        }
    }

    const onChangeRegistration = (new_registration: Registration) => {
        setRegistration(new_registration);
        localStorage.setItem("new_registration", JSON.stringify(new_registration.toJson()))
    }



    return (
        <>
            <div className={"grid grid-cols-7"}>
                <div className={`hidden xl:block sticky py-8 top-16 bg-gray-50 col-span-1 h-0`}>
                    <div>
                        {["Select Tickets", "Registrant Details", "Payment"].map((tab, index) => {
                            return <div className={`flex items-center relative cursor-pointer py-1`} key={tab} onClick={() => { goToStep(index); }}>
                                <div className={`absolute h-full transition duration-75 ${step>=index ? 'bg-primary-default' : 'bg-gray-200'}`} style={{ width: "2px" }}/>
                                <p
                                    className={`
                                    select-none
                                    ml-3
                                    font-medium text-sm
                                    opacity-90 transition duration-300
                                    ${step===index ? 'text-primary-default' : ((step > index) ? (isValid(tab) ? "text-gray-900" : "text-red-700") : "text-gray-400")}`}
                                >
                                    {tab}
                                </p>
                            </div>
                        })}
                    </div>
                </div>

                <div className={"mx-1 col-span-7 lg:col-span-4"}>
                    <RegistrationTicketSelect
                        show={step===0}
                        registration={registration}
                        onSubmit={() => { goToStep(1) }}
                        onChange={onChangeRegistration}
                    />
                    <RegistrationRegistrantDetails
                        show={step===1}
                        registration={registration}
                        onBack={() => { goToStep(0) }}
                        onSubmit={() => { goToStep(2) }}
                        onChange={onChangeRegistration}
                    />
                    <Elements stripe={stripePromise} options={options}>
                        <RegistrationPayment
                            show={step===2}
                            registration={registration}
                            onBack={(val) => { goToStep(val ?? 1) }}
                            onSubmit={async (_) => {}}
                            isValid={isValid}
                        />
                    </Elements>
                </div>
                <div className={"hidden lg:block sticky py-8 top-16 bg-gray-50 col-span-3 xl:col-span-2 h-0 ml-6 xl:ml-0"}>
                    <div className={"rounded-xl pt-1 px-3 bg-gray-100 border border-gray-200 flex flex-col"}>
                        {registration.tickets.length > 0 && (
                            <div className={"flex items-center pt-3 pb-4"}>
                                <Bag set={"bulk"} className={"text-gray-500"} size={"small"}/>
                                <p className={"text-sm opacity-60 ml-2 font-medium"}>
                                    Order Summary
                                </p>
                            </div>
                        )}
                        {registration.ticket_lines.map((ticket, index) => {
                            return (
                                <div key={`ticket_lines_${index}`} className={"flex justify-between py-2 border-b border-gray-200"}>
                                    <p className={"text-sm opacity-70"}>
                                        {ticket.title}
                                    </p>
                                    <div className={"flex items-center ml-4 flex-shrink-0"}>
                                        <p className={"text-sm opacity-70"}>
                                            × {ticket.quantity}
                                        </p>
                                        <p className={"text-sm opacity-70 w-16 text-right"}>
                                            {ticket.price === 0 ? "FREE" : formatCurrency(ticket.price)}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                        {registration.tickets.length > 0 && (
                            <div className={"flex justify-between py-3"}>
                                <p className={"text-base opacity-70"}>
                                    Total
                                </p>
                                <p className={"text-lg font-medium opacity-80"}>
                                    {formatCurrency(registration.total)}
                                </p>
                            </div>
                        )}
                        {registration.tickets.length === 0 && (
                            <div className={"flex flex-col items-center justify-center py-6"}>
                                <Bag set={"bulk"} className={"text-gray-400"} size={"large"}/>
                                <p className={"text-base opacity-75 mt-2"}>
                                    Your cart is empty
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )

}

export default NewRegistration;