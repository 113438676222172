import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAPZibt3VygMyEB_qmgWkANMeRW1YW5hws",
    authDomain: "korero-conference.firebaseapp.com",
    databaseURL: "https://korero-conference.firebaseio.com",
    projectId: "korero-conference",
    storageBucket: "korero-conference.appspot.com",
    messagingSenderId: "7572561273",
    appId: "1:7572561273:web:120c41927964a8ef1063a8",
    measurementId: "G-SMZ8YM8PGP"
};

const firebaseApp = initializeApp(firebaseConfig);


// Firebase Instances
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

// Collections
export const users = collection(firestore, "users");
export const conferences = collection(firestore, "conferences/icsg/versions");
export const submissions = collection(firestore, "submissions");
export const submissions_analytics = collection(firestore, "submissions_analytics");
export const registrations = collection(firestore, "registrations");


