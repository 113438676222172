import { ReactRenderer } from '@tiptap/react'
import VariableList from './variable-list'
import {SuggestionOptions} from "@tiptap/suggestion";
import "./editor.css"
import tippy from "tippy.js";

const suggestion: Omit<SuggestionOptions, 'editor'> =  {
    char: "#",
    items: ({ query }) => {
        return [
            {id: "submission.title", label: 'Submission Title'},
            {id: "submission.track", label: 'Submission Track'},
            {id: "person.name", label: 'Author Name'},
            {id: "person.email", label: 'Author Email'},
            {id: "conference.conference_short_name", label: 'Conference Short Name'},
            {id: "conference.conference_full_name", label: 'Conference Full Name'}
        ]
            .filter(item => item.label.toLowerCase().includes(query.toLowerCase()))
    },
    command: ({ editor, range, props }) => {
        // increase range.to by one when the next node is of type "text"
        // and starts with a space character
        const nodeAfter = editor.view.state.selection.$to.nodeAfter
        const overrideSpace = nodeAfter?.text?.startsWith(' ')

        if (overrideSpace) {
            range.to += 1
        }

        editor
            .chain()
            .focus()
            .insertContentAt(range, [
                {
                    type: "mention",
                    attrs: props,
                },
                {
                    type: 'text',
                    text: ' ',
                },
            ])
            .run()

        window.getSelection()?.collapseToEnd()
    },
    render: () => {
        let component
        let popup

        return {
            onStart: props => {
                component = new ReactRenderer(VariableList, {
                    props,
                    editor: props.editor,
                })

                if (!props.clientRect) {
                    return
                }

                popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start'
                })
            },

            onUpdate(props) {
                component.updateProps(props)

                if (!props.clientRect) {
                    return
                }

                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                })
            },

            onKeyDown(props) {
                if (props.event.key === 'Escape') {
                    popup[0].hide()

                    return true
                }

                return component.ref?.onKeyDown(props)
            },

            onExit() {
                popup[0].destroy()
                component.destroy()
            },
        }
    },
}

export default suggestion;