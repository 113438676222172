import {Activity} from "../../../models/activity";
import React, {useCallback, useEffect, useState} from "react";
import {MoreCircle, Paper, PaperFail, PaperUpload, Plus, TickSquare, TimeSquare} from "react-iconly";
import moment from "moment";
import {secondary_button_class} from "../../buttons/secondary-button";
import DocumentViewer from "../../document-viewer/document-viewer";
import {collection, getDocs, orderBy, query} from "firebase/firestore";
import {submissions} from "../../../utils/firebase.utils";
import {RiDownloadLine} from "react-icons/all";

const SubmissionTimeline = ({id, friendly_id, admin=false}: {id: string, friendly_id?:string, admin?: boolean}) => {

    const [submission_activities, setSubmissionActivity] = useState<Activity[]>([]);
    const last = submission_activities[submission_activities.length-1];

    const getSubmissionActivity = useCallback(() => {
        setSubmissionActivity([]);
        getDocs(query(collection(submissions, id, 'activity'), orderBy('createdAt'))).then((actDocs) => {
            if (actDocs.size > 0) {
                setSubmissionActivity(actDocs.docs.map((doc) => {
                    let data = doc.data();
                    data.id = doc.id;
                    return new Activity(data);
                }))
            }
        })
    }, [id])

    useEffect(() => {

        if (id) {
            getSubmissionActivity();
        }

    }, [getSubmissionActivity, id]);

    const tiny_button_class = secondary_button_class.replace("w-full", "w-auto").replace("text-base", "text-xs").replace("rounded-xl", "rounded-lg")

    return (
        <>
            <div className={"flex items-center mb-4"}>
                <p className={"font-medium text-base"}>
                    Activity
                </p>
            </div>
            {submission_activities.length === 0 && (
                <>
                    <div className={"flex items-start relative pb-3 text-sm"} style={{ minHeight: "56px" }}>
                        <div className={"h-9 animate-pulse w-px bg-gray-200 absolute left-2.5 top-5"}/>
                        <div className={"text-gray-200 animate-pulse mt-px z-10 bg-gray-50"}>
                            <MoreCircle set={"bold"} size={20}/>
                        </div>
                        <div className={"ml-3"}>
                            <div className={"h-4 rounded-lg w-24 bg-gray-200 animate-pulse"}/>
                            <div className={"h-3 rounded-lg w-16 bg-gray-200 animate-pulse mt-1"}/>
                        </div>
                    </div>
                    <div className={"flex items-start relative"} style={{ minHeight: "56px" }}>
                        <div  className={"text-gray-200 animate-pulse mt-px z-10 bg-gray-50"}>
                            <MoreCircle set={"bold"} size={20}/>
                        </div>
                        <div className={"ml-3"}>
                            <div className={"h-4 rounded-lg w-24 bg-gray-200 animate-pulse"}/>
                            <div className={"h-3 rounded-lg w-16 bg-gray-200 animate-pulse mt-1"}/>
                        </div>
                    </div>
                </>
            )}
            {submission_activities.map((act) => {
                return <div key={act.id} className={"flex items-start relative pb-3 text-sm"} style={{ minHeight: "56px" }}>
                    <div className={"h-full w-px bg-gray-300 absolute left-2.5 top-1"}/>
                    {
                        (() => {
                            switch (act.type) {
                                case "new-submission":
                                    return <div className={"text-primary-default mt-px z-10 bg-gray-50"}>
                                        <Plus set={"bold"} size={20}/>
                                    </div>
                                case "abstract-accepted":
                                    return <div className={"text-primary-default mt-px z-10 bg-gray-50"}>
                                        <TickSquare set={"bold"} size={20}/>
                                    </div>
                                case "uploaded-paper":
                                    return <div className={"text-primary-default mt-px z-10 bg-gray-50"}>
                                        <PaperUpload set={"bold"} size={20}/>
                                    </div>
                                case "re-submit-abstract":
                                case "re-submit-paper":
                                    return <div className={"text-red-700 mt-px z-10 bg-gray-50"}>
                                        <PaperFail set={"bold"} size={20}/>
                                    </div>
                            }
                        })()
                    }
                    <div className={"ml-3"}>
                        {
                            (() => {
                                switch (act.type) {
                                    case "new-submission":
                                        return <p><span className={"font-medium"}>{act.name}</span> submitted an abstract.</p>
                                    case "abstract-accepted":
                                        return <>
                                            <p>
                                                <span className={"font-medium"}>{admin ? "The" : "Your" } abstract has been accepted.</span>
                                            </p>
                                            <p>
                                                {act.name}
                                            </p>
                                            <div className={"flex"}>
                                                <div className={"mt-1.5 mb-2"}>
                                                    <button className={`pl-2 pr-2 pt-1 pb-1 flex items-center ${tiny_button_class}`}>
                                                        View Details
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    case "re-submit-abstract":
                                    case "re-submit-paper":
                                        return <>
                                            <p>
                                                <span className={"font-medium"}>Please resubmit your submission.</span>
                                            </p>
                                            <p>
                                                {act.name}
                                            </p>
                                            <div className={"flex"}>
                                                <div className={"mt-1.5 mb-2"}>
                                                    <button className={`pl-2 pr-2 pt-1 pb-1 flex items-center ${tiny_button_class}`}>
                                                        View Details
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    case "uploaded-paper":
                                        return <>
                                            <p>
                                                <span className={"font-medium"}>{act.name}</span> uploaded their paper for review.
                                            </p>
                                            <div className={"flex justify-start space-x-2 mt-1.5 mb-2"}>
                                                <a href={act.link} rel={"noreferrer"} target={"_blank"} className={`pl-2 pr-2 pt-1 pb-1 flex items-center space-x-1 ${tiny_button_class}`}>
                                                    <RiDownloadLine/> <span>Download</span>
                                                </a>
                                                <DocumentViewer url={act.link} type={act.submission_data_type} title={friendly_id ?? id}>
                                                    <button className={`pl-2 pr-2 pt-1 pb-1 flex items-center ${tiny_button_class}`}>
                                                        View
                                                    </button>
                                                </DocumentViewer>
                                            </div>
                                        </>
                                }
                            })()
                        }
                        <p className={"text-gray-400 text-xs"}>{moment(act.createdAt).format("MMM DD, YYYY")}</p>
                    </div>
                </div>
            })}
            {last && (
                <div className={"flex items-start relative"} style={{ minHeight: "56px" }}>
                    <div className={"h-2 w-px bg-gray-300 absolute left-2.5 top-1"}/>
                    {
                        (() => {
                            switch (last?.type) {
                                case "new-submission":
                                case "uploaded-paper":
                                    return <div  className={"text-yellow-600 mt-px z-10 bg-gray-50"}>
                                        <TimeSquare set={"bold"} size={20}/>
                                    </div>
                                case "abstract-accepted":
                                    return <div className={`mt-px z-10 bg-gray-50 ${admin ? "text-yellow-600" : "text-blue-800"}`}>
                                        {
                                            admin ?
                                                <TimeSquare set={"bold"} size={20}/>
                                                :
                                                <Paper set={"bold"} size={20}/>
                                        }
                                    </div>
                                case "re-submit-abstract":
                                case "re-submit-paper":
                                    return <div  className={`${admin ? "text-yellow-600" : "text-blue-800"} mt-px z-10 bg-gray-50`}>
                                        {
                                            admin ?
                                                <TimeSquare set={"bold"} size={20}/>
                                                :
                                                <Paper set={"bold"} size={20}/>
                                        }
                                    </div>
                            }
                        })()
                    }
                    <div className={"ml-3 text-sm"}>
                        {
                            (() => {
                                switch (last?.type) {
                                    case "new-submission":
                                    case "uploaded-paper":
                                        return <p className={"text-yellow-600"}>Pending Review</p>
                                    case "abstract-accepted":
                                        return <>
                                            {admin ?
                                                <p className={"text-yellow-600"}>Waiting for author to submit paper.</p>
                                                :
                                                <p className={"text-blue-800"}>You can now submit your paper.</p>
                                            }
                                        </>

                                    case "re-submit-abstract":
                                    case "re-submit-paper":
                                        return <>
                                            {admin ?
                                                <p className={"text-yellow-600"}>Waiting for author to resubmit.</p>
                                                :
                                                <p className={"text-blue-800"}>{last?.type === "re-submit-abstract" ? "Resubmit your abstract." : "Resubmit your submission"}.</p>
                                            }
                                        </>
                                }
                            })()
                        }
                    </div>
                </div>
            )}
        </>
    )

}

export default SubmissionTimeline