import moment from "moment";
import React from "react";
import {useHistory} from "react-router-dom";
import {Registration} from "../../../../models/registration";
import {formatCurrency} from "../../../../utils/methods";

const AdminRegistrationRow = ({registration}: {registration:Registration}) => {

    const history = useHistory();

    return (
        <tr key={registration.document_id} className={`border-b border-gray-200 group hover:shadow-sm rounded-xl cursor-pointer transition duration-300`} onClick={() => { history.push("/admin/registrations/" + registration.document_id) }}>
            <td className="sm:p-3 py-2 px-1 group-hover:bg-white rounded-l-xl  transition duration-300">
                {registration.tickets.length}{/*<div className={"flex items-center space-x-3"}>*/}
                {/*    <div className={"text-xs opacity-70 w-24 flex-shrink-0"}>*/}
                {/*        #{submission.id}*/}
                {/*    </div>*/}
                {/*    <div className={"text-sm flex-shrink-0"}>*/}
                {/*        {authorValue(submission.authors)}*/}
                {/*    </div>*/}
                {/*    <div className={"flex space-x-2 text-black text-sm line-clamp-1 text-gray-400"}>*/}
                {/*        <span>•</span> <span>{submission.title}</span>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </td>
            <td className="sm:p-3 py-2 px-1 group-hover:bg-white text-sm transition duration-300">
                Delegate
            </td>
            <td className="sm:p-3 py-2 px-1 group-hover:bg-white rounded-r-xl transition duration-300">
                <div className={"flex items-center justify-end"}>
                    <p className={"text-sm"}>
                        {formatCurrency(registration.total)}
                    </p>
                </div>
            </td>
            <td className="sm:p-3 py-2 px-1 group-hover:bg-white rounded-r-xl transition duration-300">
                <div className={"flex items-center justify-end"}>
                    <p className={"text-sm"}>
                        {moment(registration?.createdAt).format(`MMM DD${new Date().getFullYear() === registration?.createdAt.getFullYear() ? "" : ", YY"}`)}
                    </p>
                </div>
            </td>
        </tr>
    )
}

export default AdminRegistrationRow;