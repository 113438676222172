import React from "react";
import {FiArrowLeft, FiX} from "react-icons/fi";
import {Link, useLocation} from "react-router-dom";

const Heading = ({title, subtitle, button, returnTo, className, returnText="Back", returnType="back"}: {title: string, subtitle?: string | React.ReactNode, button?: React.ReactNode, returnTo?: string | (() => void), className?: string, returnText?: string, returnType?: "back" | "close"}) => {

    const location = useLocation();

    return (
        <div className={`bg-gray-50 pb-3 pt-3.5 flex flex-col items-start border-b border-gray-200 z-10 w-full ${className}`}>
            {returnTo &&
            <Link tabIndex={-1} to={typeof  returnTo === "string" ? returnTo : location.pathname}
                  onClick={() => {
                      if (typeof returnTo !== "string")
                          returnTo()
                  }}
                  className={"flex items-center mb-1 text-gray-700 opacity-50 hover:opacity-100 hover:text-primary-light font-medium cursor-pointer"}>
                {
                    returnType === "close" ?
                        <FiX className={"mr-2"}/>
                        :
                        <FiArrowLeft className={"mr-2"}/>
                }
                {returnText}
            </Link>
            }
            <h1 className={`font-semibold text-2xl text-gray-900 ${!!title ? "max-w-4xl" : "animate-pulse bg-gray-200 rounded-lg max-w-md w-full"}`} style={{ minHeight: "1rem" }}>
                {title}
            </h1>
            {subtitle &&
                <div className="text-lg text-gray-500 mt-1 max-w-md">
                    {subtitle}
                </div>
            }
            <div className={"flex w-full"}>
                {button && button}
            </div>
        </div>
    )

}

export default Heading