import {Submission} from "../../models/submission";
import SubmissionAnalytics from "../../models/submission-analytics";
import {doc, getDoc} from "firebase/firestore";
import {registrations, submissions} from "../../utils/firebase.utils";
import {Registration} from "../../models/registration";


// Action Types
export const SET_ADMIN_REGISTRATIONS = 'SET_ADMIN_REGISTRATIONS';
export const UPDATE_ADMIN_REGISTRATIONS = 'UPDATE_ADMIN_REGISTRATIONS';
export const SET_ADMIN_REGISTRATIONS_ANALYTICS = 'SET_ADMIN_REGISTRATIONS_ANALYTICS';

interface SetAdminRegistrations {
    type: typeof SET_ADMIN_REGISTRATIONS,
    payload: {
        registrations: Registration[]
    }
}

interface UpdateAdminRegistrations {
    type: typeof UPDATE_ADMIN_REGISTRATIONS,
    payload: {
        registrations: Registration[]
    }
}

interface SetAdminRegistrationAnalytics {
    type: typeof SET_ADMIN_REGISTRATIONS_ANALYTICS,
    payload: {
        analytics: SubmissionAnalytics
    }
}

export type AdminRegistrationsActionTypes = SetAdminRegistrations | SetAdminRegistrationAnalytics | UpdateAdminRegistrations

// State
export class AdminRegistrationsState {

    loading?: boolean
    registrations: Registration[]
    analytics: SubmissionAnalytics

    constructor(data?: { [field: string]: any }) {
        this.loading = data?.loading ?? true;
        if (data?.registrations) {
            this.registrations = data?.registrations.map((r: { [field: string]: any }) => new Registration(r));
        } else {
            this.registrations = [];
        }
        this.analytics = new SubmissionAnalytics(data?.analytics);
    }

    has_next (document_id:string): boolean {
        let i = this.registrations.findIndex(s => s.document_id  === document_id);
        if (i===-1)
            return false;

        return (i + 1) < this.registrations.length;
    }

    next (document_id:string): string {

        if (this.has_next(document_id)) {
            let i = this.registrations.findIndex(s => s.document_id  === document_id);
            return this.registrations[i+1].document_id;
        } else {
            return null;
        }

    }

    has_previous (document_id:string): boolean {
        let i = this.registrations.findIndex(s => s.document_id  === document_id);
        if (i===-1)
            return false;

        if ((i-1) >= 0)
            return true;
    }

    previous (document_id:string): string {

        if (this.has_previous(document_id)) {
            let i = this.registrations.findIndex(s => s.document_id  === document_id);
            return this.registrations[i-1].document_id;
        } else {
            return null;
        }

    }

    async registration (id: string): Promise<Registration | null> {
        let s:Registration = this.registrations.find(s => s.document_id === id);

        if (s) {
            return s;
        } else {
            let registration_doc = await getDoc(
                doc(
                    registrations,
                    id
                )
            );

            if (registration_doc.exists()) {
                let data = registration_doc.data();
                data.document_id = registration_doc.id;

                return new Registration(data);
            } else {
                return null;
            }
        }
    }
}