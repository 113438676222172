import React from "react";

function FormErrorText(
    {
        error,
        showError,
        style
    } : {
        error?: Record<string, string | boolean> | string,
        showError?: boolean,
        style?: React.CSSProperties
    }) {

    let errorValue;

    if (typeof error === "string")
        errorValue=error;
    else
        errorValue=error?.text;

    return (
        (showError && !!errorValue) ?
            <div style={{ ...style }} className={"text-base mt-1"}>
                <p style={{ minHeight: "12px", textTransform:"none"}} className={"text-red-700"}>
                    {errorValue}
                </p>
            </div>
            :
            <></>
    )
}

export default FormErrorText;