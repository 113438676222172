import React from "react";
import {Link, Route, Switch, useLocation} from "react-router-dom";
import {Category, Document, Ticket} from 'react-iconly'
import Overview from "./overview/overview";
import MySubmissions from "./my-submissions/my-submissions";
import NewSubmission from "./new-submission/new-submission";
import SubmissionDetails from "./submission-details/submission-details";
import MyRegistrations from "./my-registrations/my-registrations";
import NewRegistration from "./new-registration/new-registration";
import NavigationBar from "./components/navigation-bar";


const AttendeeWrapper = () => {

    const location = useLocation();

    const menuItems = [
        {
            icon: Category,
            label: "Overview",
            to: "/"
        },
        {
            icon: Document,
            label: "Submit",
            to: "/submissions"
        }
        // {
        //     icon: Ticket,
        //     label: "Register",
        //     to: "/registrations"
        // }
    ]

    return (
        <div className="bg-gray-50 min-h-screen text-sm">

            {/*Top Navbar*/}
            <NavigationBar/>

            {/*Content*/}
            <div className="flex-grow flex w-full">
                {/*Navigation Side Bar*/}
                <div className={"flex z-30 flex-col w-18 flex-shrink-0 bg-white px-2 py-8 space-y-8 border-r border-gray-200 sticky top-16"} style={{ height: "calc(100vh - 4rem)" }}>
                    {
                        menuItems.map((item) => {

                            const active = (location.pathname.startsWith(item.to) && item.to !== "/") || (location.pathname === item.to);

                            return (
                                <div key={item.to.replace("/", "")}>
                                    <Link tabIndex={1} key={item.to} to={item.to} className={`outline-none ring-1 ring-transparent focus:ring-gray-200 focus:bg-gray-100 border-dashed rounded-lg group h-full flex w-full items-center justify-start text-xs font-normal flex-col transition duration-100 ${active ? "text-primary-default" : ""}`}>
                                        <div className={`p-2 w-full flex justify-center rounded-lg ${active ? "bg-primary-lighter" : "group-hover:bg-gray-100"} transition-bg duration-300`}>
                                            {item.icon &&
                                            React.createElement(item.icon, { set: 'bulk', size: 34, style: {flexShrink: 0, opacity: active ? "1.0" : "0.50"} })
                                            }
                                        </div>
                                        <span className={"font-medium my-1 tracking-tight px-1"} style={{ opacity: active ? "1.0" : "0.80" }}>
                                            {item.label}
                                        </span>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
                {/*Views*/}
                <div className={"flex-grow"}>
                    <div className={"max-w-7xl px-3 sm:px-10 mx-auto"}>
                        <Switch>
                            <Route exact path={'/'} component={Overview} />

                            <Route exact path={['/submissions']} component={MySubmissions} />
                            <Route exact path={['/submissions/new']} component={NewSubmission} />
                            <Route exact path={['/submissions/:id']} component={SubmissionDetails} />

                            <Route exact path={'/registrations'} component={MyRegistrations} />
                            <Route exact path={'/registrations/new'} component={NewRegistration} />

                            {/*<OpenRoute>*/}
                            {/*    <div>404</div>*/}
                            {/*</OpenRoute>*/}
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AttendeeWrapper;