import StaticDate from "./static-date";

export class RegistrationStage {
    title: string
    end_date: StaticDate | undefined;

    constructor(data?: { [field: string]: any }) {
        this.title = data?.title ?? '';
        this.end_date = new StaticDate(data?.end_date) ?? undefined;
    }
}

export class RegistrationData {
    title: string
    fields: RegistrationField[];

    constructor(data?: { [field: string]: any }) {
        this.title = data?.title ?? '';
        if (data?.fields) {
            this.fields = data?.fields.map((r: { [field: string]: any }) => new RegistrationField(r));
        } else {
            this.fields = [];
        }
    }
}

export class RegistrationField {
    autoComplete: string
    label: string
    name: string
    options: string[]
    placeholder: string
    required: boolean
    type: "text" | "personal_details" | "organization" | "country"

    constructor(data?: { [field: string]: any }) {
        this.autoComplete = data?.autoComplete ?? '';
        this.label = data?.label ?? '';
        this.name = data?.name ?? '';
        this.options = data?.options ?? [];
        this.placeholder = data?.placeholder ?? '';
        this.required = data?.required ?? false;
        this.type = data?.type ?? '';
    }
}

export class TicketData {
    currency: string
    title: string
    tickets: Ticket[]
    country: string
    default: boolean

    constructor(data?: { [field: string]: any }) {
        this.currency = data?.currency ?? '';
        this.title = data?.title ?? '';
        this.country = data?.country ?? '';
        this.default = data?.default ?? '';
        if (data?.tickets) {
            this.tickets = data?.tickets.map((r: { [field: string]: any }) => new Ticket(r));
        } else {
            this.tickets = [];
        }
    }
}

export class Ticket {
    id: string
    title: string
    description: string
    price: number
    ticket_type: "paid" | "free"

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? '';
        this.description = data?.description ?? '';
        this.title = data?.title ?? '';
        this.ticket_type = data?.ticket_type ?? 'free';
        this.price = data?.price ?? 0;
    }
}

export class RegistrationPostConferenceOption {
    id: string
    title: string
    price: number

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? '';
        this.title = data?.title ?? '';
        this.price = data?.price ?? 0;
    }
}

export class RegistrationPostConference {
    id: string
    title: string
    description: string
    short_description: string
    link: string
    image: string
    price: number
    options: RegistrationPostConferenceOption[]

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? '';
        this.title = data?.title ?? '';
        this.description = data?.description ?? '';
        this.short_description = data?.short_description ?? '';
        this.link = data?.link ?? '';
        this.image = data?.image ?? '';
        this.price = data?.price ?? 0;
        if (data?.options) {
            this.options = data?.options.map((r: { [field: string]: any }) => new RegistrationPostConferenceOption(r));
        } else {
            this.options = [];
        }
    }

    get price_can_change(): boolean {
        let can_change = false;

        if (this.options.length > 0) {
            this.options.forEach(opt => {
                if (opt.price > 0)
                    can_change = true;
            })
        }

        return can_change;
    }
}

export class ConferenceSettings {
    id: string;
    active_conference: string;

    conference_short_name: string;
    conference_full_name: string;
    subtitle: string;

    logo: string;
    icon: string;

    background_auth: string;
    background_theme: string;

    end_date: StaticDate;
    start_date: StaticDate;
    abstract_deadline: StaticDate;
    submission_deadline: StaticDate;
    registration_stages: RegistrationStage[];
    registration_data: RegistrationData[];
    registration_post_conference: RegistrationPostConference[]
    ticket_data: TicketData[];
    currency: string;
    location: string;
    website: string;

    email_template_submission_abstract_accepted: string
    email_template_submission_abstract_revise: string
    email_template_submission_abstract_reject: string


    constructor(data?: { [field: string]: any }) {

        this.id = data?.id ?? '';
        this.active_conference = data?.active_conference ?? '';

        this.conference_short_name = data?.conference_short_name ?? '';
        this.conference_full_name = data?.conference_full_name ?? '';
        this.subtitle = data?.subtitle ?? '';

        this.logo = data?.logo ?? '';
        this.icon = data?.icon ?? '';

        this.background_auth = data?.background_auth ?? '';
        this.background_theme = data?.background_theme ?? '';

        this.end_date = new StaticDate(data?.end_date) ?? undefined;
        this.start_date = new StaticDate(data?.start_date) ?? undefined;
        this.abstract_deadline = new StaticDate(data?.abstract_deadline) ?? undefined;
        this.submission_deadline = new StaticDate(data?.submission_deadline) ?? undefined;
        if (data?.registration_stages) {
            this.registration_stages = data?.registration_stages.map((r: { [field: string]: any }) => new RegistrationStage(r));
        } else {
            this.registration_stages = [];
        }
        if (data?.registration_data) {
            this.registration_data = data?.registration_data.map((r: { [field: string]: any }) => new RegistrationData(r));
        } else {
            this.registration_data = [];
        }
        if (data?.registration_post_conference) {
            this.registration_post_conference = data?.registration_post_conference.map((r: { [field: string]: any }) => new RegistrationPostConference(r));
        } else {
            this.registration_post_conference = [];
        }
        if (data?.ticket_data) {
            this.ticket_data = data?.ticket_data.map((r: { [field: string]: any }) => new TicketData(r));
        } else {
            this.ticket_data = [];
        }
        this.currency = data?.currency ?? '';
        this.location = data?.location ?? '';
        this.website = data?.website ?? '';

        this.email_template_submission_abstract_accepted = data?.email_template_submission_abstract_accepted ?? '';
        this.email_template_submission_abstract_revise = data?.email_template_submission_abstract_revise ?? '';
        this.email_template_submission_abstract_reject = data?.email_template_submission_abstract_reject ?? '';
    }

    get is_registration_available(): boolean {
        return !!this.next_registration_deadline
    }

    get next_registration_deadline (): Date | undefined {
        let next_deadline: Date | undefined;
        let now = new Date();
        this.registration_stages.forEach((stage) => {
            if (stage.end_date.date > now) {
                if (next_deadline) {
                    if (next_deadline > stage.end_date.date)
                        next_deadline = stage.end_date.date;
                } else {
                    next_deadline = stage.end_date.date;
                }
            }
        })

        return next_deadline;
    }

    get last_registration_deadline (): Date | undefined {
        let last_registration_deadline: Date | undefined;
        let now = new Date();
        this.registration_stages.forEach((stage) => {
            if (stage.end_date.date > now) {
                if (last_registration_deadline) {
                    if (last_registration_deadline < stage.end_date.date)
                        last_registration_deadline = stage.end_date.date;
                } else {
                    last_registration_deadline = stage.end_date.date;
                }
            }
        })

        return last_registration_deadline;
    }

    get current_registration_stage (): RegistrationStage | undefined {
        let current_stage: RegistrationStage | undefined;
        let now = new Date();
        this.registration_stages.forEach((stage) => {
            if (stage.end_date.date > now) {
                if (current_stage?.end_date) {
                    if (current_stage.end_date.date > stage.end_date.date)
                        current_stage = stage;
                } else {
                    current_stage = stage;
                }
            }
        })

        return current_stage;
    }
}