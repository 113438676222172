import {Submission} from "../../models/submission";
import SubmissionAnalytics from "../../models/submission-analytics";
import {doc, getDoc} from "firebase/firestore";
import {submissions} from "../../utils/firebase.utils";


// Action Types
export const SET_ADMIN_SUBMISSIONS = 'SET_ADMIN_SUBMISSIONS';
export const UPDATE_ADMIN_SUBMISSIONS = 'UPDATE_ADMIN_SUBMISSIONS';
export const SET_ADMIN_SUBMISSIONS_ANALYTICS = 'SET_ADMIN_SUBMISSIONS_ANALYTICS';

interface SetAdminSubmissions {
    type: typeof SET_ADMIN_SUBMISSIONS,
    payload: {
        submissions: Submission[]
    }
}

interface UpdateAdminSubmissions {
    type: typeof UPDATE_ADMIN_SUBMISSIONS,
    payload: {
        submissions: Submission[]
    }
}

interface SetAdminSubmissionsAnalytics {
    type: typeof SET_ADMIN_SUBMISSIONS_ANALYTICS,
    payload: {
        analytics: SubmissionAnalytics
    }
}

export type AdminSubmissionsActionTypes = SetAdminSubmissions | SetAdminSubmissionsAnalytics | UpdateAdminSubmissions

// State
export class AdminSubmissionsState {
    loading?: boolean
    submissions: Submission[]
    analytics: SubmissionAnalytics

    constructor(data?: { [field: string]: any }) {
        this.loading = data?.loading ?? true;
        if (data?.submissions) {
            this.submissions = data?.submissions.map((r: { [field: string]: any }) => new Submission(r));
        } else {
            this.submissions = [];
        }
        this.analytics = new SubmissionAnalytics(data?.analytics);
    }

    has_next (document_id:string): boolean {
        let i = this.submissions.findIndex(s => s.document_id  === document_id);
        if (i===-1)
            return false;

        return (i + 1) < this.submissions.length;
    }

    next (document_id:string): string {

        if (this.has_next(document_id)) {
            let i = this.submissions.findIndex(s => s.document_id  === document_id);
            return this.submissions[i+1].document_id;
        } else {
            return null;
        }

    }

    has_previous (document_id:string): boolean {
        let i = this.submissions.findIndex(s => s.document_id  === document_id);
        if (i===-1)
            return false;

        if ((i-1) >= 0)
            return true;
    }

    previous (document_id:string): string {

        if (this.has_previous(document_id)) {
            let i = this.submissions.findIndex(s => s.document_id  === document_id);
            return this.submissions[i-1].document_id;
        } else {
            return null;
        }

    }

    async submission (id: string): Promise<Submission | null> {
        let s:Submission = this.submissions.find(s => s.document_id === id);

        if (s) {
            return s;
        } else {
            let submission_doc = await getDoc(
                doc(
                    submissions,
                    id
                )
            );

            if (submission_doc.exists()) {
                let data = submission_doc.data();
                data.document_id = submission_doc.id;

                return new Submission(data);
            } else {
                return null;
            }
        }
    }
}