import React, {useEffect, useState} from "react";
import Heading from "../../../attendee-dashboard/components/heading";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/index.reducers";
import {Submission} from "../../../../models/submission";
import {onSnapshot, orderBy, query, where} from "firebase/firestore";
import {submissions} from "../../../../utils/firebase.utils";
import OnLoadViewer from "../../../../components/on-load-viewer/on-load-viewer";
import AdminSubmissionRow from "../../../../components/submission-components/submission-row/admin-submission-row";
import SlidingDialog from "../../../../components/dialogs/sliding-dialog";
import {useHistory, useParams} from "react-router-dom";

const AdminDecisionsView = () => {

    const conference = useSelector((state: RootState) => state.conference);
    const user = useSelector((state: RootState) => state.userData);

    const [loading, setLoading] = useState(true);
    const [allSubmissions, setAllSubmissions] = useState<Submission[]>([]);

    const { id }: {id: string} = useParams();
    const history = useHistory();
    // const selectedSubmission = allSubmissions.find((s) => s.document_id===id);

    useEffect(() => {

        if (conference.id) {
            let ref = query(
                submissions,
                where('conference_id', '==', conference.id),
                where('conference_version_id', '==', conference.active_conference),
                where('status', 'in', ['abstract-review', 'paper-review']),
                orderBy('createdAt', 'desc')
            );

            let observer = onSnapshot(ref, (snapshot) => {
                if (snapshot.empty) {
                    setAllSubmissions([])
                } else {
                    let s:Submission[] = [];
                    snapshot.forEach((doc) => {
                        let data = doc.data();
                        data.document_id = doc.id;
                        s.push(new Submission(data))
                    });
                    setAllSubmissions(s);
                }
                setLoading(false);
            })

            return () => observer();
        }

    }, [conference.active_conference, conference.id, user.userData?.uid])

    return (
        <>
            <Heading title={"Decisions"} />

            <div className={"bg-blacks-dark rounded-xl w-full my-6 grid grid-cols-5 divide-x-2 divide-black"}>
                <div className={"text-white py-4 pl-6"}>
                    <p>
                        Pending Abstracts
                    </p>
                    <p className={"text-2xl font-bold"}>
                        {allSubmissions.filter((s) => s.status === "abstract-review").length}
                    </p>
                </div>
                <div className={"text-white py-4 pl-6"}>
                    <p>
                        Pending Papers
                    </p>
                    <p className={"text-2xl font-bold"}>
                        {allSubmissions.filter((s) => s.status === "paper-review").length}
                    </p>
                </div>
            </div>

            <OnLoadViewer loading={conference.loading}>
                <table className="table-fixed w-full text-left  mb-6">
                    <thead className={`bg-gray-50 h-12 px-3 py-3 text-gray-400 sticky z-20`} style={{ top: `64px`, boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>
                        <tr>
                            <th className="font-normal sm:p-3 py-2 px-1" style={{ width: "10%" }}>#</th>
                            <th className="font-normal sm:p-3 py-2 px-1" style={{ width: "50%" }}>Title</th>
                            <th className="font-normal sm:p-3 py-2 px-1" style={{ width: "15%" }}>Format</th>
                            <th className="font-normal sm:p-3 py-2 px-1" style={{ width: "15%" }}>Created</th>
                            <th className="font-normal sm:p-3 py-2 px-1 sm:text-gray-400 text-white" style={{ width: "15%" }}>Status</th>
                        </tr>
                    </thead>
                    <tbody className={"text-gray-700 relative"}>
                        <OnLoadViewer loading={loading} component={"tr"} childComponent={"td"} className={"absolute"}>
                            {
                                allSubmissions.map((submission) => {
                                    return <AdminSubmissionRow submission={submission}/>
                                })
                            }
                        </OnLoadViewer>
                    </tbody>
                </table>
                <SlidingDialog open={!!id} onClose={() => { history.push("/admin/submissions/view") }}>
                </SlidingDialog>
            </OnLoadViewer>
        </>
    )

}

export default AdminDecisionsView;