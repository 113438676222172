import {Fragment, useState} from "react";
import Fade from "../../transitions/fade";
import React from "react"
import PrimaryButton from "../../buttons/button";
import SecondaryButton from "../../buttons/secondary-button";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import FormErrorText from "../../forms/form-error-text";
import {Registration} from "../../../models/registration";
import {FiLock, HiCreditCard} from "react-icons/all";
import {People, Ticket} from "react-iconly";
import ZoomFadeDialog from "../../dialogs/zoom-fade-dialog";
import Heading from "../../../main-pages/attendee-dashboard/components/heading";
import axios from "axios";
import {BASE_API} from "../../../utils/constants";
import {registrations} from "../../../utils/firebase.utils";
import { addDoc } from "firebase/firestore";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";

const RegistrationPayment = ({show=true, registration, isValid, onBack, onSubmit}: {show: boolean, registration: Registration, isValid: (v:string) => boolean, onSubmit: (token:string) => Promise<unknown>, onBack: (val?: number)=>void }) => {

    const user = useSelector((state: RootState) => state.userData);

    const [loading, setLoading] = useState(false);
    const [cardDialog, setCardDialog] = useState(false);
    const [error, setError] = useState("");
    const history = useHistory();

    let stripe = useStripe();
    let elements = useElements();

    // const postRegistration = (token:string) => {
    // return new Promise(async (resolve, reject) => {
    //     let s = secret;
    //     if (!secret) {
    //         let r = await axios.post(`${BASE_API}/payments/stripe/secret/${100}`);
    //         let secretData = await r.data;
    //         s = secretData['secret'];
    //         setSecret(secretData['secret']);
    //     }
    //     stripe?.confirmCardPayment(s ?? "", {
    //         payment_method: {
    //             card: { token: token },
    //             billing_details: {
    //                 // name: registration.people?.name ?? "",
    //                 // email: registration.people?.email ?? ""
    //             }
    //         }
    //     })
    //         .then(async (result) => {
    //             if (result.error) {
    //                 console.log("here", result.error);
    //                reject(result.error.message);
    //             } else {
    //                 resolve(null);
    //                 addDoc(registrations, {
    //                     ...registration.toJson(),
    //                     status: "paid",
    //                     payment: result.paymentIntent
    //                 })
    //                 history.push("/registrations")
    //             }
    //
    //         })
    //         .catch((e) => {
    //             console.log("here", e);
    //             reject(e);
    //         });
    // })
    // }

    return (
        <Fade
            show={show}

            as={Fragment}
        >
            <div>
                <p className={"max-w-lg pt-6 pb-2 text-xl font-medium border-b border-gray-200"}>
                    Make Payment:
                </p>

                {loading && (
                    <div className={"fixed top-0 left-0 w-screen h-screen bg-black/50 "} style={{ zIndex: 2147483647 }}/>
                )}
                <div className={"max-w-lg mt-4"}>
                    {registration.tickets.length === 0 && (
                        <div className={"rounded-xl py-8 px-3 text-center bg-gray-100 border border-gray-200 flex flex-col items-center justify-center space-y-2"}>
                            <Ticket set={"bulk"} className={"text-primary-default"} size={"xlarge"}/>
                            <span>
                                Please select tickets before<br/> making payment.
                            </span>
                            <div className={"flex "}>
                                <SecondaryButton onClick={() => { onBack(0) }}>
                                    Select Tickets
                                </SecondaryButton>
                            </div>
                        </div>
                    )}
                    {registration.tickets.length > 0 && !isValid("Registrant Details") && (
                        <div className={"rounded-xl py-8 px-3 text-center bg-gray-100 border border-gray-200 flex flex-col items-center justify-center space-y-2"}>
                            <People set={"bulk"} className={"text-primary-default"} size={"xlarge"}/>
                            <span>
                                Please enter registrant details before <br/>making payment.
                            </span>
                            <div className={"flex "}>
                                <SecondaryButton onClick={() => { onBack() }}>
                                    Enter Registrant Details
                                </SecondaryButton>
                            </div>
                        </div>
                    )}
                    {(isValid("Registrant Details") && isValid("Select Tickets")) && (
                        <>
                            <p className={"text-sm font-medium opacity-80 mb-1.5"}>
                                Select Payment Method
                            </p>
                            <div className={"divide-y"}>
                                <button onClick={() => { setCardDialog(true); }} className={"w-full rounded-lg py-2.5 px-4 text-center bg-gray-100 border border-gray-200 flex items-center justify-start space-x-3 hover:bg-white"}>
                                    <HiCreditCard className={"text-primary-default"} size={18}/>
                                    <span className={"text-sm font-medium"}>
                                        Credit/Debit Card
                                    </span>
                                </button>
                            </div>
                        </>
                    )}
                    <ZoomFadeDialog open={cardDialog} onClose={() => { if (!loading) setCardDialog(false) }}>
                        <div className={"px-6 pb-6"}>
                            <div className={"sticky top-0 z-10"}>
                                <Heading title={"Credit/Debit Card"} className={"bg-gray-50"}/>
                            </div>
                            <form onSubmit={async (event) => {
                                event.preventDefault();
                                setLoading(true);
                                let data = elements?.getElement("cardNumber");
                                if (data) {
                                    stripe?.createPaymentMethod({type: 'card', card: data})?.then(async ({error, paymentMethod}) => {
                                        if (error) {
                                            setLoading(false);
                                            setError(error.message ?? "Something went wrong.")
                                        } else {
                                            if (paymentMethod) {
                                                let r = await axios.post(`${BASE_API}/payments/stripe/secret/${(registration.total*100).toFixed(0)}`);
                                                let secretData = await r.data;
                                                let s = secretData['secret'];
                                                stripe?.confirmCardPayment(s ?? "", {
                                                    payment_method: {
                                                        card: data,
                                                        billing_details: {
                                                            name: user.userData.name,
                                                            email: user.userData.email
                                                        }
                                                    },
                                                    receipt_email: user.userData.email
                                                })
                                                    .then(async (result) => {
                                                        if (result.error) {
                                                            console.log("here", result.error);
                                                            setLoading(false)
                                                            setError(result.error.message ?? "Something went wrong.")
                                                        } else {
                                                            await addDoc(registrations, {
                                                                ...JSON.parse(JSON.stringify(registration)),
                                                                status: "paid",
                                                                payment: result.paymentIntent,
                                                                createdAt: new Date()
                                                            })
                                                            localStorage.removeItem("new_registration");
                                                            history.push("/registrations")
                                                        }

                                                    })
                                                    .catch((e) => {
                                                        console.log("here", e);
                                                        setLoading(false)
                                                        setError(e.message ?? "Something went wrong.")
                                                    });
                                            }
                                        }
                                    })
                                }
                            }} noValidate className={"mt-4"}>
                                <div className={"grid grid-cols-5 gap-3"}>
                                    <div className={"col-span-5"}>
                                        <CardNumberElement options={{
                                            showIcon: true,
                                            style: {
                                                base: {
                                                    backgroundColor: "#fff",
                                                    iconColor: '#13172F',
                                                    color: 'black',
                                                    fontWeight: '500',
                                                    fontFamily: 'Basier, Inter, Open Sans, Segoe UI, sans-serif',
                                                    fontSize: '16px',
                                                    fontSmoothing: 'antialiased',
                                                    '::placeholder': {
                                                        color: '#6B7280',
                                                    },
                                                },
                                            },
                                            iconStyle: 'default'
                                        }}/>
                                    </div>
                                    <div className={"col-span-3"}>
                                        <CardExpiryElement options={{
                                            style: {
                                                base: {
                                                    backgroundColor: "#fff",
                                                    iconColor: '#13172F',
                                                    color: 'black',
                                                    fontWeight: '500',
                                                    fontFamily: 'Basier, Inter, Open Sans, Segoe UI, sans-serif',
                                                    fontSize: '16px',
                                                    fontSmoothing: 'antialiased',
                                                    '::placeholder': {
                                                        color: '#6B7280',
                                                    },
                                                },
                                            },
                                        }}/>
                                    </div>
                                    <div className={"col-span-2"}>
                                        <CardCvcElement options={{
                                            style: {
                                                base: {
                                                    backgroundColor: "#fff",
                                                    iconColor: '#13172F',
                                                    color: 'black',
                                                    fontWeight: '500',
                                                    fontFamily: 'Basier, Inter, Open Sans, Segoe UI, sans-serif',
                                                    fontSize: '16px',
                                                    fontSmoothing: 'antialiased',
                                                    '::placeholder': {
                                                        color: '#6B7280',
                                                    },
                                                },
                                            },
                                        }}/>
                                    </div>
                                </div>
                                <FormErrorText error={error} showError={!!error}/>
                                <div className={"sticky bottom-0 pt-4 max-w-lg w-full z-40 border-t border-gray-200 mt-6 bg-gray-50 ring-1 ring-gray-50"} >
                                    <div className={"flex space-x-6"}>
                                        <SecondaryButton type={"button"} className={"mt-2 text-gray-600"} onClick={() => { if (!loading) setCardDialog(false) }} style={{ width: "auto" }}>
                                            Back
                                        </SecondaryButton>
                                        <PrimaryButton
                                            loading={loading}
                                            type={"submit"}
                                            className={"mt-2"}
                                            style={{ width: "auto" }}
                                        >
                                            <FiLock className={"mr-2 "}/> Pay Securely
                                        </PrimaryButton>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ZoomFadeDialog>
                </div>
            </div>
        </Fade>
    )
}

export default RegistrationPayment;