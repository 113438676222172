import OnLoadViewer from "../../../../components/on-load-viewer/on-load-viewer";
import AdminSubmissionRow from "../../../../components/submission-components/submission-row/admin-submission-row";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/index.reducers";

const AdminSubmissionsList = () => {

    const conference = useSelector((state: RootState) => state.conference);
    const admin_submissions = useSelector((state: RootState) => state.adminSubmissions);

    return (
        <OnLoadViewer loading={conference.loading}>
            <table className="table-fixed w-full text-left  mb-6">
                <thead className={`bg-gray-50 h-12 px-3 py-3 text-gray-700 sticky z-20`} style={{ top: `112px`, boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>
                <tr>
                    <th className="font-normal text-sm sm:p-3 py-2 px-1" style={{ width: "75%" }}>Submission</th>
                    <th className="font-normal text-sm sm:p-3 py-2 px-1" style={{ width: "15%" }}>Status</th>
                    <th className="font-normal text-sm sm:p-3 py-2 px-1 text-right" style={{ width: "10%" }}>Created</th>
                </tr>
                </thead>
                <tbody className={"text-gray-700 relative"}>
                <OnLoadViewer loading={admin_submissions.loading} component={"tr"} childComponent={"td"} className={"absolute"}>
                    {
                        admin_submissions.submissions.map((submission) => {
                            return <AdminSubmissionRow key={`submissions_${submission.document_id}`} submission={submission}/>
                        })
                    }
                </OnLoadViewer>
                </tbody>
            </table>
        </OnLoadViewer>
    )

}

export default AdminSubmissionsList;