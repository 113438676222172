import {
    AdminRegistrationsActionTypes,
    AdminRegistrationsState,
    SET_ADMIN_REGISTRATIONS,
    SET_ADMIN_REGISTRATIONS_ANALYTICS,
    UPDATE_ADMIN_REGISTRATIONS
} from "../types/admin-registrations-types";

const initialState: AdminRegistrationsState = new AdminRegistrationsState({
    loading: true,
    registrations: [],
    analytics: null
});

export function adminRegistrationsReducer (state = initialState, action: AdminRegistrationsActionTypes): AdminRegistrationsState {
    switch (action.type) {
        case SET_ADMIN_REGISTRATIONS:
            return new AdminRegistrationsState({
                ...state,
                registrations: action.payload.registrations,
                loading: false
            });
        case SET_ADMIN_REGISTRATIONS_ANALYTICS:
            return new AdminRegistrationsState({
                ...state,
                analytics: action.payload.analytics,
                loading: false
            });
        case UPDATE_ADMIN_REGISTRATIONS:

            let current_data = [...state.registrations];
            let new_data = [...action.payload.registrations];

            new_data.forEach((sub) => {
                let ind = current_data.findIndex((t) => t.document_id === sub.document_id);
                if (ind !== -1) {
                    current_data[ind] = sub;
                } else {
                    current_data.push(sub)
                }
            })

            current_data.sort((a,b) => (b.createdAt.getTime() - a.createdAt.getTime()))

            return new AdminRegistrationsState({
                ...state,
                registrations: current_data,
                loading: false
            });
        default:
            return state;
    }
}

export default adminRegistrationsReducer;