import {Submission} from "../../../models/submission";
import React, {Fragment, useEffect, useState} from "react";
import Fade from "../../transitions/fade";
import PrimaryButton from "../../buttons/button";
import { RadioGroup } from "@headlessui/react";
import {BsCheckCircle, BsFillCheckCircleFill} from "react-icons/bs";
import FormErrorText from "../../forms/form-error-text";

const AbstractTrackForm = ({show=true, submission, onChange, onSubmit}: {show: boolean, submission: Submission, onChange: (newSubmission: Submission) => void, onSubmit: () => void }) => {

    let tracks = ['Education', 'Innovation & Entrepreneurship', 'Tourism & Economy', 'Sustainability, Environment & Risk Management', 'Design & Urban Planning'];
    const [track, setTrack] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        setTrack(submission.track);
    }, [submission.track])

    const onChangeData = (v: string) => {
        setError("");

        let s = new Submission(submission.toJson());
        s.track = v ?? "";
        onChange(s);
    }

    const goNext = () => {
        if (track) {
            setError("");
            onSubmit();
        } else {
            setError("Please select one track.")
        }
    }

    return (
        <Fade
            show={show}
            as={Fragment}
        >
            <div>
                <p className={"pt-6 pb-2 text-2xl"}>
                    Which track would you like to submit to?
                </p>
                <RadioGroup value={track} onChange={onChangeData} className={"max-w-prose space-y-2"}>
                    <RadioGroup.Label className="sr-only">Which track would you like to submit to?</RadioGroup.Label>
                    {
                        tracks.map((t) => {
                            return <RadioGroup.Option
                                key={t}
                                value={t}
                                className={`group relative rounded-lg py-2 cursor-pointer flex focus:outline-none`}
                            >
                                {({ checked }) => (
                                    <>
                                        <div className="flex items-center w-full">
                                            {checked ? (
                                                <div className="flex-shrink-0 text-primary-default">
                                                    <BsFillCheckCircleFill className="w-4 h-4" />
                                                </div>
                                            ) : (
                                                <div className="flex-shrink-0 text-gray-400 group-hover:text-gray-700">
                                                    <BsCheckCircle className="w-4 h-4" />
                                                </div>
                                            )}
                                            <div className="flex items-center ml-4">
                                                <div className="text-base">
                                                    <RadioGroup.Label
                                                        as="p"
                                                        className={`font-medium  ${checked ? 'text-primary-default' : 'text-gray-900'}`}
                                                    >
                                                        {t}
                                                    </RadioGroup.Label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </RadioGroup.Option>
                        })
                    }
                </RadioGroup>
                <div className={"sticky bottom-0 py-4 max-w-md w-full z-40 border-t border-gray-200 mt-10 bg-gray-50"} >
                    <FormErrorText error={error} showError={!!error}/>
                    <PrimaryButton className={"mt-2"} onClick={goNext} style={{ width: "auto" }}>
                        Continue
                    </PrimaryButton>
                </div>
            </div>
        </Fade>
    )

}

export default AbstractTrackForm;