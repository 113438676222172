import {Author, Submission} from "../../../models/submission";
import React, {Fragment, useState} from "react";
import Fade from "../../transitions/fade";
import PrimaryButton from "../../buttons/button";
import FormErrorText from "../../forms/form-error-text";
import {Plus} from "react-iconly";
import AddAuthorForm from "./add-author-form";
import SecondaryButton from "../../buttons/secondary-button";
import ZoomFadeDialog from "../../dialogs/zoom-fade-dialog";
import Heading from "../../../main-pages/attendee-dashboard/components/heading";
import {BsFillCircleFill} from "react-icons/bs";

const AbstractAuthorForm = ({show=true, submission, onChange, onBack, onSubmit}: {show: boolean, submission: Submission, onChange: (newSubmission: Submission) => void, onSubmit: () => void, onBack: ()=>void }) => {

    const [authors, setAuthors] = useState<Author[]>(submission.authors);
    const [adding, setAdding] = useState<number>(-1);
    const [error, setError] = useState<any>("");

    const onChangeData = (v: Author[]) => {
        setError("");

        let s = new Submission(submission.toJson());
        s.authors = v;
        onChange(s);
    }

    const goNext = () => {
        if ((authors?.length ?? 0) > 0) {
            setError("");
            onSubmit();
        } else {
            setError({
                text: "Please add at least one author."
            })
        }
    }

    return (
        <Fade
            show={show}
            as={Fragment}
        >
            <div>
                <p className={"pt-6 pb-4 text-2xl mb-2 max-w-sm"}>
                    Please nominate all the authors contributing to this submission
                </p>
                <div className="max-w-md">
                    {
                        authors?.map((author, index) => {
                            return <div className={"py-4 border-b border-gray-200"} key={author.email}>
                                <div className={"group flex justify-between items-start"}>
                                    <div>
                                        <p className={"font-medium"}>{author.name}</p>
                                        <p className={"font-normal text-sm"}>{author.email}</p>
                                        <div className={"flex items-center mt-2 space-x-2"}>
                                            <p className={"font-normal text-xs text-gray-500"}>{author.country}</p>
                                            <BsFillCircleFill size={4} className={"text-gray-500"}/>
                                            <p className={"font-normal text-xs text-gray-500"}>{author.organization}</p>
                                        </div>
                                    </div>
                                    <div className={"flex space-x-2"}>
                                        <p onClick={() => { setAdding(index) }} role={"button"} className={"h-full px-3 group-hover:text-primary-default hover:opacity-70 transition duration-100 font-medium text-sm"}>Edit</p>
                                        <p onClick={() => {
                                            let tempAuthors = [...authors];
                                            tempAuthors.splice(index, 1);
                                            setAuthors(tempAuthors);
                                            onChangeData(tempAuthors);
                                        }} role={"button"} className={"px-3 group-hover:text-red-700 hover:opacity-70 transition duration-100 font-medium text-sm"}>Remove</p>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                    <div className={"bg-white rounded-md ring-1 ring-gray-200 "}>
                        <div onClick={() => { setAdding(-2) }} role={"button"} className={"py-3 px-3 group flex items-center space-x-4 font-medium"}>
                            {/*@ts-ignore*/}
                            <Plus className={"group-hover:text-primary-default w-5 h-5 group-hover:bg-primary-lighter rounded-md transition overflow-hidden duration-100"}/>
                            <p className={"group-hover:text-primary-default transition duration-100"}>Add Author</p>
                        </div>
                    </div>
                </div>
                <ZoomFadeDialog open={adding !== -1} onClose={() => { setAdding(-1) }}>
                    <div className={"px-6 pb-6"}>
                        <div className={"sticky top-0 z-10"}>
                            <Heading title={"Add Author"} className={"bg-gray-50"}/>
                        </div>
                        <AddAuthorForm
                            author={authors[adding] ? authors[adding] : (authors.length > 0 ? new Author({name: "", email: "", country: authors[0].country, organization: authors[0].organization}) : undefined)}
                            onSave={(a) => {
                                if (a) {
                                    let tempAuthors: Author[] = [];
                                    if (adding === -2)
                                        tempAuthors = [a,...authors]
                                    else {
                                        tempAuthors = [...authors];
                                        tempAuthors[adding] = a;
                                    }

                                    setAuthors(tempAuthors);
                                    onChangeData(tempAuthors);
                                }
                                setAdding(-1) ;
                            }}
                        />
                    </div>
                </ZoomFadeDialog>
                <div className={"sticky bottom-0 py-4 max-w-md w-full z-30 border-t border-gray-200 mt-10 bg-gray-50"} >
                    <FormErrorText error={error} showError={!!error}/>
                    <div className={"flex space-x-6"}>
                        <SecondaryButton className={"mt-2 text-gray-600"} onClick={onBack} style={{ width: "auto" }}>
                            Back
                        </SecondaryButton>
                        <PrimaryButton className={"mt-2"} onClick={goNext} style={{ width: "auto" }}>
                            Continue
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </Fade>
    )

}

export default AbstractAuthorForm;