import React from "react"
import Card from "../../../../components/cards/card";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/index.reducers";
import moment from "moment";
import {Document, TimeSquare} from "react-iconly";
import { Link } from "react-router-dom";
import {RiArrowRightLine} from "react-icons/all";

const SIX_MONTHS = [5,4,3,2,1,0].map((index) => {
    let now = new Date();
    now.setMonth(now.getMonth()-index)
    let param = `${now.getFullYear()}-${(now.getMonth() + 1).toFixed(0).padStart(2,"0")}`
    let caption = moment(now).format("MMM");
    let full_title = moment(now).format("MMMM YYYY");
    return {
        title: caption,
        full_title: full_title,
        param: param
    }
});

const AdminSubmissionsOverview = () => {

    const {analytics} = useSelector((state: RootState) => state.adminSubmissions);

    return (
        <div className={"grid grid-cols-4 my-6 gap-6"}>
             <Card className={'px-5 py-4'} style={{ overflow: "unset" }}>
                 <p className={"text-gray-800 font-semibold text-base"}>
                     Submissions
                 </p>
                 <div className={"my-2 rounded-lg bg-gray-100 p-4 pb-6 relative flex justify-between"}>
                     {SIX_MONTHS.map((v) => {

                         const bar_data = analytics?.bar_data("SIX_MONTHS", v.param)

                         return (
                             <div className={"flex w-full flex-col items-center group"} key={`bar_${v.param}`} style={{ maxWidth: "16px" }}>
                                 <div className={"w-full h-36 rounded-md bg-gray-100 relative hover:bg-gray-200 transition duration-200"}>
                                    <div className={"w-full h-36 absolute bottom-0 rounded-t-md bg-primary-default"} style={{ transition: "200ms", height: `${bar_data?.size ?? "4px"}`, opacity: (bar_data?.value ?? 0) === 0 ? "0.4": "1" }}/>
                                 </div>
                                 <span className={"absolute bottom-1.5 text-xs font-medium opacity-60 mt-1"}>
                                     {v.title}
                                 </span>
                                 <div className={"pointer-events-none z-50 opacity-0 group-hover:opacity-100 transition duration-200 px-2.5 py-1.5 ring-1 ring-black ring-opacity-5 shadow-sm rounded-md absolute -top-5 text-sm flex flex-col flex-shrink-0"} style={{ background: "rgba(255,255,255,0.7)", backdropFilter: "blur(60px) saturate(210%)" }}>
                                     <p className={"flex"}><span>{bar_data?.value ?? 0}</span><span className={"ml-2"}> Submissions</span></p>
                                     <p className={"opacity-60 text-xs"}>{v.full_title}</p>
                                 </div>
                             </div>
                         )
                     })}
                     <div className={"h-px bg-gray-300 absolute left-0 right-0 bottom-6"}/>
                 </div>
                 <Link to={"/admin/submissions/browse"} className={"flex items-center py-2 hover:bg-gray-100 rounded-lg px-3"}>
                     <Document set={"bulk"} size={16} className={"mr-3  text-green-700"}/>
                     <p className={"text-sm opacity-80"}>
                         Total Submissions
                     </p>
                     <RiArrowRightLine className={"ml-2 opacity-50"}/>
                     <p className={"ml-auto text-sm font-medium"}>
                         {analytics?.total_submissions({filter: []}) ?? 0}
                     </p>
                 </Link>
                 <div className={"h-px w-full bg-gray-200 my-1"}/>
                 <Link to={"/admin/submissions/browse?status=review"} className={"flex items-center py-2 hover:bg-gray-100 rounded-lg px-3"}>
                     <TimeSquare set={"bulk"} size={16} className={"mr-3  text-yellow-600"}/>
                     <p className={"text-sm opacity-80"}>
                         Needs Review
                     </p>
                     <RiArrowRightLine className={"ml-2 opacity-50"}/>
                     <p className={"ml-auto text-sm font-medium"}>
                         {analytics?.total_submissions({filter: ["abstract-review", "paper-review"]}) ?? 0}
                     </p>
                 </Link>
             </Card>
            <Card className={"px-5 py-4"}>
                <p className={"text-gray-800 font-semibold text-base"}>
                    Authors By Country
                </p>
                <div className={"my-2 space-y-3 max-h-64 overflow-y-auto"} style={{  }}>
                    {analytics?.countries.map((country, index) => {

                        let bar_data = analytics?.bar_data("COUNTRIES", country._id)

                        return (
                            <div className={"text-sm flex justify-between border-b-4 pb-1 relative"} key={`country_${index.toString()}`}>
                                <p>
                                    {country._id}
                                </p>
                                <p>
                                    {country.count}
                                </p>
                                <div className={"absolute -bottom-1 h-1 bg-primary-default rounded-sm"} style={{ width: bar_data?.size ?? "0%" }}/>
                            </div>
                        )
                    })}
                </div>
            </Card>
        </div>
    )

}

export default AdminSubmissionsOverview;