import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/index.reducers";
import {useEffect} from "react";
import {getDocs, limit, orderBy, query, where} from "firebase/firestore";
import {registrations} from "../../../../utils/firebase.utils";
import {Dispatch} from "redux";
import {
    AdminRegistrationsActionTypes,
    SET_ADMIN_REGISTRATIONS
} from "../../../../redux/types/admin-registrations-types";
import {Registration} from "../../../../models/registration";

const ReduxRegistrations = () => {

    const conference = useSelector((state: RootState) => state.conference);
    const user = useSelector((state: RootState) => state.userData);

    const dispatch = useDispatch<Dispatch<AdminRegistrationsActionTypes>>();


    useEffect(() => {

        if (conference.id) {
            let ref = query(
                registrations,
                where('conference_id', '==', conference.id),
                where('conference_version_id', '==', conference.active_conference),
                limit(40),
                orderBy('createdAt', 'desc')
            );

            // TODO Registration Analytics
            // let a_ref = doc(submissions_analytics, `${conference.id}-${conference.active_conference}`);

            // getDoc(a_ref)
            //     .then((snapshot) => {
            //         if (!snapshot.exists()) {
            //             dispatch({ type: SET_ADMIN_SUBMISSIONS_ANALYTICS, payload: {analytics: null} });
            //         } else {
            //             dispatch({ type: SET_ADMIN_SUBMISSIONS_ANALYTICS, payload: {analytics: new SubmissionAnalytics(snapshot.data())} });
            //         }
            //     })
            //     .catch()

            getDocs(ref)
                .then((snapshot) => {
                    if (snapshot.empty) {
                        dispatch({ type: SET_ADMIN_REGISTRATIONS, payload: {registrations: []} });
                    } else {
                        let s:Registration[] = [];
                        snapshot.forEach((doc) => {
                            let data = doc.data();
                            data.document_id = doc.id;
                            s.push(new Registration(data))
                        });
                        dispatch({ type: SET_ADMIN_REGISTRATIONS, payload: {registrations: s} });
                    }
                })
                .catch()
        }

    }, [conference.active_conference, conference.id, user.userData?.uid, dispatch])

    return (
        <></>
    )

}

export default ReduxRegistrations;