import React, {useState} from "react";
import {Submission} from "../../../models/submission";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import AbstractTrackForm from "./abstract-track-form";
import AbstractFormatForm from "./abstract-format-form";
import AbstractMainForm from "./abstract-main-form";
import AbstractAuthorForm from "./abstract-author-form";
import AbstractReview from "./abstract-review";
import { nodeValue } from "../../../utils/methods";
import axios from "axios";
import {auth} from "../../../utils/firebase.utils";
import { BASE_API } from "../../../utils/constants";
import {useHistory} from "react-router-dom";

const AbstractForm = () => {

    const conference = useSelector((state: RootState) => state.conference);
    const user = useSelector((state: RootState) => state.userData);
    const history = useHistory();

    const [step, setStep] = useState<number>(0);
    const [submission, setSubmission] = useState<Submission>(new Submission({
        conference_id: conference.id,
        conference_version_id: conference.active_conference,
        uid: user.userData?.uid
    }))

    const isValid = (tab: string) => {
        switch(tab) {
            case "Track":
                return !!submission.track;
            case "Format":
                return !!submission.format;
            case "Title & Abstract":
                return submission.title!=="" && nodeValue(submission.abstract).length > 0
            case "Authors":
                return submission.authors.length > 0
            case "All":
                return !!submission.track && !!submission.format && submission.title!=="" && nodeValue(submission.abstract).length > 0 && submission.authors.length > 0
            default:
                return false;
        }
    }

    const goToStep = (nextStep: number) => {
        if (nextStep > step) {
            setStep(nextStep);
        } else {
            setStep((prev) => prev-0.5);
            setTimeout(() => {
                setStep(nextStep)
            }, 80)
        }
    }

    const postSubmission = () => {
        return new Promise(async (resolve, reject) => {
            let token = await auth.currentUser?.getIdToken();
            axios.post(`${BASE_API}/submissions`, { ...submission.toJson() }, { headers: { authorization: token ?? "" } }).then(async (res) => {
                if (res.status === 201) {
                    resolve(null);
                    history.push("/submissions")
                }
                else
                    reject();
            });
        })
    }

    return (
        <>
            <div className={"grid grid-cols-4"}>
                <div className={`sticky py-8 top-16 bg-gray-50 col-span-1 h-0`}>
                    <div>
                            {["Track", "Format", "Authors", "Title & Abstract", "Review Submission"].map((tab, index) => {
                                return <div className={`flex items-center relative cursor-pointer py-1`} key={tab} onClick={() => { goToStep(index); }}>
                                    <div className={`absolute h-full transition duration-75 ${step>=index ? 'bg-primary-default' : 'bg-gray-200'}`} style={{ width: "2px" }}/>
                                    <p
                                        className={`
                                    select-none
                                    ml-3
                                    font-medium text-sm
                                    opacity-90 transition duration-300
                                    ${step===index ? 'text-primary-default' : ((step > index) ? (isValid(tab) ? "text-gray-900" : "text-red-700") : "text-gray-400")}  
                                    `}
                                    >
                                        {tab}
                                    </p>
                                </div>
                            })}
                        </div>
                </div>
                <div className={"col-span-3"}>
                    <AbstractTrackForm show={step===0} submission={submission} onSubmit={() => { goToStep(1) }} onChange={(newSubmission) => {setSubmission(newSubmission)}}/>
                    <AbstractFormatForm show={step===1} submission={submission} onBack={() => { goToStep(0) }} onSubmit={() => { goToStep(2) }} onChange={(newSubmission) => {setSubmission(newSubmission)}}/>
                    <AbstractAuthorForm show={step===2} submission={submission} onBack={() => { goToStep(1) }}  onSubmit={() => { goToStep(3) }} onChange={(newSubmission) => {setSubmission(newSubmission)}}/>
                    <AbstractMainForm show={step===3} submission={submission} onBack={() => { goToStep(2) }}  onSubmit={() => { goToStep(4) }} onChange={(newSubmission) => {setSubmission(newSubmission)}}/>
                    <AbstractReview isValid={isValid} show={step===4}  submission={submission} onBack={() => { goToStep(3) }} onSubmit={postSubmission}/>
                </div>
            </div>
        </>
    )

}

export default AbstractForm;