import React, {Fragment, useState} from "react";
import Fade from "../../transitions/fade";
import PrimaryButton from "../../buttons/button";
import FormErrorText from "../../forms/form-error-text";
import {Registration, RegistrationTicket} from "../../../models/registration";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import {formatCurrency} from "../../../utils/methods";
import {FiMinus, FiPlus} from "react-icons/fi";
import {Ticket} from "../../../models/conference-settings";
import Countries from "../../forms/countries.json";
import SingleSelect from "../../forms/form-single-select";

const RegistrationTicketSelect = ({show=true, registration, onChange, onSubmit}: {show: boolean, registration: Registration, onChange: (newRegistration: Registration) => void, onSubmit: () => void }) => {

    const conference = useSelector((state: RootState) => state.conference);
    const [error, setError] = useState("");

    const addTicket = (ticket: Ticket) => {
        let current_tickets: RegistrationTicket[] = [...registration.tickets];

        let index = current_tickets.findIndex((t) => t.id === ticket.id);
        current_tickets.splice(index === -1 ? (current_tickets.length) : index, 0, new RegistrationTicket({
            id: ticket.id,
            title: ticket.title,
            description: ticket.description,
            currency: conference.currency,
            price: ticket.price,
            ticket_type: ticket.ticket_type
        }))
        onChangeData(current_tickets)
    }

    const removeTicket = (ticket: Ticket) => {
        let current_tickets: RegistrationTicket[] = [...registration.tickets];

        let index = current_tickets.findIndex((t) => t.id === ticket.id);
        if (index !== -1) {
            current_tickets.splice(index, 1)
            onChangeData(current_tickets)
        }
    }

    const onChangeData = (new_tickets: RegistrationTicket[]) => {
        setError("");

        let s = new Registration(registration.toJson());
        s.tickets = new_tickets;
        onChange(s);
    }

    const onChangeCountry = (country: string) => {
        setError("");

        let s = new Registration(registration.toJson());
        s.country = country;
        s.tickets = [];
        onChange(s);
    }

    const goNext = () => {
        if (registration.tickets.length > 0) {
            setError("");

            let s = new Registration(registration.toJson());
            onChange(s);
            onSubmit();
        } else {
            setError("Please add at-least one ticket.")
        }
    }

    const ticket_data = registration.country ? (conference.ticket_data.find((t) => t.country === registration.country) ?? conference.ticket_data.find(t => t.default)) : undefined;

    return (
        <Fade
            show={show}
            as={Fragment}
        >
            <div>
                <p className={"max-w-lg pt-6 pb-2 text-xl  font-medium border-b border-gray-200"}>
                    Select Tickets:
                </p>
                <div className={"max-w-lg mt-4"}>
                    <SingleSelect name={"registration_country"} onChange={(v) => { onChangeCountry(v) }} placeholder={"Select Your Country"} value={registration.country} label={"Country"} options={Countries} />
                    {ticket_data &&(
                        <div className={"shadow-sm border rounded-xl border-gray-200 bg-white divide-y divide-gray-200 overflow-hidden mt-6"}>
                            {
                                ticket_data.tickets.map((ticket, ind) => {
                                    return (
                                        <div key={`${ticket.id}_${ind}`} className={"px-4 py-3 hover:bg-primary-lightest flex items-center relative"}>
                                            <div>
                                                <p className={"text-base font-medium"}>
                                                    {ticket.title}
                                                </p>
                                                <p className={"opacity-80 text-sm"}>
                                                    {formatCurrency(ticket.price, ticket_data.currency)}
                                                </p>
                                            </div>
                                            <div className={"flex items-center py-1 px-1 ml-auto rounded-full bg-gray-50 border border-gray-200"}>
                                                <button onClick={() => { removeTicket(ticket) }} className={"outline-none ring-1 ring-transparent focus:ring-primary-default rounded-full p-1 bg-primary-lighter text-primary-default"}>
                                                    <FiMinus/>
                                                </button>
                                                <span className={"w-8 flex justify-center"}>
                                                    {registration.tickets.filter(t => t.id === ticket.id).length}
                                                </span>
                                                <button onClick={() => { addTicket(ticket) }} className={"outline-none ring-1 ring-transparent focus:ring-primary-default rounded-full p-1 bg-primary-lighter text-primary-default"}>
                                                    <FiPlus/>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )}
                </div>
                <div className={"sticky bottom-0 py-4 max-w-lg w-full z-40 border-t border-gray-200 mt-10 bg-gray-50"} >
                    <FormErrorText error={error} showError={!!error}/>
                    <PrimaryButton className={"mt-2"} onClick={goNext} style={{ width: "auto" }}>
                        Continue
                    </PrimaryButton>
                </div>
            </div>
        </Fade>
    )

}

export default RegistrationTicketSelect;