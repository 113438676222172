import moment from "moment";
import React from "react";
import {Submission} from "../../../models/submission";
import {useHistory} from "react-router-dom";
import {authorValue} from "../../../utils/methods";

const AdminSubmissionRow = ({submission}: {submission:Submission}) => {

    const history = useHistory();

    return (
        <tr key={submission.document_id} className={`border-b border-gray-200 group hover:shadow-sm rounded-xl cursor-pointer transition duration-300`} onClick={() => { history.push("/admin/submissions/" + submission.document_id) }}>
            <td className="sm:p-3 py-2 px-1 group-hover:bg-white rounded-l-xl  transition duration-300">
                <div className={"flex items-center space-x-3"}>
                    <div className={"text-xs opacity-70 w-24 flex-shrink-0"}>
                        #{submission.id}
                    </div>
                    <div className={"text-sm flex-shrink-0"}>
                        {authorValue(submission.authors)}
                    </div>
                    <div className={"flex space-x-2 text-black text-sm line-clamp-1 text-gray-400"}>
                        <span>•</span> <span>{submission.title}</span>
                    </div>
                </div>
            </td>
            <td className="sm:p-3 py-2 px-1 group-hover:bg-white text-sm transition duration-300">
                {
                    (() => {
                        switch (submission.status) {
                            case "draft":
                                return <div className="flex items-center text-yellow-600">
                                    Draft
                                </div>
                            case "abstract-review":
                            case "paper-review":
                                return <div className="flex items-center text-yellow-600">
                                    Review Pending
                                </div>
                            case "re-submit-paper":
                            case "re-submit-abstract":
                                return <div className="flex items-center text-red-600">
                                    Resubmit
                                </div>
                            case "submit-paper":
                                return <div className="flex items-center text-blue-800">
                                    Abstract Accepted
                                </div>
                            case "final":
                                return <div className="flex items-center text-green-800">
                                    Paper Accepted
                                </div>
                        }
                    })()
                }
            </td>
            <td className="sm:p-3 py-2 px-1 group-hover:bg-white rounded-r-xl transition duration-300">
                <div className={"flex items-center justify-end"}>
                    <p className={"text-sm"}>
                        {moment(submission?.createdAt).format(`MMM DD${new Date().getFullYear() === submission?.createdAt.getFullYear() ? "" : ", YY"}`)}
                    </p>
                </div>
            </td>
        </tr>
    )
}

export default AdminSubmissionRow;