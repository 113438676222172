import React, {useEffect} from 'react'
import {Redirect, Route, RouteProps, Switch, useHistory} from 'react-router-dom';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import SignIn from "../../main-pages/sign-in/sign-in";
import * as queryString from "querystring";
import ForgotPassword from "../../main-pages/forgot-password/forgot-password";
import AttendeeWrapper from "../../main-pages/attendee-dashboard/attendee-wrapper";
import Action from "../../main-pages/action/action";
import SignUp from "../../main-pages/sign-up/sign-up";
import AdminWrapper from "../../main-pages/admin-dashboard/admin-wrapper";

const OpenRoute = ({component, ...props}: RouteProps) => {
    return <Route {...props} component={component} />
};

const NoAuthRoute = ({component, redirect = true, ...props}: RouteProps & { redirect?: boolean }) => {

    const { isAuthenticated } = useSelector((state: RootState) => state.userData);

    let redirectUrl = queryString.parse(props.location?.search ?? "")?.redirect ?? null;

    return !isAuthenticated || !redirect ? (
        <Route {...props} component={component} />
    ) : (
        <Redirect to={redirectUrl!=null ? redirectUrl.toString() : "/"}/>
    );
};

const PrivateRoute = ({ level = 1, component, ...props }: RouteProps & {level?: number}) => {

    const {isAuthenticated, role} = useSelector((state: RootState) => state.userData);

    let allowed:boolean = (() => {
        switch (level) {
            case 0:
                return true;
            case 1:
                return role==="admin";
            default:
                return false;
        }
    })();

    return (isAuthenticated) ?
        (
            allowed ?
                <Route {...props} component={component} />
                :
                <Redirect to={`/not-authorised`}/>
        )
        :
        <Redirect to={`/sign-in?redirect=${props.location?.pathname}${props.location?.search}`}/>
};

const AppRoutes = ()  => {

    const history = useHistory();

    useEffect(() => {
        let observer = history.listen(() => {
            if (history.action === "PUSH") {
                if (!history.location.pathname.includes("/admin"))
                    window.scroll(0,0);
            }
        })

        return () => { observer(); }
    }, [history])

    return (
        <main>
            <Switch>

                {/*Authentication Routes - Login & Forgot Password*/}
                <NoAuthRoute exact path='/sign-in' component={SignIn} />
                <NoAuthRoute exact redirect={false} path='/sign-up' component={SignUp} />
                <NoAuthRoute exact path='/forgot-password' component={ForgotPassword} />

                {/*Action Routes - Reset Password & Link Sign In*/}
                <OpenRoute exact path='/action' component={Action} />

                {/*All Attendee Routes*/}
                <PrivateRoute path={'/admin'} level={1} component={AdminWrapper} />
                <PrivateRoute path={'/*'} level={0} component={AttendeeWrapper} />

                {/*<OpenRoute>*/}
                {/*    <div>404</div>*/}
                {/*</OpenRoute>*/}
            </Switch>
        </main>
    )
};

export default AppRoutes;

