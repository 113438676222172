import React from "react";
import {Submission} from "../../../models/submission";
import {Image, Paper} from "react-iconly";
import moment from "moment";
import {secondary_button_class} from "../../buttons/secondary-button";
import DocumentViewer from "../../document-viewer/document-viewer";
import PrimaryButton from "../../buttons/button";
import {RiDownloadLine} from "react-icons/all";

const SubmissionDocDataView = ({submission}: {submission?: Submission}) => {

    return (
        <>
            <div className={"w-full font-semibold text-sm text-gray-500 text-center"}>
                <div className={"flex items-start "}>
                    {submission?.submission_data_type === "docx" &&
                    <Paper className={"w-8 h-8 flex-shrink-0"} set={"bold"} style={{ color: "#005DA6" }}/>
                    }
                    {submission?.submission_data_type === "image" &&
                    <Image className={"w-8 h-8 flex-shrink-0 text-primary-default"} set={"bold"} />
                    }
                    <div>
                        <p className={"text-gray-900 w-full text-left ml-2 font-normal text-sm"}>
                            Paper
                        </p>
                        <p className={"w-full text-left ml-2 font-normal text-xs"}>
                            Uploaded on {moment(submission.upload_date).format("MMM DD, YYYY")}
                        </p>
                    </div>
                    <div className={"flex items-start ml-auto space-x-3"}>
                        <a className={`${secondary_button_class} flex items-center space-x-2`} rel={"noreferrer"} target={"_blank"} href={submission.submission_data_link}>
                            <RiDownloadLine size={16}/><span>Download</span>
                        </a>
                        <DocumentViewer title={submission.id} url={submission.submission_data_link} type={submission.submission_data_type}>
                            <PrimaryButton>
                                View
                            </PrimaryButton>
                        </DocumentViewer>
                    </div>
                </div>
            </div>
        </>
    )

}

export default SubmissionDocDataView;