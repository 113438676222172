import React, {useEffect, useState} from "react";
import FormInput from "../../components/forms/form-input";
import PrimaryButton from "../../components/buttons/button";
import FormWrapper from "../../components/forms/form-wrapper";
import FormErrorText from "../../components/forms/form-error-text";
import {auth, users} from "../../utils/firebase.utils";
import {createUserWithEmailAndPassword} from "firebase/auth";
import InlineLink from "../../components/links/inline-link";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import { Transition } from "@headlessui/react";
import {Redirect, useLocation} from "react-router-dom";
import queryString from "querystring";
import {UserData} from "../../models/user-data";
import {doc, setDoc } from "firebase/firestore";

const SignUp = () => {

    const location = useLocation();
    const conference = useSelector((state: RootState) => state.conference);
    const { loading, isAuthenticated } = useSelector((state: RootState) => state.userData);

    const [authenticated, setAuthenticated] = useState(false);
    const [passwordData, setPasswordData] = useState({
        length: 0,
        isNumbers: false,
        isSymbols: false
    });

    useEffect(() => {
        if (isAuthenticated)
            setAuthenticated(true);
        // eslint-disable-next-line
    }, []);

    // Handle Sign Up
    const signUp = (values: Record<string, string | boolean>) => {
        return new Promise( async (resolve, reject) => {
            createUserWithEmailAndPassword(auth, values['email'].toString(), values['newPassword'].toString())
                .then(async (result) => {
                    let user = result.user;
                    if (user) {
                        let userData: UserData = new UserData({
                            uid: user.uid, email: user.email,
                            firstName: values.firstName,
                            lastName: values.lastName
                        });
                        let d = doc(users, user.uid);
                        await setDoc(d, userData.toJson());
                        setAuthenticated(true);
                    } else {
                        reject("Something went wrong on our side.")
                    }
                })
                .catch((err) => {
                    switch (err.code) {
                        case "auth/email-already-in-use":
                            return reject({ email: "Account already exists." });
                        case "auth/weak-password":
                            return reject({ password: "Password must contain 6+ characters, a symbol & a number." });
                        case "auth/invalid-email":
                            return reject({ email: "Please enter a valid Email Address." });
                        default:
                            return reject("Something went wrong on our side.");
                    }
                });
        });
    };

    return (
        <div className="min-h-screen flex justify-center bg-white items-start sm:items-center">
            {authenticated &&
                <Redirect to={queryString.parse(location.search).redirect ? queryString.parse(location.search).redirect as string : "/"} />
            }
            <div className="max-w-md w-full m-6 mt-10 sm:m-20">
                <Transition
                    appear={true}
                    show={!loading}
                    enter="transition ease-in-out delay-150 duration-300 transform"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100  translate-y-0"
                    className="max-w-md w-full space-y-8"
                >
                    <div>
                        <div className={"flex justify-between align-start"}>
                            <h2 className="text-3xl font-bold text-gray-900">Sign up to get<br/> started</h2>
                            {(!conference.loading && conference.logo) &&
                            <img
                                className="mt-1 h-5"
                                src={conference.logo}
                                alt="Conference Logo"
                            />
                            }
                        </div>
                        <div className="text-sm mt-2">
                            Already Registered? {" "}
                            <InlineLink to="/sign-in">
                                Sign In
                            </InlineLink>
                        </div>
                    </div>
                    <FormWrapper onSubmit={signUp} className={"space-y-6"} onValueChange={(values) => {
                        let password = values["newPassword"];
                        console.log(password);
                        let data = {
                            length: password?.toString().length,
                            isNumbers: !!password?.toString()?.match(RegExp(/\d/)),
                            isSymbols: !!password?.toString()?.match(RegExp(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/))
                        }

                        setPasswordData(data);
                    }}>
                        <div className={"space-y-2"}>
                            <div className="flex w-full space-x-3">
                                <FormInput
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    autoComplete="given-name"
                                    placeholder="First Name"
                                    label="First Name"
                                    required
                                    rootClassName={"w-full"}
                                />
                                <FormInput
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    autoComplete="family-name"
                                    required
                                    label="Last Name"
                                    placeholder="Last Name"
                                    rootClassName={"w-full"}
                                />
                            </div>
                            <FormInput
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                placeholder="Email address"
                                label="Email address"
                                required
                            />
                            <FormInput
                                id="new-password"
                                name="newPassword"
                                type="password"
                                autoComplete="new-password"
                                required
                                label="New Password"
                                placeholder="New Password"
                            />
                            <div className={"flex space-x-2 space-y-2z"}>
                                <div className={`duration-200 transition text-xs tracking-tight px-2 py-1 rounded-lg ${passwordData.length >= 6 ? "text-green-700 bg-green-50" : (passwordData.length > 0 ? "text-red-700 bg-red-50" : "text-gray-500 bg-gray-100")}`}>
                                    min. 6 letters
                                </div>
                                <div className={`duration-200 transition text-xs tracking-tight px-2 py-1 rounded-lg bg-gray-100 ${passwordData.isNumbers ? "text-green-700 bg-green-50" : (passwordData.length > 0 ? "text-red-700 bg-red-50" : "text-gray-500 bg-gray-100")}`}>
                                    1 number required
                                </div>
                                <div className={`duration-200 transition text-xs tracking-tight px-2 py-1 rounded-lg bg-gray-100 ${passwordData.isSymbols ? "text-green-700 bg-green-50" : (passwordData.length > 0 ? "text-red-700 bg-red-50" : "text-gray-500 bg-gray-100")}`}>
                                    1 symbol required
                                </div>
                            </div>
                            <FormErrorText/>
                        </div>
                        <div>
                            <PrimaryButton type={"submit"}>
                                Sign Up
                            </PrimaryButton>
                        </div>
                        <div className="flex items-center text-sm">
                            <p>
                                By clicking Sign Up, you agree to our {" "}
                                <InlineLink to={"/terms"}>
                                    Terms & Conditions
                                </InlineLink>
                                {" "}and{" "}
                                <InlineLink to={"/privacy"}>
                                    Privacy Policy
                                </InlineLink>
                            </p>
                        </div>
                    </FormWrapper>
                </Transition>
            </div>
            <div className={"w-full min-h-screen bg-gray-100 hidden relative sm:block"}>
                <Transition
                    show={!conference.loading}
                    enter="transition-opacity duration-150"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className={"absolute left-0 top-0 w-full h-full"} style={{ background: `url("${conference.background_auth}") left top / cover` }}/>
                </Transition>
            </div>
        </div>
    )
}

export default SignUp;