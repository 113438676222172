import React, { useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import Heading from "../components/heading";
import {onSnapshot, orderBy, query, where} from "firebase/firestore";
import {registrations} from "../../../utils/firebase.utils";
import PrimaryButton from "../../../components/buttons/button";
import {Link} from "react-router-dom";
import OnLoadViewer from "../../../components/on-load-viewer/on-load-viewer";
import moment from "moment";
import {Registration} from "../../../models/registration";
import {formatCurrency} from "../../../utils/methods";

const MyRegistrations = () => {

    const conference = useSelector((state: RootState) => state.conference);
    const user = useSelector((state: RootState) => state.userData);

    const [loading, setLoading] = useState(true);
    const [myRegistrations, setMyRegistrations] = useState<Registration[]>([]);


    useEffect(() => {

        if (conference.id) {
            let ref = query(
                registrations,
                where('uid', '==', user.userData?.uid),
                where('conference_id', '==', conference.id),
                where('conference_version_id', '==', conference.active_conference),
                orderBy('createdAt', 'desc')
            );

            let observer = onSnapshot(ref, (snapshot) => {
                if (snapshot.empty) {
                    setMyRegistrations([])
                } else {
                    let s:Registration[] = [];
                    snapshot.forEach((doc) => {
                        let data = doc.data();
                        data.document_id = doc.id;
                        s.push(new Registration(data))
                    });
                    setMyRegistrations(s);
                }
                setLoading(false);
            })

            return () => observer();
        }

    }, [conference.active_conference, conference.id, user.userData?.uid])

    return (
        <>
            <Heading title={"Registrations"} subtitle={<p>You can now register to attend this conference. The last date to register is <span className={"text-primary-default"}>{moment(conference.last_registration_deadline).format("MMM DD, YYYY")}</span></p>}
                     button={
                         <Link to={"/registrations/new"}>
                             <PrimaryButton className={"mt-5"} style={{ width: "auto" }}>
                                 Register Now
                             </PrimaryButton>
                         </Link>
                     }
            />
            <OnLoadViewer loading={conference.loading}>
                {myRegistrations.length > 0 &&
                    <table className="table-fixed w-full text-left">
                        <thead className={`bg-gray-50 h-12 px-3 py-3 text-gray-400 sticky z-20`} style={{ top: `64px`, boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>
                        <tr>
                            <th className="font-normal sm:p-3 py-2 px-1" style={{ width: "65%" }}>Ticket Type</th>
                            <th className="font-normal sm:p-3 py-2 px-1 sm:text-gray-400 text-white" style={{ width: "20%" }}>Status</th>
                        </tr>
                        </thead>
                        <tbody className={"text-gray-600 relative"}>
                        <OnLoadViewer loading={loading} component={"tr"} childComponent={"td"} className={"absolute"}>
                            {
                                myRegistrations.map((registration) => {
                                    return<tr key={registration.document_id} className={"border-b border-gray-200 hover:bg-gray-100 cursor-pointer"}>
                                        <td className="sm:p-3 py-2 px-1">
                                            <div className=" mr-4">
                                                {registration.tickets.map((rr) => {
                                                    return (
                                                        <div className={""}>
                                                            {rr.registrant_details.name} ({rr.title})
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="flex items-center">
                                                {formatCurrency(registration.total)}
                                            </div>
                                        </td>
                                        <td className="sm:p-3 py-2 px-1">
                                            <div className="flex items-center text-green-800">
                                                Paid
                                            </div>
                                        </td>
                                    </tr>
                                })
                            }
                        </OnLoadViewer>
                        </tbody>
                    </table>
                }
            </OnLoadViewer>
        </>
    )
}

export default MyRegistrations;