import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import {EditorContent, useEditor} from "@tiptap/react";
import {StarterKit} from "@tiptap/starter-kit";
import {Mention} from "@tiptap/extension-mention";
import suggestions from "../../editor/suggestions";
import Heading from "../../../main-pages/attendee-dashboard/components/heading";
import ZoomFadeDialog from "../../dialogs/zoom-fade-dialog";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import SingleSelect from "../../forms/form-single-select";
import { RiMailLine } from "react-icons/all";
import {Underline} from "@tiptap/extension-underline";
import {TextAlign} from "@tiptap/extension-text-align";
import FormWrapper from "../../forms/form-wrapper";
import PrimaryButton from "../../buttons/button";
import SecondaryButton from "../../buttons/secondary-button";
import axios from "axios";
import {BASE_API} from "../../../utils/constants";
import EditorBar from "../../editor/editor-bar";
import {Submission} from "../../../models/submission";
import FormInput from "../../forms/form-input";
import {AdminSubmissionsActionTypes, UPDATE_ADMIN_SUBMISSIONS} from "../../../redux/types/admin-submissions-types";
import {Dispatch} from "redux";

const AdminSubmissionDecideAbstract = ({closeTo}: {closeTo: string}) => {

    const conference = useSelector((state: RootState) => state.conference);
    const user_data = useSelector((state: RootState) => state.userData);
    const history = useHistory();
    const dispatch = useDispatch<Dispatch<AdminSubmissionsActionTypes>>()

    const {id, action}: {id: string, action: string} = useParams();

    const [step, setStep] = useState(0);
    const [decision, setDecision] = useState("");
    const [name, setName] = useState(user_data.userData.name);

    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            Mention.configure({
                HTMLAttributes: {
                    class: 'custom_variable',
                },
                renderLabel({ node }) {
                    return `{{${node.attrs.id}}}`
                },
                suggestion: suggestions
            }),
        ],
        content: ""
    });

    const close_dialog = () => {
        if (history.length > 1) { history.goBack() } else { history.push(closeTo) }
    }

    useEffect(() => {
        if (action === "decide-abstract" || action === "decide-paper") {
            axios.post(`${BASE_API}/submissions/heat`, {  }, {}).then();
        }
    }, [action])

    const updateEditor = (values:Record<string, any>) => {
        switch (action) {
            case "decide-abstract":
                switch (values.decision) {
                    case "Accept":
                        editor.commands.setContent(conference.email_template_submission_abstract_accepted);
                        break;
                    case "Revise & Resubmit":
                        editor.commands.setContent(conference.email_template_submission_abstract_revise);
                        break;
                    case "Reject":
                        editor.commands.setContent(conference.email_template_submission_abstract_reject);
                        break;
                }
                break;
            case "decide-paper":
                switch (values.decision) {
                    case "Accept":
                        editor.commands.setContent(conference.email_template_submission_abstract_accepted);
                        break;
                    case "Revise & Resubmit":
                        editor.commands.setContent(conference.email_template_submission_abstract_revise);
                        break;
                    case "Reject":
                        editor.commands.setContent(conference.email_template_submission_abstract_reject);
                        break;
                }
        }
    }

    const getOptions = () => {
        switch (action) {
            case "decide-abstract":
                return ["Accept", "Revise & Resubmit", "Reject"];
            case "decide-paper":
                return ["Accept & Finalise Submission", "Revise & Resubmit", "Reject"]
            default:
                return [];
        }
    }

    const getSubject = () => {
        switch (action) {
            case "decide-abstract":
                switch (decision) {
                    case "Accept":
                        return "Your abstract has been accepted"
                    case "Revise & Resubmit":
                        return "Revise & resubmit your abstract"
                    case "Reject":
                        return "We regret to inform you that we are unable to accept your submission"
                }
                break;
            case "decide-paper":
                switch (decision) {
                    case "Accept & Finalise Submission ":
                        return "Your abstract has been accepted"
                    case "Revise & Resubmit":
                        return "Revise & resubmit your"
                    case "Reject":
                        return "We regret to inform you that we are unable to accept your submission"
                }
                break;
        }
    }

    return (
        <ZoomFadeDialog open={action === "decide-abstract" || action === "decide-paper"} onClose={close_dialog}>
            <div className={"px-6 pb-6 overflow-y-overlay"} style={{ maxHeight: "calc(100vh - 48px)", minHeight: "500px" }}>
                <div className={"sticky top-0 z-10"}>
                    <Heading title={"Publish Decision"} className={"bg-gray-50"}/>
                </div>
                <div className={"h-4"}/>
                <>
                    {step === 0 && (
                        <FormWrapper className={"space-y-6"}
                                     initialValues={{ decision: decision, chair_name: name ?? user_data.userData.name }}
                                     onSubmit={async (values) => {
                                         setDecision(values.decision ?? "")
                                         setName(values.chair_name ?? "")
                                         updateEditor(values);
                                         await axios.post(`${BASE_API}/submissions/heat`, {  }, {}).then();
                                         setStep(1);
                                     }}>
                            <SingleSelect required name={"decision"} label={"Decision"} options={getOptions()} placeholder={"No Decision"}/>
                            <div>
                                <FormInput label={"Chair's Name"} id={"chair_name"} type={"text"} placeholder={"Name"} name={"chair_name"} required/>
                                <p className={"pl-2 opacity-70 tracking-tighter text-xs mt-2"}>Authors will be able to see this name for their decision. You can use your name or a generic name like the Executive Board of {conference.conference_short_name}</p>
                            </div>
                            <PrimaryButton>
                                Next
                            </PrimaryButton>
                        </FormWrapper>
                    )}

                    {step === 1 && (
                        <>
                            <FormWrapper
                                initialValues={{
                                    subject: getSubject()
                                }}
                                onSubmit={(values) => {
                                    return new Promise((resolve, reject) => {
                                        axios.post(`${BASE_API}/submissions/decide/abstract/${id}`,
                                            {
                                                decision: decision,
                                                name: name,
                                                conference_id: conference.id,
                                                subject: values.subject,
                                                content: editor.getHTML()
                                            },
                                            {  })
                                            .then((res) => {
                                                if (res.status === 201) {
                                                    dispatch({type: UPDATE_ADMIN_SUBMISSIONS, payload: { submissions: [new Submission(res.data["submission"])] } });
                                                    close_dialog();
                                                }
                                            }).catch((e) => {
                                                console.log(e);
                                                reject("Something went wrong.")
                                        });
                                    })
                                }}
                            >
                                <FormInput label={"Subject"} id={"subject"} type={"text"} placeholder={"Subject or Title"} name={"subject"} required/>
                                <div className={"h-4"}/>
                                <div className={`flex space-x-2 items-center text-sm font-medium opacity-80 mb-2`}>
                                    <RiMailLine/>
                                    <span>Confirmation Message for
                                    <span className={`ml-1 ${(() => {
                                        switch (decision) {
                                            case "Accept":
                                                return "text-green-800"
                                            case "Revise & Resubmit":
                                                return "text-yellow-700"
                                            case "Reject":
                                                return "text-red-700"
                                        }
                                    })()}`}>
                                        {(() => {
                                            switch (decision) {
                                                case "Accept":
                                                    return "Acceptance"
                                                case "Revise & Resubmit":
                                                    return "Resubmission"
                                                case "Reject":
                                                    return "Rejection"
                                            }
                                        })()}
                                    </span>
                                    </span>
                                </div>
                                <div className={" bg-white border border-gray-300 rounded-xl"}>
                                    <EditorBar editor={editor}/>
                                    <div className={"p-3"}>
                                        <EditorContent editor={editor}/>
                                    </div>
                                </div>
                                <div className={"h-4"}/>
                                <div className={"flex space-x-4"}>
                                    <SecondaryButton type={"button"} onClick={() => { setStep(0) }}>
                                        Back
                                    </SecondaryButton>
                                    <PrimaryButton type={"submit"}>
                                        Send Decision
                                    </PrimaryButton>
                                </div>
                            </FormWrapper>
                        </>
                    )}
                </>
            </div>
        </ZoomFadeDialog>
    )

}

export default AdminSubmissionDecideAbstract;