import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import { RootState } from "../../../../redux/index.reducers";
import OnLoadViewer from "../../../../components/on-load-viewer/on-load-viewer";
import SubmissionHeading from "../../../../components/submission-components/submission-details/submission-heading";
import SubmissionTimeline from "../../../../components/submission-components/submission-details/submission-timeline";
import Card from "../../../../components/cards/card";
import SubmissionAbstractView from "../../../../components/submission-components/submission-details/submission-abstract-view";
import {Submission} from "../../../../models/submission";
import AdminSubmissionDecideAbstract from "../../../../components/submission-components/submission-details/admin-submission-decide-abstract";
import SubmissionsFieldsView from "../../../../components/submission-components/submission-details/submissions-fields-view";
import SubmissionDocDataView
    from "../../../../components/submission-components/submission-details/submissions-doc-data-view";
import AdminSubmissionDecisionCard
    from "../../../../components/submission-components/submission-details/admin-submission-decision-card";

const AdminSubmissionDetails = () => {

    const submissionLeftCol = useRef<HTMLDivElement>(null);

    const conference = useSelector((state: RootState) => state.conference);

    const admin_submissions = useSelector((state: RootState) => state.adminSubmissions);
    const {id}: {id: string, action: string} = useParams();

    const [submission, setSubmission] = useState<Submission>();

    useEffect(() => {
        admin_submissions.submission(id).then((s) => { setSubmission(s) })
    }, [id, admin_submissions])

    return (
        <>
            <SubmissionHeading submission={submission} admin/>
            <OnLoadViewer loading={conference.loading || !submission}>
                <div className={"grid grid-cols-5 space-x-8 px-6"}>
                    {/*L_COL Details of Submission*/}
                    <div ref={submissionLeftCol} className={"col-span-3 space-y-4 py-8"}>
                        <div className={"flex items-center"}>
                            <p className={"font-medium text-base"}>
                                Details
                            </p>
                        </div>

                        {/*Send Decision for Admin*/}
                        {["abstract-review"].includes(submission?.status) && (
                            <div>
                                <AdminSubmissionDecisionCard to={`/admin/submissions/${id}/decide-abstract`} />
                            </div>
                        )}
                        {["paper-review"].includes(submission?.status) && (
                            <div>
                                <AdminSubmissionDecisionCard to={`/admin/submissions/${id}/decide-paper`} />
                            </div>
                        )}

                        {/*Submission Details*/}
                        <Card>
                            <SubmissionsFieldsView submission={submission}/>

                            <div className={"my-5"} style={{ width: "100%", borderBottom: "1px dashed rgb(230,230,230)" }}/>

                            <SubmissionAbstractView submission={submission} containerWidth={submissionLeftCol?.current?.clientWidth ?? 1}/>

                            {submission?.submission_data_link && (
                                <div className={"my-5"} style={{width: "100%", borderBottom: "1px dashed rgb(230,230,230)"}}/>
                            )}
                            {submission?.submission_data_link && (
                                <SubmissionDocDataView submission={submission}/>
                            )}
                        </Card>
                    </div>
                    {/*R_COL Timeline of Submission*/}
                    <div className={"col-span-2 sticky top-16 h-0 pt-8"}>
                        <SubmissionTimeline id={id} friendly_id={submission?.id} admin/>
                    </div>

                    {/*Views/Actions for Submission*/}

                    {/*Decide Submission [/decide-abstract], [/decide-paper]*/}
                    <AdminSubmissionDecideAbstract closeTo={`/admin/submissions/${id}`}/>
                </div>
            </OnLoadViewer>
        </>
    )

}

export default AdminSubmissionDetails;