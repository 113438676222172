import React, {useState} from "react";
import SlidingDialog from "../dialogs/sliding-dialog";
import Heading from "../../main-pages/attendee-dashboard/components/heading";
const DocumentViewer = ({url, type, title, children}: {url: string, type: string, title:string, children: React.ReactElement}) => {

    const [open, setOpen] = useState(false);

    return (
        <>
            {React.cloneElement(children, { onClick: (ev) => { ev.stopPropagation(); setOpen(true) } })}
            <SlidingDialog open={open} onClose={() => { setOpen(false) }}>
                <div className={"sticky top-0 z-10"}>
                    <Heading title={`#${title}`} className={"px-10"} returnType={"close"} returnTo={() => { setOpen(false) }} returnText={"Close"}/>
                </div>
                <iframe
                    title={"Document Viewer"}
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
                    width='100%' height='100%' frameBorder='0'>This is an embedded <a target='_blank'
                                                                                         href='http://office.com' rel="noreferrer">Microsoft
                    Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noreferrer">Office
                    Online</a>.
                </iframe>
            </SlidingDialog>
        </>
    )

}

export default DocumentViewer;