import React from "react";

interface PrimaryButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    icon?: React.FunctionComponent<any>,
    loading?: boolean,
    textClassName?: string
}

export const secondary_button_class = "group relative w-full flex justify-center py-2.5 px-4 border border-gray-200 text-base font-medium rounded-xl text-primary-default bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"

const SecondaryButton = (props: PrimaryButtonProps) => {

    const inputProps = {...props};
    delete inputProps.icon
    delete inputProps.loading
    delete inputProps.textClassName

    return (
        <button
            {...inputProps}
            disabled={props.loading || props.disabled}
            className={`${inputProps.className ?? ""} ${secondary_button_class}`}
        >
            <div className={`absolute left-50 inset-y-0 flex items-center transition duration-200 ${props.loading ? "opacity-100" : "opacity-0"}`}>
                <svg className="animate-spin h-5 w-5 mr-3 text-white" fill={"none"} viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                </svg>
            </div>
            <span className={`absolute left-0 inset-y-0 flex items-center pl-3`}>
                {props.icon &&
                    React.createElement(props.icon, {className: "h-4 w-4 text-primary-default group-hover:opacity-90", "aria-hidden": "true"})
                }
            </span>
            <span className={`flex items-center justify-center h-full transition duration-200 ${props.loading ? "opacity-0" : "opacity-100"} ${props.textClassName ?? ""}`}>
                {props.children}
            </span>
        </button>
    )

}

export default SecondaryButton;