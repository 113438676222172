import React, {Fragment} from "react";
import {Transition} from "@headlessui/react";

const Fade = ({children, show, appear = false, as=Fragment}: {children: React.ReactNode, show: boolean, appear?: boolean, as?: React.ElementType}) => {

    return (
        <Transition
            show={show}
            appear={appear}
            as={as}
            enter="transition ease-in-out delay-80 duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            {children}
        </Transition>
    )

}

export default Fade;