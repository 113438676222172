import {Descendant} from 'slate';

export class Submission {

    id: string = '';
    document_id: string = '';
    conference_id: string = '';
    conference_version_id: string = '';
    uid: string = '';
    title: string = '';
    abstract: Descendant[] = [];
    submission_data_link: string = "";
    submission_data_type: string = "";
    format: string = '';
    track: string = '';
    status: 'draft' | 'abstract-review' | 're-submit-abstract' | 'abstract-rejected' | 'submit-paper' | 'paper-review' | 're-submit-paper' | 'submit-other-materials' | 'final' = 'draft';
    authors: Author[] = [];
    reviewedBy: string[] = [];
    createdAt: Date | null = null;
    upload_date: Date | null = null;

    constructor(data?: { [field: string]: any }) {
        this.id = data?.id ?? '';
        this.document_id = data?.document_id ?? '';
        this.conference_id = data?.conference_id ?? '';
        this.conference_version_id = data?.conference_version_id ?? '';
        this.uid = data?.uid ?? '';
        this.title = data?.title ?? '';
        this.abstract = data?.abstract ?? [{type: 'paragraph', children: [{ text: '' }]}];
        this.submission_data_link = data?.submission_data_link ?? '';
        this.submission_data_type = data?.submission_data_type ?? '';
        this.format = data?.format ?? '';
        this.track = data?.track ?? '';
        this.status = data?.status ?? 'draft';
        if (data?.authors) {
            this.authors = [];
            data.authors.forEach((a: any) => {
                this.authors.push(new Author(a));
            });
        } else {
            this.authors = [];
        }
        this.reviewedBy = data?.reviewedBy!=null ? data.reviewedBy : [];
        this.createdAt = data?.createdAt ? (typeof data?.createdAt === "string" ? new Date(data.createdAt) : (data.createdAt instanceof Date ? data.createdAt : new Date(data.createdAt.toDate().toString()))) : null;
        this.upload_date = data?.upload_date ? (typeof data?.upload_date === "string" ? new Date(data.upload_date) : (data.upload_date instanceof Date ? data.upload_date : new Date(data.upload_date.toDate().toString()))) : null;
    }

    toJson(): Submission {
        let at = this.authors.map((a) => { return Object.assign({}, a) });
        let ab = this.abstract.map((a) => { return Object.assign({}, a) });
        let data = Object.assign({}, this);
        data.authors = at;
        data.abstract = ab;
        return data;
    }

}

export class Author {
    name: string = '';
    email: string = '';
    country: string = '';
    organization: string = '';

    constructor(data?: { [field: string]: any }) {
        this.name = data?.name ?? '';
        this.email = data?.email ?? '';
        this.country = data?.country ?? '';
        this.organization = data?.organization ?? '';
    }

    get first_name () {
        if (this.name.split(" ").length > 1) {
            return this.name.split(" ")[0]
        } else {
            return this.name;
        }
    }

    toJson(): Author {
        return Object.assign({}, this);
    }

}