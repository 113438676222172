export class UserData {
    uid: string = '';
    profilePicture: string = '';
    firstName: string = '';
    lastName: string = '';
    email: string = '';

    constructor(data?: { [field: string]: any }) {
        this.uid = data?.uid ?? '';
        this.firstName = data?.firstName ?? '';
        this.lastName = data?.lastName ?? '';
        this.profilePicture = data?.profilePicture ?? '';
        this.email = data?.email ?? '';
    }

    get initials() {
        if (this?.firstName && this?.lastName)
            return `${this?.firstName[0]}${this?.lastName[0]}`
        else
            return `${this?.firstName[0] ?? ""}`
    }

    get name() {
        if (this?.firstName && this?.lastName)
            return `${this?.firstName} ${this?.lastName}`
        else
            return `${this?.firstName}`
    }

    toJson(): UserData {
        return Object.assign({}, this);
    }

}