import React, {Fragment, useState} from "react";
import Fade from "../../transitions/fade";
import PrimaryButton from "../../buttons/button";
import FormErrorText from "../../forms/form-error-text";
import {PostConferenceActivity, RegistrantDetails, Registration} from "../../../models/registration";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import SecondaryButton from "../../buttons/secondary-button";
import FormInput from "../../forms/form-input";
import FormWrapper from "../../forms/form-wrapper";
import Countries from "../../forms/countries.json";
import SingleSelect from "../../forms/form-single-select";
import {Ticket} from "react-iconly";
import {formatCurrency} from "../../../utils/methods";
import { RegistrationPostConference } from "../../../models/conference-settings";
import ZoomFadeDialog from "../../dialogs/zoom-fade-dialog";
import Heading from "../../../main-pages/attendee-dashboard/components/heading";

const RegistrationRegistrantDetails = ({show=true, registration, onChange, onBack, onSubmit}: {show: boolean, registration: Registration, onChange: (newRegistration: Registration) => void, onSubmit: () => void, onBack: (val?: number) => void }) => {

    const conference = useSelector((state: RootState) => state.conference);
    const [error, setError] = useState("");

    const [post_conference_data, setPostConferenceData] = useState<RegistrationPostConference>()
    const [post_conference_dialog, setPostConferenceDialog] = useState<number>(-1)

    const [radio_select, setRadioSelect] = useState<string>("")

    const goNext = () => {
        if (registration.tickets.length > 0) {
            setError("");

            let s = new Registration(registration.toJson());
            onChange(s);
            onSubmit();
        } else {
            setError("Please add at-least one ticket.")
        }
    }

    const addPostConference = (post_conference: RegistrationPostConference, index:number, variation_id?: string) => {
        let t = new Registration(registration.toJson());

        if (variation_id) {
            t.tickets[index].post_conference_activities.push(new PostConferenceActivity({
                id: post_conference.id,
                title: post_conference.title,
                price: post_conference.price + post_conference.options.find(t => t.id === variation_id).price,
                variation_id: variation_id,
                variation_name: post_conference.options.find(t => t.id === variation_id).title,
                has_variation: true
            }));
        } else {
            t.tickets[index].post_conference_activities.push(new PostConferenceActivity({
                id: post_conference.id,
                title: post_conference.title,
                price: post_conference.price,
            }));
        }
        onChange(t);
    }

    const removePostConference = (post_conference: RegistrationPostConference, index:number) => {
        let t = new Registration(registration.toJson());
        let removal_index =  t.tickets[index].post_conference_activities.findIndex(pc => post_conference.id === pc.id);
        t.tickets[index].post_conference_activities.splice(removal_index, 1)
        onChange(t);
    }

    return (
        <Fade
            show={show}
            as={Fragment}
        >
            <div>
                <p className={"max-w-lg pt-6 pb-2 text-xl font-medium border-b border-gray-200"}>
                    Add Registrant Details:
                </p>
                <div className={"max-w-lg mt-4"}>
                    {registration.tickets.length > 0 && (
                        <FormWrapper
                            inlineError
                            onSubmit={(values) => {
                                return new Promise((resolve, reject) => {
                                    let errors: { [field: string]: any } = {};

                                    // Check for duplicate emails
                                    let duplicate_emails: string[] = [];
                                    Object.keys(values).filter(k => k.endsWith("email")).forEach((key) => {
                                        let field_ind = key.split("__")[0];
                                        Object.keys(values).filter(k => k.endsWith("email")).forEach(key_j => {
                                            let field_jnd = key_j.split("__")[0];
                                            if (key !== key_j && values[key_j] === values[key]) {
                                                duplicate_emails.push(field_jnd, field_ind);
                                            }
                                        })
                                    });

                                    duplicate_emails.forEach((duplicate_index) => {
                                        errors[`${duplicate_index}__email`] = "You can’t assign two tickets to the same email";
                                    });

                                    if (duplicate_emails.length > 0) {
                                        return reject(errors);
                                    } else {
                                        goNext();
                                    }
                                })
                            }}
                            className={"mt-6 space-y-12"}
                            onValueChange={(values) => {
                                let s = new Registration(registration.toJson());
                                s.tickets.forEach((t, index) => {
                                    let r_data: { [field: string]: any } = {
                                        meta_data: {}
                                    };
                                    Object.keys(values).filter(k => k.split("__")[0] === index.toString()).forEach((key) => {
                                        let f_key = key.split("__")[1];
                                        if (f_key === "name")
                                            r_data["name"] = values[key];
                                        else if (f_key === "email")
                                            r_data["email"] = values[key];
                                        else {
                                            r_data["meta_data"][f_key] = values[key];
                                        }
                                    })
                                    t.registrant_details = new RegistrantDetails(r_data);
                                })
                                onChange(s);
                            }}
                            initialValues={(() => {
                                const form_values:{ [field: string]: any } = {};
                                registration.tickets.forEach((t, index) => {
                                    let registrant_details: { [field: string]: any } = t.registrant_details.toJson();
                                    Object.keys(registrant_details).forEach((key) => {
                                        if (key === "meta_data" && registrant_details["meta_data"]) {
                                            Object.keys(registrant_details["meta_data"]).forEach((meta_key) => {
                                                form_values[`${index}__${meta_key}`] = registrant_details["meta_data"][meta_key];
                                            });
                                        } else {
                                            form_values[`${index}__${key}`] = registrant_details[key];
                                        }
                                        if (!form_values[`${index}__country`])
                                            form_values[`${index}__country`] = registration.country;
                                    })
                                })
                                return form_values;
                            })()}
                        >
                            {
                                registration.tickets.map((ticket, ind) => {
                                    return (
                                        <div key={`${ind}__${ticket.title}`}>
                                            <div className={"pb-2 relative border-b border-gray-200"}>
                                                <p className={"text-sm font-medium"}>
                                                    <span className={"font-bold text-primary-default mr-3"}>
                                                        {ind+1}.
                                                    </span>
                                                    {ticket.title}
                                                </p>
                                            </div>
                                            <div className={"pb-6 pt-4"}>
                                                <div>
                                                    {
                                                        conference.registration_data.map((registrant_data, jnd) => {
                                                            return (
                                                                <div key={`${ind}__${ticket.title}__section__${jnd}`} className={"space-y-6"}>
                                                                    <div className={"space-y-6"}>
                                                                        {
                                                                            registrant_data.fields.map((field, knd) => {
                                                                                switch (field.type) {
                                                                                    case "personal_details":
                                                                                        return <div key={`${ind}__${jnd}__${knd}__personal`} className={"space-y-6"}>
                                                                                            <FormInput  key={`${ind}__name`} name={`${ind}__name`} type={"text"} placeholder={"Registrant's Name"} label={"Full Name"} autoComplete={"name"} required/>
                                                                                            <FormInput key={`${ind}__name`} name={`${ind}__email`} type={"email"} placeholder={"Registrant's Email"} label={"Email Address"} autoComplete={"email"} required/>
                                                                                        </div>
                                                                                    case "country":
                                                                                        return <SingleSelect key={`${ind}__${jnd}__${knd}__country`} name={`${ind}__country`} placeholder={"Select Registrant's Country"} label={"Country"} options={Countries} />
                                                                                    default:
                                                                                        return <></>
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {conference.registration_post_conference.length > 0 && (
                                                                        <div className={"rounded-xl border border-gray-300 shadow-sm bg-white"}>
                                                                            <div className={"border-b border-gray-200 px-3 py-2 text-sm opacity-80 font-medium"}>
                                                                                Post Conference Activities
                                                                            </div>
                                                                            <div className={"divide-y divide-gray-200"}>
                                                                                {conference.registration_post_conference.map(post_conference => {
                                                                                    return (
                                                                                        <div key={`${ind}__${post_conference.id}`} className={"px-4 py-4 flex items-start"}>
                                                                                            <div className={"w-full mr-8"}>
                                                                                                <p className={"text-base font-medium"}>
                                                                                                    {post_conference.title}
                                                                                                </p>
                                                                                                <p className={"text-sm mb-1.5 opacity-70"}>
                                                                                                    {post_conference.short_description}
                                                                                                </p>
                                                                                                <p className={"opacity-80 text-sm"}>
                                                                                                    {formatCurrency(post_conference.price)}{post_conference.price_can_change ? "+" : ""}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className={"flex relative"}>
                                                                                                {registration.tickets[ind].post_conference_activities.findIndex(pc => pc.id === post_conference.id) === -1 ?
                                                                                                (
                                                                                                    <button type={"button"}
                                                                                                        onClick={() => {
                                                                                                            if (post_conference.options.length > 0) {
                                                                                                                setPostConferenceData(post_conference)
                                                                                                                setPostConferenceDialog(ind)
                                                                                                            } else {
                                                                                                                addPostConference(post_conference, ind);
                                                                                                            }
                                                                                                        }}
                                                                                                        className={`py-2 w-20 rounded-xl bg-black text-white text-sm font-semibold hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light`}>
                                                                                                        Add
                                                                                                    </button>
                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    <button type={"button"}
                                                                                                        onClick={() => {
                                                                                                            removePostConference(post_conference, ind);
                                                                                                        }}
                                                                                                        className={`py-2 w-20 rounded-xl bg-transsparent text-primary-default border border-primary-light text-sm font-semibold hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light`}>
                                                                                                        Remove
                                                                                                    </button>
                                                                                                )

                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            {ind !== (registration.tickets.length-1) &&
                                                (
                                                    <div className={"flex items-center"}>
                                                        <div className={"flex-1 h-px w-full bg-gray-200"}/>
                                                        <div className={"flex flex-shrink-0 mx-3 text-xs text-gray-300"}>
                                                            ● ●
                                                        </div>
                                                        <div className={"flex-1 h-px w-full bg-gray-200"}/>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                })
                            }
                            <div className={"sticky bottom-0 py-4 max-w-lg w-full z-40 border-t border-gray-200 mt-10 bg-gray-50 ring-1 ring-gray-50"} >
                                <FormErrorText error={error} showError={!!error}/>
                                <div className={"flex space-x-6"}>
                                    <SecondaryButton className={"mt-2 text-gray-600"} onClick={() => { onBack() }} style={{ width: "auto" }}>
                                        Back
                                    </SecondaryButton>
                                    <PrimaryButton
                                        className={"mt-2"}
                                        style={{ width: "auto" }}
                                    >
                                        Proceed to Payment
                                    </PrimaryButton>
                                </div>
                            </div>
                        </FormWrapper>
                    )}
                    {registration.tickets.length === 0 && (
                        <div className={"rounded-xl py-8 px-3 text-center bg-gray-100 border border-gray-200 flex flex-col items-center justify-center space-y-2"}>
                            <Ticket set={"bulk"} className={"text-primary-default"} size={"xlarge"}/>
                            <span>
                                Please select tickets before entering<br/> registrant details.
                            </span>
                            <div className={"flex "}>
                                <SecondaryButton onClick={() => { onBack() }}>
                                    Select Tickets
                                </SecondaryButton>
                            </div>
                        </div>
                    )}
                </div>
                <ZoomFadeDialog open={post_conference_dialog !== -1} onClose={() => { setPostConferenceDialog(-1); setTimeout(() => { setPostConferenceData(undefined) }, 310) }}>
                    <div  className={"px-6 pb-6"}>
                        <div className={"sticky top-0 z-10"}>
                            <Heading title={post_conference_data?.title ?? ""} className={"bg-gray-50"}/>
                        </div>
                            
                        <div className={"border rounded-xl mt-4 divide-y overflow-hidden"}>
                            {
                                (post_conference_data?.options ?? []).map((op) => {
                                    return (
                                        <div className={"px-4 py-2 flex space-x-6 items-center hover:bg-white cursor-pointer"} onClick={() => { setRadioSelect(op.id); }}>
                                            <input required type={"radio"} id={`pc_option_${op.id}`} name={"pc_option"} value={op.id} className={"text-primary-default focus:ring-primary-default"}/>
                                            <label htmlFor={`pc_option_${op.id}`} className={"w-full"}>
                                                <p className={"text-base font-medium"}>
                                                    {op.title}
                                                </p>
                                                <p className={"opacity-80 text-sm"}>
                                                    {formatCurrency(post_conference_data.price + op.price)}
                                                </p>
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={"sticky bottom-0 pt-4 max-w-lg w-full z-40 border-t border-gray-200 mt-6 bg-gray-50 ring-1 ring-gray-50"} >
                            <div className={"flex space-x-6"}>
                                <SecondaryButton className={"mt-2 text-gray-600"} onClick={() => { setPostConferenceDialog(-1); setTimeout(() => { setPostConferenceData(undefined) }, 310) }} style={{ width: "auto" }}>
                                    Cancel
                                </SecondaryButton>
                                <PrimaryButton
                                    className={"mt-2"}
                                    style={{ width: "auto" }}
                                    onClick={() => {
                                        addPostConference(post_conference_data, post_conference_dialog, radio_select);
                                        setPostConferenceDialog(-1); setTimeout(() => { setPostConferenceData(undefined) }, 310)
                                    }}
                                >
                                    Confirm
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>
                </ZoomFadeDialog>
            </div>
        </Fade>
    )

}

export default RegistrationRegistrantDetails;