import React, {useCallback, useMemo} from "react";
import {BaseEditor, createEditor, Descendant, Editor} from 'slate';
import {Slate, Editable, withReact, ReactEditor} from 'slate-react';
import {HistoryEditor, withHistory} from 'slate-history';
import isHotkey from 'is-hotkey';
// import {FiBold, FiItalic, FiList, FiUnderline} from "react-icons/all";

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
};

declare module 'slate' {
    // noinspection JSUnusedGlobalSymbols
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor & HistoryEditor
        Element: {
            type: 'paragraph'
            children: { text: string; bold?: boolean, italic?: boolean, underline?: boolean }[]
        }
        Text: { text: string; bold?: boolean, italic?: boolean, underline?: boolean }
    }
}

// const LIST_TYPES = ['numbered-list', 'bulleted-list'];

// const useStyles = makeStyles((theme: Theme) => ({
//     root: {
//         border: "none",
//         width: "38px",
//         height: "38px",
//         margin: "4px !important",
//         borderRadius: "4px !important",
//     },
//     selected: {
//         color: theme.palette.primary.main,
//         backgroundColor: "rgba(9,135,118,0.15) !important",
//         "& span": {
//             color: "rgb(9,135,118)"
//         }
//     }
// }));

const RichTextEditor = ({value, onChange, placeholder, readOnly=false, error} : {value:Descendant[], onChange: (newValue: Descendant[]) => void, placeholder: string, readOnly?: boolean, error?: string | boolean}) => {

    const editor = useMemo(() => withHistory(withReact(createEditor())), [])
    const renderElement = useCallback(({ attributes, children, element }) => {
        switch (element.type) {
            case 'paragraph':
                return <p {...attributes}>{children}</p>
            default:
                return <p {...attributes}>{children}</p>
        }
    }, [])

    const renderLeaf = useCallback(({ attributes, children, leaf }) => {
        if (leaf.bold) {
            children = <span className={"font-semibold"}>{children}</span>
        }

        if (leaf.italic) {
            children = <em>{children}</em>
        }

        if (leaf.underline) {
            children = <u>{children}</u>
        }

        return <span {...attributes}>{children}</span>
    }, [])

    // const isBlockActive = (editor: ReactEditor, format: string) => {
    //     // @ts-ignore
    //     const [match] = Editor.nodes(editor, {
    //         match: n => n.type === format,
    //     })
    //
    //     return !!match
    // }

    // const toggleBlock = (editor: ReactEditor, format: string) => {
    //     const isActive = isBlockActive(editor, format)
    //     const isList = LIST_TYPES.includes(format)
    //
    //     Transforms.unwrapNodes(editor, {
    //         match: n => LIST_TYPES.includes(n.type as string),
    //         split: true,
    //     })
    //
    //     Transforms.setNodes(editor, {
    //         type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    //     })
    //
    //     if (!isActive && isList) {
    //         const block = { type: format, children: [] }
    //         Transforms.wrapNodes(editor, block)
    //     }
    // }

    const toggleMark = (editor: Editor, format: "bold" | "italic" | "underline") => {
        const isActive = isMarkActive(editor, format)

        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }

    const isMarkActive = (editor: Editor, format: "bold" | "italic" | "underline") => {
        const marks = Editor.marks(editor)
        return marks ? marks[format] === true : false
    }

    // const BlockButton = ({ format, icon }: {format: string, icon: React.ReactNode}) => {
    //
    //     const editor = useSlate();
    //     const classes = useStyles();
    //
    //     return (
    //         <ToggleButton
    //             classes={{
    //                 root: classes.root,
    //                 selected: classes.selected
    //             }}
    //             TouchRippleProps={{ style: { filter: "blur(10px)", opacity: "0.95" } }}
    //             selected={isBlockActive(editor, format)}
    //             onMouseDown={event => {
    //                 event.preventDefault()
    //                 toggleBlock(editor, format)
    //             }}
    //         >
    //             {icon}
    //         </ToggleButton>
    //     )
    // }
    //
    // const MarkButton = ({ format, icon }: {format: string, icon: React.ReactNode}) => {
    //
    //     const editor = useSlate()
    //     const classes = useStyles();
    //
    //     return (
    //         <ToggleButton
    //             classes={{
    //                 root: classes.root,
    //                 selected: classes.selected
    //             }}
    //             TouchRippleProps={{ style: { filter: "blur(10px)", opacity: "0.95" } }}
    //             selected={isMarkActive(editor, format)}
    //             onMouseDown={event => {
    //                 event.preventDefault()
    //                 toggleMark(editor, format)
    //             }}
    //         >
    //             {icon}
    //         </ToggleButton>
    //     )
    // }

    return (
        <>
            <Slate editor={editor} value={value} onChange={onChange}>
                {/*{!readOnly &&*/}
                {/*<ToggleButtonGroup aria-label="text formatting" style={{ background: "rgb(250,250,250)", borderRadius: "6px", padding: "3px", width: "100%", boxSizing: "border-box" }}>*/}
                {/*    <MarkButton format="bold" icon={<FiBold/>} />*/}
                {/*    <MarkButton format="italic" icon={<FiItalic/>} />*/}
                {/*    <MarkButton format="underline" icon={<FiUnderline/>} />*/}
                {/*    <BlockButton format="numbered-list" icon={<FiList/>} />*/}
                {/*    <BlockButton format="bulleted-list" icon={<FiList/>} />*/}
                {/*</ToggleButtonGroup>*/}
                {/*}*/}
                <Editable
                    placeholder={placeholder}
                    renderPlaceholder={({ children, attributes }) => (
                        <span {...attributes} style={{ ...attributes.style, opacity: 1, width: "auto" }} className={"text-base text-gray-500"}>{children}</span>
                    )}
                    className={`${readOnly ? "" : "slate-editable"} appearance-none relative block w-full ${error ? "border-red-300 z-10" : "border-gray-300"} text-gray-900 focus:outline-none ${error ? "focus:ring-red-400" : "focus:ring-primary-default"} ${error ? "focus:border-red-400" : "focus:border-primary-default"} focus:z-20 ${readOnly ? "text-sm" : "ring-1 ring-gray-50 bg-white text-sm shadow-sm px-3 py-2 border rounded-xl min-h-32"}`}
                    style={{
                        overflow: readOnly ? "hidden" : 'overlay'
                    }}
                    spellCheck
                    readOnly={readOnly}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    onKeyDown={(event) => {
                        for (const hotkey  in HOTKEYS) {
                            if (isHotkey(hotkey, event as any)) {
                                event.preventDefault()
                                if (hotkey === 'mod+b' || hotkey === 'mod+i' || hotkey === 'mod+u') {
                                    const mark = HOTKEYS[hotkey]
                                    if (mark === "bold" || mark === "italic" || mark === "underline")
                                        toggleMark(editor, mark)
                                }
                            }
                        }
                    }}
                />
            </Slate>
        </>
    )

}

export default RichTextEditor;