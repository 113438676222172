import React from "react";
import {Link} from "react-router-dom";

const ActionError = () => {

    return (
        <>
            <div className={"flex justify-center align-start"}>
                <div>
                    <h2 className="text-3xl font-bold text-gray-900">This action does not exist.</h2>
                    <p className="text-sm text-gray-700 mt-2">
                        The link you are trying to access is either invalid or expired. Try requesting a new link.
                    </p>
                    <div className="flex items-center justify-between pt-4">
                        <div className="text-sm">
                            <span>
                                Go back to {" "}
                            </span>
                            <Link to="/" className="font-medium text-primary-default hover:text-primary-dark">
                                Dashboard
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActionError;