import React from "react";
import {Link, Route, Switch, useLocation} from "react-router-dom";
import {ArrowLeft, Category, Document, People, Ticket} from 'react-iconly'
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import AdminSubmissionsView from "./submissions/submissions-view/admin-submissions-view";
import AdminDecisionsView from "./submissions/decisions/admin-decisions-view";
import AdminSubmissionDetails from "./submissions/submissions-details/admin-submission-details";
import ReduxSubmissions from "./submissions/redux-submissions/redux-submissions";
import AdminRegistrationsView from "./registrations/registrations-view/admin-registration-view";
import ReduxRegistrations from "./registrations/redux-registrations/redux-registrations";

const AdminWrapper = () => {

    const location = useLocation();
    const { userData } = useSelector((state: RootState) => state.userData);

    const menuItems = [
        // {
        //     icon: Category,
        //     label: "Overview",
        //     to: "/admin"
        // },
        // {
        //     icon: Edit,
        //     label: "Setup",
        //     to: "/admin/setup"
        // },
        {
            icon: Document,
            label: "Submissions",
            to: "/admin/submissions",
            children: [
                // {
                //     label: "Details",
                //     to: "/admin/submissions/details"
                // },
                {
                    label: "Submissions",
                    to: "/admin/submissions/view"
                },
                {
                    label: "Decisions",
                    to: "/admin/submissions/decisions"
                }
            ]
        },
        {
            icon: Ticket,
            label: "Registrations",
            to: "/admin/registrations"
        },
        // {
        //     icon: People,
        //     label: "People",
        //     to: "/admin/people"
        // },
        // {
        //     icon: Graph,
        //     label: "Analytics",
        //     to: "/admin/analytics"
        // }
    ]

    return (
        <div className="bg-gray-50 min-h-screen text-sm">
            {/*Navbar*/}


            {/*Content*/}
            <div className="flex-grow flex w-full">
                <div className={"flex z-30 flex-col w-64 flex-shrink-0 border-r border-gray-200 sticky top-0"} style={{ background: "rgb(255,255,255)", height: "calc(100vh)" }}>
                    <Link to={"/"} className={"h-16 border-b border-gray-200 mb-4 flex items-center justify-start cursor-pointer"}>
                        <div className={"flex px-6"}>
                            <ArrowLeft className={"text-purple-900 mr-4"} style={{}} stroke={"bold"} size={"medium"} />
                        </div>
                    </Link>
                    <div className={"space-y-5"}>
                        {
                            menuItems.map((item, index) => {

                                const active = (location.pathname.startsWith(item.to) && item.to !== "/admin") || (location.pathname === item.to || location.pathname === (item.to + "/"));

                                return (
                                    <div className={`flex items-center ml-4`} key={`admin_nav_${index.toString()}`}>
                                        {
                                            <Link to={item.to} className={`${active ? "bg-gray-100" : ""} group px-4 py-0.5 rounded-xl flex items-center justify-start text-xs font-normal transition duration-100 ${active ? "text-primary-default" : "text-gray-800 opacity-100 hover:opacity-70"} focus:outline-none`}>
                                                <div className={`p-2 pl-0 pr-4 rounded-lg transition-bg duration-300`}>
                                                    {item.icon &&
                                                        React.createElement(item.icon, { set: 'bulk', size: 20, style: {flexShrink: 0, opacity: active ? "1.0" : "0.80", transition: "0.3s"} })
                                                    }
                                                </div>
                                                <span className={"font-medium text-base"} style={{ opacity: active ? "1.0" : "0.80" }}>
                                                    {item.label}
                                                </span>
                                            </Link>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={"flex-grow"}>
                    <div className="bg-white relative z-30 h-16 flex w-full border-b border-gray-200 px-5 sm:px-10 flex-shrink-0 sticky top-0">
                        <p className={"text-lg font-semibold text-blacks-dark h-full flex items-center"}>
                            {(() => {
                                
                                const active = menuItems.find((item) => ((location.pathname.startsWith(item.to) && item.to !== "/admin") || (location.pathname === item.to || location.pathname === (item.to + "/"))));

                                return active?.label
                            })()}
                        </p>
                        <div className={"ml-auto"}/>
                        <div className={"h-full flex items-center"}>
                            <button className={`group w-10 h-8 flex items-center justify-center text-sm focus:outline-none rounded-full bg-primary-lighter`}>
                                <span className={"font-semibold text-primary-default"}>
                                    {(userData?.firstName && userData?.lastName) &&
                                        userData?.firstName[0] + userData?.lastName[0]
                                    }
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className={"px-5 sm:px-6 mx-auto"}>
                        {/*Redux */}
                        <Switch>
                            <Route path={['/admin/submissions']} component={ReduxSubmissions} />
                            <Route path={['/admin/registrations']} component={ReduxRegistrations} />
                        </Switch>
                        {/*UI */}
                        <Switch>
                            {/*<Route exact path={'/'} component={Overview} />*/}

                            <Route exact path={['/admin/submissions', '/admin/submissions/browse']} component={AdminSubmissionsView} />
                            <Route exact path={['/admin/submissions/:id', '/admin/submissions/:id/:action']} component={AdminSubmissionDetails} />

                            <Route exact path={['/admin/submissions/decisions/:id']} component={AdminDecisionsView} />
                            {/*<Route exact path={['/submissions/new']} component={NewSubmission} />*/}
                            {/*<Route exact path={['/submissions/:id']} component={SubmissionDetails} />*/}

                            <Route exact path={['/admin/registrations', '/admin/registrations/payments']} component={AdminRegistrationsView} />
                            {/*<Route exact path={'/registrations'} component={Orders} />*/}

                            {/*<OpenRoute>*/}
                            {/*    <div>404</div>*/}
                            {/*</OpenRoute>*/}
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AdminWrapper;