import React, {useEffect, useState} from "react";
import {auth} from "../../utils/firebase.utils";
import {useHistory, useLocation} from "react-router-dom";
import queryString from "querystring";
import axios from "axios";
import { BASE_API } from "../../utils/constants";
import { signInWithEmailLink } from "firebase/auth";

const LinkSignIn = () => {

    const history = useHistory();
    const location = useLocation();
    const [status, setStatus] = useState(0);

    useEffect(() => {
        let {email} = queryString.parse(location.search.replace("?", ""));

        signInWithEmailLink(auth, email.toString(), window.location.href)
            .then(async (cred) => {

                setStatus(1);
                let token = await cred.user?.getIdToken();

                axios.post(`${BASE_API}/dashboard-createUser`, {}, { headers: { authorization: token } }).then(async (res) => {
                    if (res.status === 201) {

                    }
                });

            })
            .catch(() => {
                history.push("/");
            });
        // eslint-disable-next-line
    }, [])

    return (
        <div className={"flex flex-col justify-center items-center"}>
            <svg className="animate-spin h-5 w-5 text-primary-default" fill={"none"} viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
            </svg>
            <p className="text-sm text-gray-400 mt-2">
                {
                    (() => {
                        switch (status) {
                            case 0:
                                return "Signing in";
                            case 1:
                                return "Updating user settings";
                            default:
                                return "";
                        }
                    })()
                }
            </p>
        </div>
    )
}

export default LinkSignIn;