import React from "react";
import {Submission} from "../../../models/submission";
import {authorValue} from "../../../utils/methods";

const SubmissionsFieldsView = ({submission}: {submission?: Submission}) => {

    const FIELDS = [
        {
            label: "Track",
            field: "track",
            type: "string"
        },
        {
            label: "Presentation Format",
            field: "format",
            type: "string"
        }
    ]

    return (
        <div className={"space-y-2"}>
            <div className={"flex"}>
                <p className={"text-sm "} style={{ marginRight: "12px", opacity: "0.8" }}>
                    Authors:
                </p>
                <p className={"text-sm font-medium"}>
                    {authorValue(submission?.authors ?? [])}
                </p>
            </div>
            {
                FIELDS.map((field) => {
                    return (
                        <div className={"flex"} key={field.field}>
                            <p className={"text-sm opacity-80 mr-3"}>
                                {field.label}:
                            </p>
                            <p className={"text-sm font-medium"}>
                                {submission[field?.field] ?? ""}
                            </p>
                        </div>
                    )
                })
            }
        </div>
    )

}

export default SubmissionsFieldsView;