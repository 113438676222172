import React from "react";
import FormInput from "../../components/forms/form-input";
import FormCheckBox from "../../components/forms/form-checkbox";
import PrimaryButton from "../../components/buttons/button";
import FormWrapper from "../../components/forms/form-wrapper";
import FormErrorText from "../../components/forms/form-error-text";
import {auth} from "../../utils/firebase.utils";
import {browserSessionPersistence, setPersistence, indexedDBLocalPersistence} from "firebase/auth";
import InlineLink from "../../components/links/inline-link";
import { signInWithEmailAndPassword } from "firebase/auth";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import { Transition } from "@headlessui/react";

const SignIn = () => {

    const conference = useSelector((state: RootState) => state.conference);
    const { loading } = useSelector((state: RootState) => state.userData);

    // Handle Sign In
    const signIn = (values: Record<string, string | boolean>) => {
        return new Promise( async (resolve, reject) => {
            let persistence = values["remember_me"] ? indexedDBLocalPersistence : browserSessionPersistence;
            await setPersistence(auth, persistence);
            signInWithEmailAndPassword(auth, values['email'].toString(), values['password'].toString())
                .then(() => {
                    // Automatic Redirect to HomeIndex
                })
                .catch((err) => {
                    switch (err.code) {
                        case "auth/user-not-found":
                        case "auth/email-not-found":
                            return reject({email: "empty", text: "Couldn't find your account."});
                        case "auth/wrong-password":
                            return reject({password: "empty", text: "That's an incorrect password. Try again."});
                        case "auth/invalid-email":
                            return reject({email: "empty", text: "Please enter a valid Email Address."});
                        case "auth/too-many-requests":
                            return reject("Access to this account is temporarily disabled due to many failed attempts. Please reset your password.");
                        default:
                            return reject("Something went wrong on our side.");
                    }
                });
        });
    };

    return (
        <div className="min-h-screen flex justify-center bg-white items-start sm:items-center">
            <div className="max-w-md w-full m-6 mt-10 sm:m-20">
                <Transition
                    appear={true}
                    show={!loading}
                    enter="transition ease-in-out delay-150 duration-300 transform"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100  translate-y-0"
                    className="max-w-md w-full space-y-8"
                >
                    <div>
                        <div className={"flex justify-between align-start"}>
                            <h2 className="text-3xl font-bold text-gray-900">Sign in to your<br/>account</h2>
                            {(!conference.loading && conference.logo) &&
                            <img
                                className="mt-1 h-5"
                                src={conference.logo}
                                alt="Conference Logo"
                            />
                            }
                        </div>
                        <div className="text-sm mt-2">
                            Not Registered? {" "}
                            <InlineLink to="/sign-up">
                                Create an Account
                            </InlineLink>
                        </div>
                    </div>
                    <FormWrapper onSubmit={signIn} className={"space-y-6"}>
                        <div>
                            <div className="rounded-md shadow-sm -space-y-px">
                                <FormInput
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    placeholder="Email address"
                                    label="Email address"
                                    hideLabel
                                    hideShadow
                                    className={"rounded-t-xl"}
                                    disableRounded
                                    required
                                />
                                <FormInput
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    label="Password"
                                    hideLabel
                                    hideShadow
                                    placeholder="Password"
                                    className={"rounded-b-xl"}
                                    disableRounded
                                />
                            </div>

                            <FormErrorText/>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <FormCheckBox
                                    id="remember_me"
                                    name="remember_me"
                                    label={"Remember me"}
                                />
                            </div>

                            <div className="text-sm">
                                <InlineLink to="/forgot-password">
                                    Forgot your password?
                                </InlineLink>
                            </div>
                        </div>
                        <div>
                            <PrimaryButton type={"submit"}>
                                Sign In
                            </PrimaryButton>
                        </div>
                    </FormWrapper>
                </Transition>
            </div>
            <div className={"w-full min-h-screen bg-gray-100 hidden relative sm:block"}>
                <Transition
                    show={!conference.loading}
                    enter="transition-opacity duration-150"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className={"absolute left-0 top-0 w-full h-full"} style={{ background: `url("${conference.background_auth}") left top / cover` }}/>
                </Transition>
            </div>
        </div>
    )
}

export default SignIn;