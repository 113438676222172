import moment from "moment";

export default class StaticDate {

    day: number;
    month: number;
    year: number;


    constructor(data?: { [field: string]: any }) {
        this.day = data?.day ?? 0;
        this.month = data?.month ?? 0;
        this.year = data?.year ?? 0;
    }

    formatted_date(format: string = "DD MMM YYYY") {
        let date = new Date(this.year,this.month-1,this.day);
        if (this.day && this.month && this.year && moment.isDate(date)) {
            return moment(date).format(format);
        } else {
            return "";
        }
    }

    get date() {
        return new Date(this.year,this.month-1,this.day);
    }

    range(end: StaticDate) {

        let date = moment(new Date(this.year,this.month-1,this.day));
        let endDate = moment(new Date(end.year,end.month-1,end.day));

        if (date.isValid() && endDate.isValid() && date.isBefore(endDate)) {
            //TODO Adapt for diff. month/year
            return `${date.format('DD')} - ${end.formatted_date()}`
        } else{
            return "";
        }



    }
}