import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import Heading from "../components/heading";
import ConferenceOverview from "./sections/conference-overview";
import SubmissionOverview from "./sections/submission-overview";
import OnLoadViewer from "../../../components/on-load-viewer/on-load-viewer";
import RegistrationOverview from "./sections/registration-overview";

const Overview = () => {

    const conference = useSelector((state: RootState) => state.conference);

    // useEffect(() => {
    //     let msg: any[] = [];
    //     getDocs(collection(firestore, "conferences", "icsg", "versions", "2021-1", "submissions")).then((snapshot) => {
    //         snapshot.docs.forEach((doc) => {
    //             let s = new Submission(doc.data());
    //
    //             s.authors.forEach((author) => {
    //                 msg.push({
    //                     "to": {
    //                         "name": author.name,
    //                         "email": author.email
    //                     },
    //                     "from": {
    //                         "name": "ICSG 2022",
    //                         "email": "no-reply@atticcreation.com"
    //                     },
    //                     "templateId": "d-f83a2447967c4a5497a3bdbaea9b656f",
    //                     "dynamicTemplateData" : {
    //                         "conference_title": "ICSG 2022",
    //                         "logo": "https://ik.imagekit.io/atticcreation/korero/public_data/logo.webp?tr:f-webp",
    //                         "web_link": "https://icsg.world",
    //                         "date": "07-10 Jan. 2022",
    //                         "title": s.title,
    //                         "authors": authorValue(s.authors),
    //                         "name": author.name
    //                     }
    //                 })
    //             })
    //
    //                 console.log(authorValue(s.authors))
    //                 s.authors.forEach((a) => { console.log((a.email)) })
    //                 console.log("___")
    //         })
    //
    //         // axios.post("https://us-central1-korero-conference.cloudfunctions.net/sendSubmissionReceipt", {"msg": msg}).then();
    //     })
    // }, [])

    return (
        <>
            <OnLoadViewer loading={conference.loading}>
                <Heading title={conference.conference_short_name}/>
                <div className="sm:py-7 py-4 grid grid-cols-1 md:grid-cols-2 gap-6 justify-evenly">
                    <div className={"col-span-1 w-full"}>
                        <ConferenceOverview/>
                    </div>
                    <div className={"col-span-1 w-full"}>
                        <div className={"space-y-4"}>
                            <SubmissionOverview/>
                            {/*<RegistrationOverview/>*/}
                        </div>
                    </div>
                </div>
            </OnLoadViewer>
        </>
    )
}

export default Overview;