import {Link} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import { Popover } from "@headlessui/react";
import {FiLogOut, FiSettings, FiSmile} from "react-icons/all";
import { signOut } from "firebase/auth";
import {auth} from "../../../utils/firebase.utils";

const NavigationBar = () => {

    const conference = useSelector((state: RootState) => state.conference);
    const { userData, role } = useSelector((state: RootState) => state.userData);

    return (
        <div className="bg-white z-30 h-16 flex w-full border-b border-gray-200 px-5 sm:px-10 flex-shrink-0 sticky top-0 left-0">
            {(!conference.loading && conference.logo) &&
            <img
                className="absolute top-1/2 transform -translate-y-1/2 h-5"
                src={conference.logo}
                alt="Conference Logo"
            />
            }
            <div className={"ml-auto"}/>
            <div className={"w-4"}/>
            <div className={"h-full flex items-center"}>
                <Popover className="relative ">
                    {({ close }) => (
                        <>
                            <Popover.Button id={"navigation-account"} className={`w-10 h-8 flex items-center justify-center text-sm focus:outline-none rounded-full bg-primary-lighter transition duration-200 border-primary-lighter border-2 hover:border-primary-default focus:border-primary-default font-semibold text-primary-default`}>
                                {userData?.initials ?? ""}
                            </Popover.Button>
                            <Popover.Panel className="absolute z-30 w-screen mt-3 transform right-0 lg:max-w-xs" style={{ maxWidth: "250px" }}>
                                <div className={"overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"} onClick={() => { close() }}>
                                    <div className={"relative flex flex-col divide-y divide-black divide-opacity-5"} style={{ background: "rgba(255,255,255,0.7)", backdropFilter: "blur(60px) saturate(210%)" }}>
                                        <div className={"flex flex-col justify-center items-center py-6 text-sm"}>
                                            <div className={`w-10 h-8 flex items-center justify-center text-sm focus:outline-none rounded-full bg-primary-lighter transition duration-200 border-primary-lighter border-2 hover:border-primary-default font-semibold text-primary-default`}>
                                                {userData?.initials ?? ""}
                                            </div>
                                            <p className={"mt-2"}>
                                                {userData?.name ?? ""}
                                            </p>
                                            <p className={"opacity-50 mt-0"} style={{ lineHeight: "1" }}>
                                                {userData?.email ?? ""}
                                            </p>
                                        </div>
                                        {/*{role === "admin" &&*/}
                                        {/*    <Link to={"/admin"} className={"flex items-center justify-start text-sm font-medium py-2 px-3 hover:bg-white hover:bg-opacity-40"}>*/}
                                        {/*        <span>*/}
                                        {/*        Go to Admin*/}
                                        {/*        </span>*/}
                                        {/*        <FiSettings className={"ml-auto opacity-70"}/>*/}
                                        {/*    </Link>*/}
                                        {/*}*/}
                                        {/*<Link to={"/account"} className={"flex items-center justify-start text-sm font-medium py-2 px-3 hover:bg-white hover:bg-opacity-40"}>*/}
                                        {/*    <span>*/}
                                        {/*        My Account*/}
                                        {/*    </span>*/}
                                        {/*    <FiSmile className={"ml-auto opacity-70"}/>*/}
                                        {/*</Link>*/}
                                        <button onClick={() => { signOut(auth) }} className={"flex items-center justify-start text-sm font-medium py-2 px-3 hover:bg-white hover:bg-opacity-40 text-red-700"}>
                                            <span>
                                                Sign Out
                                            </span>
                                            <FiLogOut className={"ml-auto opacity-70"}/>
                                        </button>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </>
                    )}
                </Popover>
            </div>

        </div>
    )

}

export default NavigationBar;