import React, {ReactNode} from 'react'
import {Listbox} from '@headlessui/react'
import {HiSelector} from "react-icons/hi";
import {FiAlertCircle, FiCheck} from "react-icons/fi";

const SingleSelect = ({value, onChange, options, label, placeholder = "Select an Option", name, error, hideLabel = false, small, suffix = []}: {suffix?: ReactNode[], small?: boolean, hideLabel?: boolean, required?: boolean, error?: string, showError?: boolean, name:string, value?:any, onChange?: (val: any) => void, options: any[], label?: string, placeholder?: string}) => {

    const className = small ? "text-sm py-1.5 pl-2 pr-10 rounded-lg" : "text-base py-2 pl-3 pr-10 rounded-xl"

    return (
        <div className="w-full">
            <Listbox name={name} value={value} onChange={(value) => { if (onChange) onChange(value); }}>
                <Listbox.Label className={`text-sm font-medium opacity-80 ${hideLabel ? "sr-only" : ""}`}>
                    {label}
                </Listbox.Label>
                {!hideLabel &&
                    <div className={"h-2"}/>
                }
                <div className="relative">
                    <Listbox.Button
                        className={`${className} relative w-full text-left bg-white  shadow-sm border ${error ? "border-red-300 z-10" : "border-gray-300"} cursor-default focus:outline-none ring-1 ring-transparent  ${error ? "focus:ring-red-400" : "focus:ring-primary-default"} focus:z-20 ${error ? "focus:border-red-400" : "focus:border-primary-default"}`}>
                        <span className={`block truncate ${!value ? "text-gray-500" : ""}`}>{!value ? placeholder : value}</span>
                        {(!!error) &&
                            <div className={"absolute z-10 right-9 top-1/2 transform -translate-y-1/2"}>
                                <FiAlertCircle className={"text-red-700"} size={14}/>
                            </div>
                        }
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <HiSelector
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Listbox.Options
                        className={"absolute w-full mt-1 overflow-auto text-base bg-white rounded-lg shadow-lg max-h-32 z-50 ring-1 ring-black ring-opacity-5 divide-y divide-black divide-opacity-5 focus:outline-none text-sm"}
                        style={{ background: "rgba(255,255,255,0.7)", backdropFilter: "blur(60px) saturate(210%)" }}
                    >
                        {options.map((option, index) => (
                            <Listbox.Option
                                key={index}
                                className={({active}) => `${active ? 'text-primary-default bg-primary-lighter' : 'text-gray-900'} cursor-pointer select-none relative py-2 pl-10 pr-4`}
                                value={option}
                            >
                                {
                                    ({selected, active}) => (
                                        <>
                                            <span className={`${selected ? 'font-medium text-primary-default' : ' font-normal'} text-sm block truncate`}>
                                                {option}
                                            </span>
                                            {selected ?
                                                <span className={`${active ? 'text-primary-default' : 'text-primary-light'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                                                      <FiCheck className="w-4 h-4" aria-hidden="true"/>
                                                    </span>
                                                :
                                                <span className={`${active ? 'text-primary-default' : 'text-gray-300'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                                                  <FiCheck className="w-4 h-4" aria-hidden="true"/>
                                                </span>
                                            }
                                            {(suffix.length-1) >= index ? suffix[index] : <></>}
                                        </>
                                    )
                                }
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </div>
            </Listbox>
            {(!!error && error !== "empty") &&
                <p style={{ textTransform:"none"}} className={"-space-y-px text-sm mt-1 text-red-700"}>
                    {error}
                </p>
            }
        </div>
    )
}

export default SingleSelect;