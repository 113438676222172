import React from "react";
import {Link} from "react-router-dom";
import {FiArrowRight} from "react-icons/all";
import Card from "../../cards/card";
import {BsHexagonFill} from "react-icons/bs";

const AdminSubmissionDecisionCard = ({to}: {to: string}) => {

    return (
        <Link to={`${to}`}>
            <Card className={"bg-gray-100 border-dashed"}>
                <div className={"max-w-xl"}>
                    <div className={"flex mb-2"}>
                        <div className={"flex items-center space-x-1 text-xs font-medium text-indigo-600 bg-indigo-100 px-1.5 py-px rounded-md"}>
                            <BsHexagonFill size={8}/>
                            <span>Admin</span>
                        </div>
                    </div>
                    <div className={"text-xl font-semibold max-w-sm"}>
                        Decisions
                    </div>

                    <div className={"text-sm opacity-80 mt-1 max-w-sm"}>
                        Once decisions are sent, authors can see if they've been accepted. Authors will also be able to view your feedback & make improvements.
                    </div>
                    <div className={"flex items-center space-x-2 mt-4 font-semibold text-indigo-500"}>
                        <span>Send Decision</span><FiArrowRight/>
                    </div>
                </div>
            </Card>
        </Link>
    )

}

export default AdminSubmissionDecisionCard;