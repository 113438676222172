import OnLoadViewer from "../../../../components/on-load-viewer/on-load-viewer";
import AdminSubmissionRow from "../../../../components/submission-components/submission-row/admin-submission-row";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/index.reducers";
import AdminRegistrationRow from "../registrations-view/admin-registration-row";

const AdminRegistrationList = () => {

    const conference = useSelector((state: RootState) => state.conference);
    const admin_registrations = useSelector((state: RootState) => state.adminRegistrations);

    return (
        <OnLoadViewer loading={conference.loading}>
            <table className="table-fixed w-full text-left  mb-6">
                <thead className={`bg-gray-50 h-12 px-3 py-3 text-gray-700 sticky z-20`} style={{ top: `112px`, boxShadow: "inset 0 -1px 0 rgb(229, 231, 235)" }}>
                    <tr>
                        <th className="font-normal text-sm sm:p-3 py-2 px-1" style={{ width: "50%" }}>Name</th>
                        <th className="font-normal text-sm sm:p-3 py-2 px-1" style={{ width: "25%" }}>Ticket</th>
                        <th className="font-normal text-sm sm:p-3 py-2 px-1 text-right" style={{ width: "10%" }}>Price</th>
                        <th className="font-normal text-sm sm:p-3 py-2 px-1 text-right" style={{ width: "10%" }}>Date</th>
                    </tr>
                </thead>
                <tbody className={"text-gray-700 relative"}>
                    <OnLoadViewer loading={admin_registrations.loading} component={"tr"} childComponent={"td"} className={"absolute"}>
                        {
                            admin_registrations.registrations.map((registration) => {
                                return <AdminRegistrationRow key={`registrations_${registration.document_id}`} registration={registration}/>
                            })
                        }
                    </OnLoadViewer>
                </tbody>
            </table>
        </OnLoadViewer>
    )

}

export default AdminRegistrationList;