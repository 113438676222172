import RichTextEditor from "../../forms/rich-text-editor";
import React, {useEffect, useState} from "react";
import {Submission} from "../../../models/submission";
import {nodeValue} from "../../../utils/methods";

const SubmissionAbstractView = ({submission, containerWidth = 1}: {submission: Submission | null, containerWidth: number}) => {

    const [hideAbstract, setHideAbstract] = useState(true);
    const [shouldHide, setShouldHide] = useState(false);

    useEffect(() => {
        if (submission) {
            let charLength = parseInt(((containerWidth) / 7).toFixed(0));
            console.log(nodeValue(submission?.abstract ?? []).replaceAll("\n", new Array(charLength).fill("-").join("")).length);
            console.log(charLength*6);
            setShouldHide(nodeValue(submission?.abstract ?? []).replaceAll("\n", new Array(charLength).fill("-").join("")).length > (charLength * 6));
        }
    }, [containerWidth, submission])

    return (
        <>
            <div className={"container font-sans text-sm text-gray-700 px-2 overflow-hidden"}
                 style={{ height: shouldHide ? `128px` : `${document.querySelector(".container_content")?.clientHeight ?? 0}`, willChange: "height", maskImage: "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)", WebkitMaskImage: "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)", transition: "height 300ms cubic-bezier(0.28, 0.13, 0.38, 1) 0s, mask-image" }}
            >
                <div className={"container_content transition duration-300 pb-6 leading-6"}>
                    <RichTextEditor value={submission?.abstract ?? []} onChange={() => {}} readOnly placeholder={""} />
                </div>
            </div>
            {!(hideAbstract && shouldHide) && <div className={"h-4"}/>}
            {shouldHide && (
                <>
                    <div className={"relative"}>
                        <div className={"absolute w-full h-24 transform -translate-y-24 transition duration-100"} style={{ opacity: hideAbstract ? 1 : 0, background: "linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0))" }}/>
                    </div>
                    <div className={`flex justify-center`}>
                        <button className={`relative w-28 h-8 overflow-hidden border border-gray-100 py-1 rounded-xl text-sm font-medium transition duration-200 hover:bg-primary-lightest text-primary-default cursor-pointer`}
                                onClick={() => {
                                    setHideAbstract(prevState => !prevState);
                                    let container: HTMLDivElement = document.querySelector(".container");
                                    let h = container.clientHeight;
                                    let content: HTMLDivElement = document.querySelector(".container_content");
                                    if (h === 128) {
                                        console.log(content.clientHeight);
                                        container.style.height = content.clientHeight + "px";
                                        container.style.maskImage = "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)";
                                    } else {
                                        container.style.height = "128px";
                                        container.style.maskImage = "linear-gradient(rgb(0, 0, 0) 1px, transparent 100%)";
                                    }
                                }}
                        >
                            <span className={`transform ${hideAbstract ? "-translate-y-1/2" : "-translate-y-8"} transition duration-200 absolute top-1/2 left-1/2 -translate-x-1/2 w-full`}>
                                Show More
                            </span>
                            <span className={`transform ${!hideAbstract ? "-translate-y-1/2" : "translate-y-8"} transition duration-200 absolute top-1/2 left-1/2 -translate-x-1/2 w-full`}>
                                Hide
                            </span>
                        </button>
                    </div>
                </>
            )}
        </>
    )

}

export default SubmissionAbstractView;