import React from 'react';

const Card = ({children, className, style}: {children: React.ReactNode, style?: React.CSSProperties, className?: string}) => {

    return (
        <div className={`relative bg-white box-border border border-gray-200 rounded-2xl overflow-hidden p-6 shadow-sm ${className}`} style={style}>
            {children}
        </div>
    );

}

export default Card;