import {
    RiAlignCenter,
    RiAlignLeft,
    RiAlignRight,
    RiBold,
    RiItalic,
    RiStrikethrough,
    RiUnderline
} from "react-icons/all";
import React from "react";
import {Editor} from "@tiptap/react";

const EditorBar = ({editor}: {editor: Editor}) => {

    return (
        <div className={"flex px-3 py-2.5 border-b border-gray-300 space-x-3"}>
            <div className={"flex border border-gray-200 bg-gray-100 rounded-lg overflow-hidden"}>
                <button
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    className={`p-1.5 ${editor.isActive('bold') ? 'bg-primary-lighter text-primary-default hover:bg-primary-lightest' : 'hover:bg-gray-200'}`}
                >
                    <RiBold size={20}/>
                </button>
                <div className={"w-px h-full bg-gray-200"}/>
                <button
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    className={`p-1.5 ${editor.isActive('italic') ? 'bg-primary-lighter text-primary-default hover:bg-primary-lightest' : 'hover:bg-gray-200'}`}
                >
                    <RiItalic size={20}/>
                </button>
                <div className={"w-px h-full bg-gray-200"}/>
                <button
                    onClick={() => editor.chain().focus().toggleUnderline().run()}
                    className={`p-1.5 ${editor.isActive('underline') ? 'bg-primary-lighter text-primary-default hover:bg-primary-lightest' : 'hover:bg-gray-200'}`}
                >
                    <RiUnderline size={20}/>
                </button>
                <div className={"w-px h-full bg-gray-200"}/>
                <button
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    className={`p-1.5 ${editor.isActive('strike') ? 'bg-primary-lighter text-primary-default hover:bg-primary-lightest' : 'hover:bg-gray-200'}`}
                >
                    <RiStrikethrough size={20}/>
                </button>
            </div>
            <div className={"flex border border-gray-200 bg-gray-100 rounded-lg overflow-hidden"}>
                <button
                    onClick={() => editor.chain().focus().setTextAlign('left').run()}
                    className={`p-1.5 ${editor.isActive({ textAlign: 'left' }) ? 'bg-primary-lighter text-primary-default hover:bg-primary-lightest' : 'hover:bg-gray-200'}`}
                >
                    <RiAlignLeft size={20}/>
                </button>
                <div className={"w-px h-full bg-gray-200"}/>
                <button
                    onClick={() => editor.chain().focus().setTextAlign('center').run()}
                    className={`p-1.5 ${editor.isActive({ textAlign: 'center' }) ? 'bg-primary-lighter text-primary-default hover:bg-primary-lightest' : 'hover:bg-gray-200'}`}
                >
                    <RiAlignCenter size={20}/>
                </button>
                <div className={"w-px h-full bg-gray-200"}/>
                <button
                    onClick={() => editor.chain().focus().setTextAlign('right').run()}
                    className={`p-1.5 ${editor.isActive({ textAlign: 'right' }) ? 'bg-primary-lighter text-primary-default hover:bg-primary-lightest' : 'hover:bg-gray-200'}`}
                >
                    <RiAlignRight size={20}/>
                </button>
            </div>
        </div>
    )

}

export default EditorBar;