// Action Types

import {ConferenceSettings} from "../../models/conference-settings";

export const SET_CONFERENCE_SETTINGS = 'SET_CONFERENCE_SETTINGS';

interface SetConferenceSettings {
    type: typeof SET_CONFERENCE_SETTINGS,
    payload: {
        settings: ConferenceSettings
    }
}


export type ConferenceSettingsActionTypes = SetConferenceSettings

export class ConferenceSettingState extends ConferenceSettings {
    loading: boolean;

    constructor(data?: { [field: string]: any }) {
        super(data);
        this.loading = data?.loading ?? true;
    }
}