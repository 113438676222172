import {UserData} from "../../models/user-data";
import {User} from "firebase/auth";


// Action Types
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';

interface SetUser {
    type: typeof SET_USER,
    payload: {
        user: User | null,
        userData: UserData | null,
        role: Role
    }
}

interface UpdateUser {
    type: typeof UPDATE_USER,
    payload: {
        userData: UserData,
        role?: Role
    }
}


export type UserActionTypes = SetUser | UpdateUser

// State
export type Role = "chair" | "admin" | "reviewer" | "regular";

export interface UserState {
    loading?: boolean,
    userData: UserData | null,
    isAuthenticated?: boolean,
    isVerified?: boolean,
    role?: Role
}