import {Submission} from "../../../models/submission";
import {Fragment, useState} from "react";
import Fade from "../../transitions/fade";
import React from "react"
import PrimaryButton from "../../buttons/button";
import RichTextEditor from "../../forms/rich-text-editor";
import { authorValue } from "../../../utils/methods";
import SecondaryButton from "../../buttons/secondary-button";

const AbstractReview = ({show=true, submission, isValid, onBack, onSubmit}: {show: boolean, submission: Submission, isValid: (v:string) => boolean, onSubmit: () => Promise<unknown>, onBack: ()=>void }) => {

    const [loading, setLoading] = useState(false);

    return (
        <Fade
            show={show}
            as={Fragment}
        >
            <div className="max-w-md pt-6 pb-2">
                <p className={"text-lg font-medium"} style={{ color: submission.title ? 'black' : 'rgba(198,53,89, 1)' }}>
                    {submission.title ? submission.title : "Missing Title"}
                </p>
                <p className={"text-sm"} style={{ opacity: "0.8", color: submission.authors.length > 0 ? 'black' : 'rgba(198,53,89, 1)' }}>
                    {submission.authors.length > 0 ?
                        authorValue(submission.authors)
                        :
                        "No authors added"
                    }
                </p>
                <div style={{ height: "12px" }}/>
                <div style={{ width: "100%", borderBottom: "1px dashed grey" }}/>
                <div style={{ height: "12px" }}/>
                <div style={{ display: "flex" }}>
                    <p className={"text-sm"} style={{ marginRight: "12px", opacity: "0.8" }}>
                        Track:
                    </p>
                    <p className={"text-sm"} style={{ color: isValid('Track') ? 'black' : 'rgba(198,53,89, 1)' }}>
                        {submission.track ? submission.track : "Missing Track"}
                    </p>
                </div>
                <div style={{ height: "8px" }}/>
                <div style={{ display: "flex" }}>
                    <p className={"text-sm"} style={{ marginRight: "12px", opacity: "0.8" }}>
                        Presentation Format:
                    </p>
                    <p className={"text-sm"} style={{ color: isValid('Format') ? 'black' : 'rgba(198,53,89, 1)' }}>
                        {submission.format ? submission.format : "Missing Presentation Format"}
                    </p>
                </div>
                <div style={{ height: "12px" }}/>
                <div style={{ width: "100%", borderBottom: "1px dashed grey" }}/>
                <div style={{ height: "12px" }}/>
                <RichTextEditor value={submission.abstract} onChange={() => {}} readOnly placeholder={"Missing abstract"}/>
                <div className={"sticky bottom-0 py-4 max-w-md w-full z-40 border-t border-gray-200 mt-10 bg-gray-50"} >
                    <div className={"flex space-x-6"}>
                        <SecondaryButton className={"mt-2 text-gray-600"} onClick={onBack} style={{ width: "auto" }}>
                            Back
                        </SecondaryButton>
                        <PrimaryButton
                            loading={loading}
                            className={"mt-2"}
                            onClick={() => {
                                if (isValid("All")) {
                                    setLoading(true);
                                    onSubmit().then(() => {})
                                }
                            }}
                            style={{ width: "auto" }}
                        >
                            Submit Abstract
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </Fade>
    )
}

export default AbstractReview;