import React, {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";

const ZoomFadeDialog = ({open=false, onClose, children, maxWidth = "max-w-lg"}: {open: boolean, onClose: () => void, children: React.ReactNode, maxWidth?: string}) => {

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="z-40 fixed inset-0 overflow-hidden" onClose={onClose} >
                <div className="min-h-screen text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed h-full w-full left-0 top-0 inset-0 transition-opacity" style={{ background: "rgba(70,60,50,0.45)" }}/>
                    </Transition.Child>
                    <div className={"flex justify-center items-end sm:items-center transform absolute bottom-0 sm:top-1/2 sm:left-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2"}>
                        <Transition.Child
                            enter="transform ease-out duration-300"
                            enterFrom="opacity-0 translate-y-5 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:translate-y-0 sm:scale-100"
                            leave="transform ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-5 sm:translate-y-0 sm:scale-95"
                        >
                            <div className={`transform relative inline-block text-left align-middle transition-all ${maxWidth} rounded-2xl overflow-hidden`} style={{ width: "100vw" }}>
                                <div
                                    className="flex flex-col shadow-xl overflow-y-overlay bg-gray-50"
                                    style={{
                                        boxShadow: "0 4px 10px -1px rgb(190 193 214 / 25%)",
                                        paddingBottom: "env(safe-area-inset-bottom)"
                                    }}
                                >
                                    {children}
                                </div>
                            </div>
                        </Transition.Child>

                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )

}

export default ZoomFadeDialog;