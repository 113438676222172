import {Submission} from "../../../models/submission";
import {Descendant} from 'slate';
import React, {Fragment, useEffect, useState} from "react";
import Fade from "../../transitions/fade";
import PrimaryButton from "../../buttons/button";
import FormErrorText from "../../forms/form-error-text";
import RichTextEditor from "../../forms/rich-text-editor";
import FormInput from "../../forms/form-input";
import { nodeValue } from "../../../utils/methods";
import SecondaryButton from "../../buttons/secondary-button";

const AbstractMainForm = ({show=true, submission, onChange, onBack, onSubmit}: {show: boolean, submission: Submission, onChange: (newSubmission: Submission) => void, onSubmit: () => void, onBack: ()=>void }) => {

    const [title, setTitle] = useState<string>("");
    const [abstract, setAbstract] = useState<Descendant[]>([
        {
            type: 'paragraph',
            children: [{ text: 'GG' }],
        },
    ]);
    const [error, setError] = useState<any>("");

    useEffect(() => {
        setAbstract(submission.abstract);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTitle(submission.title);
    }, [submission.title])

    const onChangeTitle = (v: string) => {
        setError("");

        let s = new Submission(submission.toJson());
        s.title = v ?? "";
        onChange(s);
    }

    const onChangeAbstract = (v: Descendant[]) => {
        setError("");

        let s = new Submission(submission.toJson());
        s.abstract = v;
        onChange(s);
    }

    const goNext = () => {
        if (title && nodeValue(abstract).trim().length > 0) {
            setError("");
            onSubmit();
        } else {
            setError({
                title: title ? "" : "empty",
                text: (nodeValue(abstract).trim().length === 0 || !title) ? "Please enter your title & abstract." : "",
                abstract: (nodeValue(abstract).trim().length > 0) ? "" : "Please enter your title & abstract."
            })
        }
    }

    return (
        <Fade
            show={show}
            as={Fragment}
        >
            <div>
                <p className={"pt-6 pb-4 text-2xl mb-2 max-w-sm"}>
                    Enter a Title & Abstract for your submission.
                </p>
                <div className="max-w-md space-y-6 px-1">
                    <FormInput error={error?.title ?? ""} value={title} onChange={(event) => { onChangeTitle(event.target.value) }} label={"Title"} name={"title"} id={"abstractTitle"} placeholder={"Place your title here."} type="text" autoComplete="off" hideLabel/>
                    <RichTextEditor
                        error={error.abstract}
                        value={abstract}
                        onChange={(newValue) => {
                            onChangeAbstract(newValue);
                            setAbstract(newValue);
                        }}
                        placeholder={"Place your abstract here."}
                    />
                </div>
                <div className={"sticky bottom-0 py-4 max-w-md w-full z-40 border-t border-gray-200 mt-10 bg-gray-50"} >
                    <FormErrorText error={error} showError={!!error}/>
                    <div className={"flex space-x-6"}>
                        <SecondaryButton className={"mt-2 text-gray-600"} onClick={onBack} style={{ width: "auto" }}>
                            Back
                        </SecondaryButton>
                        <PrimaryButton className={"mt-2"} onClick={goNext} style={{ width: "auto" }}>
                            Continue
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </Fade>
    )

}

export default AbstractMainForm;