import React, {useEffect, useState} from "react"
import FormInput from "../../forms/form-input";
import FormWrapper from "../../forms/form-wrapper";
import FormErrorText from "../../forms/form-error-text";
import PrimaryButton from "../../buttons/button";
import SingleSelect from "../../forms/form-single-select";
import Countries from "../../forms/countries.json";
import {Author} from "../../../models/submission";
import SecondaryButton from "../../buttons/secondary-button";

const AddAuthorForm = ({author, onSave}: {author?: Author, onSave: (author:Author | undefined) => void}) => {

    let [hasAuthor, setHasAuthor] = useState(false);

    useEffect(() => {
        if (author?.name)
            setHasAuthor(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={"px-2"}>
            <FormWrapper
                onSubmit={(values) => {
                    return new Promise((resolve, reject) => {
                        onSave(new Author({
                            name: values["name"],
                            email: values["email"],
                            organization: values["organization"],
                            country: values["country"]
                        }));
                    })
                }}
                style={{ width: "100%" }}
                initialValues={author ?
                    { name: author.name, email: author.email, organization: author.organization, country: author.country }
                    : undefined
                }
            >
                <div className={"space-y-6 mt-4"}>
                    <div className={"grid grid-cols-2 gap-6"}>
                        <FormInput id={"authorId"} name={"name"} type={"text"} label={"Full Name"} placeholder={"Full Name"} autoComplete={"name"} required/>
                        <FormInput id={"authorEmail"} name={"email"} label={"Email"} type={"email"} placeholder={"Author's Email"} autoComplete={"email"} required/>
                    </div>
                    <FormInput id={"authorOrganization"} name={"organization"} type={"text"} label={"Organisation / University"} autoComplete={"organization"} placeholder={"Organisation / University"} required/>
                    <SingleSelect name={"country"} label={"Country"} options={Countries} placeholder={"Select Author's Country"}/>
                    <FormErrorText/>
                    <div className={"sticky bottom-0 py-4 max-w-md w-full z-30 border-t border-gray-200 mt-10 bg-gray-50"} >
                        <div className={"flex space-x-6"}>
                            <SecondaryButton type={"button"} className={"mt-2 text-gray-600"} onClick={() => {onSave(undefined)}} style={{ width: "auto" }}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton type={"submit"} className={"mt-2"} style={{ width: "auto" }}>
                                {hasAuthor ? "Update" : "Add Author"}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </FormWrapper>
        </div>
    )

}

export default AddAuthorForm;