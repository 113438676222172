import React from "react";
import { FiAlertCircle } from "react-icons/fi"

interface FormInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    disableRounded?: boolean,
    label?: string,
    hideLabel?: boolean,
    error?: string,
    hideShadow?: boolean,
    rootClassName?: string
}

const FormInput = (props: FormInputProps) => {

    const inputProps = {...props};
    delete inputProps.disableRounded
    delete inputProps.label
    delete inputProps.hideLabel
    delete inputProps.error
    delete inputProps.hideShadow
    delete inputProps.rootClassName

    return (
        <div className={`${props.rootClassName}`}>
            <label htmlFor={inputProps.id} className={props.hideLabel ? "sr-only" : "text-sm font-medium opacity-80"}>
                {props.label}
            </label>
            <div className={`relative -space-y-px ${props.hideShadow ? "" : "rounded-md shadow-sm"} ${props.hideLabel ? "" : "mt-1.5"}`}>
                <input {...inputProps}
                       className={`appearance-none rounded-none relative block w-full px-3 py-2.5 border ${props.error ? "border-red-300 z-10" : "border-gray-300"} placeholder-gray-500 text-gray-900 focus:outline-none ${props.error ? "focus:ring-red-400" : "focus:ring-primary-default"} ${props.error ? "focus:border-red-400" : "focus:border-primary-default"} focus:z-20 text-base ${props.className} ${props.disableRounded ? "" : "rounded-xl"}`}
                       disabled={props.disabled}
                />
                {(!!props.error) &&
                <div className={"absolute z-20 right-3 top-1/2 transform -translate-y-1/2"}>
                    <FiAlertCircle className={"text-red-700"} size={14}/>
                </div>
                }
            </div>
            {(!!props.error && props.error !== "empty") &&
                <p style={{ textTransform:"none"}} className={"-space-y-px text-sm mt-1 text-red-700"}>
                    {props.error}
                </p>
            }
        </div>
    )

}

export default FormInput;